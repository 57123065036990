import Skeleton from '@mui/material/Skeleton';
import React, { lazy, Suspense } from 'react';

const Contch01 = lazy(() => import('./Chinese-Theme-01/Contacts'));
const Contch02 = lazy(() => import('./Chinese-Theme-02/Contacts'));
const Contit01 = lazy(() => import('./Italian-Theme-01/Contacts'));
const Contit02 = lazy(() => import('./Italian-Theme-02/Contacts'));
const Contit03 = lazy(() => import('./Italian-Theme-03/Contacts'));
const Contpz01 = lazy(() => import('./Pizza-Theme-01/Contacts'));
const Contpz02 = lazy(() => import('./Pizza-Theme-02/Contacts'));
const ContAr01 = lazy(() => import('./Arabian-Theme-01/Contacts'));
const ContPub01 = lazy(() => import('./Pub-Theme-01/Contacts'));
const ContSweet01 = lazy(() => import('./Sweet-Theme-01/Contacts'));
const AmCt01 = lazy(() => import("./American-Theme-01/contacts"))
const ContFi02 = lazy(() => import('./Fish-Theme-02/Contacts'));

const Contacts = ({ cont, handleOpen }) => {

  return (
    <div>
      <Suspense fallback={<Skeleton variant="rectangular" width={"100%"} className="mt-[5%] " height={600} animation="wave" />}>
        {cont === "Chinese-01" ? <Contch01 handleOpen={handleOpen} /> : null}
        {cont === "Chinese-02" ? <Contch02 handleOpen={handleOpen} /> : null}
        {cont === "Italian-01" ? <Contit01 handleOpen={handleOpen} /> : null}
        {cont === "Italian-02" ? <Contit02 handleOpen={handleOpen} /> : null}
        {cont === "Italian-03" ? <Contit03 handleOpen={handleOpen} /> : null}
        {cont === "Pizza-01" ? <Contpz01 handleOpen={handleOpen} /> : null}
        {cont === "Pizza-02" ? <Contpz02 handleOpen={handleOpen} /> : null}
        {cont === "Arabian-01" ? <ContAr01 handleOpen={handleOpen} /> : null}
        {cont === "Pub-01" ? <ContPub01 handleOpen={handleOpen} /> : null}
        {cont === "Sweet-01" ? <ContSweet01 handleOpen={handleOpen} /> : null}
        {cont === "American-01" ? <AmCt01 handleOpen={handleOpen} /> : null}
        {cont === "Fish-02" ? <ContFi02 handleOpen={handleOpen} /> : null}

      </Suspense>
    </div>
  );
}

export default Contacts;
