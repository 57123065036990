import React from 'react';
import notFound from '../../Assets/Image/notFound.png';

const ErrorNotFound = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <img src={notFound} style={{ maxWidth: '500px', maxHeight: '500px' }} alt="Not Found" />
      <h1>404 - Pagina non trovata</h1>
      <h4>La pagina che stai cercando non è disponibile.</h4>
    </div>
  );
};

export default ErrorNotFound;
