import React, { lazy, Suspense } from 'react';
import { MyContext } from "../../../App";



const Revch01 = lazy(() => import('./Chinese-Theme-01/Reviews'));
const Revch02 = lazy(() => import('./Chinese-Theme-02/Reviews'));
const Revit01 = lazy(() => import('./Italian-Theme-01/Reviews'));
const Revit02 = lazy(() => import('./Italian-Theme-02/Reviews'));
const Revit03 = lazy(() => import('./Italian-Theme-03/Reviews'));
const Revpz01 = lazy(() => import('./Pizza-Theme-01/Reviews'));
const Revpz02 = lazy(() => import('./Pizza-Theme-02/Reviews'));
const Revar01 = lazy(() => import('./Arabian-Theme-01/Reviews'));
const Revpb01 = lazy(() => import('./Pub-Theme-01/Reviews'));
const Revsw01 = lazy(() => import('./Sweet-Theme-01/Reviews'));
const RevAm01 = lazy(() => import("./American-Theme-01/Reviews"))
const Revfs02 = lazy(() => import("./Fish-Theme-02/reviews"))

const Revbar = ({ theme }) => {

const {  RestaurantInfo } = React.useContext(MyContext)

  return (

    <div id='masterRev'>

     {RestaurantInfo?.theme_photos?.home?.reviews?.isVisible && <Suspense fallback={<div>Pagina in caricamento...</div>}>

        {theme === "Chinese-01" ?
          <Revch01 />
          :
          null
        }

        {theme === "Chinese-02" ?
          <Revch02 />
          :
          null
        }

        {theme === "Italian-01" ?
          <Revit01 />
          :
          null
        }

        {theme === "Italian-02" ?
          <Revit02 />
          :
          null
        }

        {theme === "Italian-03" ?
          <Revit03 />
          :
          null
        }

        {theme === "Pizza-01" ?
          <Revpz01 />
          :
          null
        }

        {theme === "Pizza-02" ?
          <Revpz02 />
          :
          null
        }

        {theme === "Arabian-01" ?
          <Revar01 />
          :
          null
        }

        {theme === "Pub-01" ?
          <Revpb01 />
          :
          null
        }

        {theme === "Sweet-01" ?
          <Revsw01 />
          :
          null
        }

        {theme === "American-01" ?
          <RevAm01 />
          :
          null
        }

        {theme === "Fish-02" ?
          <Revfs02 />
          :
          null
        }

      </Suspense>}

    </div>

  );
}

export default Revbar;
