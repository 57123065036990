import { GET_REVIEWS } from '../../Constants';

export const ReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
      };
    default:
      return state;
  }
};
