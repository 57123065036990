import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import React, { useState } from "react";
import { editAuthUser } from "../../../Util/Api/CustomerAPI";
import { toast } from 'react-toastify';
import Alert from '@mui/material/Alert';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth < 1000 ? "95%" : 600,
    maxHeight: 700,
    bgcolor: 'background.paper',
    borderRadius: 3,
    boxShadow: 24,
    p: 2,
};

function InformazioniModal({ open, handleClose, SetReload, userData }) {

    const [Email, setEmail] = useState(userData?.email);
    const [firstName, setFullName] = useState(userData?.firstName);
    const [lastName, setLastName] = useState(userData?.lastName);
    const [Telephone, setTelephone] = useState(userData?.phoneNumber);
    const [error, seterror] = React.useState(false)

    React.useEffect(() => {
        if (error) {
            setTimeout(() => {
                seterror(false)
            }, 3000);
        }
        return () => { }
    }, [error])


    const handleSubmit = async () => {

        if (!firstName || !Telephone || !Email) seterror(true)
        else {
            const theNewUser = {
                firstName: firstName,
                lastName: lastName,
                email: Email,
                phoneNumber: Telephone
            }
            try {
                const res = await editAuthUser(userData._id, { ...theNewUser });
                SetReload(res)
                handleClose()
            } catch (error) {
                toast.error()
            }
        }
    }


    return (

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            disableAutoFocus
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="flex flex-col  relative gap-y-6" >

                        <button
                            onClick={() => handleClose()}
                            className="absolute top-0 right-0 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                        </button>
                        <div className="mt-8 flex flex-col">
                            <div className='flex flex-col text-left gap-y-2 mb-4'>
                                <input value={firstName} onChange={(e) => setFullName(e.target.value)} style={{ backgroundSize: "25", backgroundPosition: "98% center", backgroundRepeat: "no-repeat" }} placeholder="Nome Completo" className="h-10 rounded-l-md rounded-r-md bg-transparent py-1 px-4 w-full border text-black" />
                            </div>
                            <div className='flex flex-col text-left gap-y-2 mb-4'>
                                <input value={lastName} onChange={(e) => setLastName(e.target.value)} style={{ backgroundSize: "25", backgroundPosition: "98% center", backgroundRepeat: "no-repeat" }} placeholder="Nome Completo" className="h-10 rounded-l-md rounded-r-md bg-transparent py-1 px-4 w-full border text-black" />
                            </div>
                            <div className='flex flex-col text-left gap-y-2 mb-2' >
                                <input value={Email} onChange={(e) => setEmail(e.target.value)} type={"email"} style={{ backgroundSize: "25", backgroundPosition: "98% center", backgroundRepeat: "no-repeat" }} placeholder="Email" className="h-10 rounded-l-md rounded-r-md bg-transparent py-1 px-4 w-full border text-black" />
                            </div>
                            <div className='flex flex-col text-left gap-y-2 mb-2' >
                                <input onWheel={() => document.activeElement.blur()} type="number" value={Telephone} onChange={(e) => setTelephone(e.target.value)} style={{ backgroundSize: "25", backgroundPosition: "98% center", backgroundRepeat: "no-repeat" }} placeholder="Telefono" className="h-10 rounded-l-md rounded-r-md bg-transparent py-1 px-4 w-full border text-black" />
                            </div>
                            {error && <Alert className="mb-3  " severity="error">Compila tutti i campi!</Alert>}

                            <button onClick={() => handleSubmit()} className='bg-primaryColor uppercase hover:bg-[#2E2D2B] ml-auto px-20 text-bold py-2 rounded-l-md rounded-r-md text-white'>Modifica</button>


                        </div>

                    </div>
                </Box>
            </Fade>

        </Modal>

    )
}

export default InformazioniModal
