import axios from 'axios';
import { getSiteId } from "./Util/Data/userData"
import { baseUrl } from './Util/Api/Urls';

const baseUrlCustomers = `https://${getSiteId()}.${process.env.REACT_APP_THEMES_PREFIX}${baseUrl()}${process.env.REACT_APP_CUSTOMERS_API_PATH}`
const baseUrlThemes = `https://${getSiteId()}.${process.env.REACT_APP_THEMES_PREFIX}${baseUrl()}${process.env.REACT_APP_THEMES_API_PATH}`
const baseUrlThemesSections = `https://637df834c67b2714458fb9ef.themes.restaurants.club/api/sections`



export const getSectionsAPI = () => axios.get(`${baseUrlThemes}/sections`);
export async function getRestaurantInfoAPI() {
    try {
        const res = await axios.get(`${baseUrlCustomers}/restaurant-info`)
        document.title = res?.data?.name || "Restaurants club"
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = res?.data?.theme_photos.logo;
        return res
    } catch (error) {

    }
}
const getUrl = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/locationData/get?showProvisional=true`;
export async function httpGetLocationDetails() {
    try {
        const res = await axios.get(getUrl, getAuthConfig())
        return res
    } catch (error) {
        throw console.log(error)
    }
}
export const getThemeID_API = () => axios.get(`${baseUrlCustomers}/restaurant-info/themeId`)
export const getWebsiteInfoAPI = () => axios.get(`${baseUrlCustomers}/profiles`)
export const getPhotosAPI = () => axios.get(`${baseUrlThemes}/photos`)
export const getDeliverySettingsAPI = () => axios.get(`${baseUrlCustomers}/orders/delivery/settings`);
export const getReservationSettingsAPI = () => axios.get(`${baseUrlCustomers}/reservation/settings`);
export const PatchRestaurantInfoAPI = ({ RestaurantInfo, headersconfig }) => axios.patch(`${baseUrlThemes}/restaurant-info`, { theme_photos: RestaurantInfo.theme_photos }, headersconfig)
export const getDiscounts = () => axios.get(`${baseUrlThemes}/discounts`)
export const getDiscountss = () => axios.get(`${baseUrlThemes}/discountss`)


export const getWorkingHoursAPI = () => axios.get(`${baseUrlCustomers}/booking/getOpeningHours`)

export async function fetchCategoriesApi() {
    try {
        // send a GET request to fetch categories from the backend API
        const response = await axios.get(`${baseUrlThemes}/categories`);
        // filter out categories that have no products, are hidden, or belong to a hidden catalog
        const noEmptyCategoriesWithHiddenFalse = response?.data.filter((item) => item.products.length !== 0 && !item?.isHidden && !item?.catalog?.isHidden)
        // concatenate all products in non-empty categories with isHided=false and create a new array
        const produstWithHiddenFalse = noEmptyCategoriesWithHiddenFalse.reduce((acc, curr) => acc.concat(curr.products), []).filter((item) => !item.isHided)
        // filter out categories that have no products, are hidden, are not available for delivery, or belong to a hidden catalog that is not available for delivery
        const noEmptyCategoriesWithHiddenFalseAndNotForDelivery = response?.data.filter((item) => item.products.length !== 0 && !item.isHidden && item.isDeliverySelected && !item.catalog.isHidden && item.catalog.isDeliverySelected)
        // concatenate all products in non-empty categories with isHided=false and availableForDelivery=true, and create a new array
        const produstWithHiddenFalseAndNotForDelivery = noEmptyCategoriesWithHiddenFalseAndNotForDelivery.reduce((acc, curr) => acc.concat(curr.products), []).filter((item) => !item.isHided && item.availableForDelivery)
        // create an object with all the filtered products and categories
        let Data = {
            produstWithHiddenFalseAndNotForDelivery,
            produstWithHiddenFalse,
            noEmptyCategoriesWithHiddenFalse,
            noEmptyCategoriesWithHiddenFalseAndNotForDelivery
        }
        // return the object with the filtered products and categories
        return Data
    } catch (error) {
        console.log(error)
    }
}

/**
 * Fetches all catalogs and their corresponding non-hidden categories and products.
 * @returns {Array} An array of objects representing catalogs, each with a nested array of non-hidden categories and products.
 */
export async function fetchCatalogsApi() {
    try {
        const response = await axios.get(`${baseUrlThemes}/catalogs`);
        // Filters out catalogs and categories with no products and that are hidden
        const NoEmptyCatalogsNoEmptyCategories = response?.data?.catalogs?.map((cata) => ({ ...cata, categories: cata.categories.filter((catg) => catg.products.length !== 0 && !catg.isHidden) }))?.filter((item) => item.categories.length !== 0 && !item.isHidden)
        // Filters out hidden products in each category
        const NoHiddenProducts = NoEmptyCatalogsNoEmptyCategories.map((catalog) => ({ ...catalog, categories: catalog.categories.map((category) => ({ ...category, products: category.products.filter((product) => !product.isHided) })) }))
        return NoHiddenProducts
    } catch (error) {
        console.log(error)
    }
}




export function handleDynamicStyling(themeID) {
    if (themeID === "Chinese-01") {
        document.documentElement.style.setProperty('--primary-color', '#2e2d2b');
        document.documentElement.style.setProperty('--primary-color-hover', '#ed5d3e');
        document.documentElement.style.setProperty('--primary-color-active', '#d8563a');
        document.documentElement.style.setProperty('--primary-color-background', '#FFFFFF');
        document.documentElement.style.setProperty('--primary-text-color', '#d8563a');
        document.documentElement.style.setProperty('--primary-font', 'Enriqueta');
        document.documentElement.style.setProperty('--secondary-font', 'Mada');
    }
    if (themeID === "Chinese-02") {
        document.documentElement.style.setProperty('--primary-color', '#020a0c');
        document.documentElement.style.setProperty('--primary-color-hover', '#871c1e');
        document.documentElement.style.setProperty('--primary-color-active', '#991E20');
        document.documentElement.style.setProperty('--primary-color-background', '#FFFFFF');
        document.documentElement.style.setProperty('--primary-text-color', '#a90125');
        document.documentElement.style.setProperty('--primary-font', 'Bebas Neue');
        document.documentElement.style.setProperty('--secondary-font', 'Lato');
    }
    if (themeID === "Italian-01") {
        document.documentElement.style.setProperty('--primary-color', '#928471');
        document.documentElement.style.setProperty('--primary-color-hover', '#AF9F88');
        document.documentElement.style.setProperty('--primary-color-active', '#ffffff');
        document.documentElement.style.setProperty('--primary-color-background', '#FFFFFF');
        document.documentElement.style.setProperty('--primary-text-color', '#5a5246');
        document.documentElement.style.setProperty('--primary-font', 'Playfair Display');
        document.documentElement.style.setProperty('--secondary-font', 'Raleway');
    }
    if (themeID === "Italian-02") {
        document.documentElement.style.setProperty('--primary-color', '#343B32');
        document.documentElement.style.setProperty('--primary-color-hover', '#354032');
        document.documentElement.style.setProperty('--primary-color-active', '#859d7e');
        document.documentElement.style.setProperty('--primary-color-background', '#FFFFFF');
        document.documentElement.style.setProperty('--primary-text-color', '#62745d');
        document.documentElement.style.setProperty('--primary-font', 'Be Vietnam Pro');
        document.documentElement.style.setProperty('--secondary-font', 'Be Vietnam Pro');
    }
    if (themeID === "Italian-03") {
        document.documentElement.style.setProperty('--primary-color', '#343B32');
        document.documentElement.style.setProperty('--primary-color-hover', '#354032');
        document.documentElement.style.setProperty('--primary-color-active', '#272b26');
        document.documentElement.style.setProperty('--primary-color-background', '#FFFFFF');
        document.documentElement.style.setProperty('--primary-text-color', '#FFFFFF');
    }
    if (themeID === "Pizza-01") {
        document.documentElement.style.setProperty('--primary-color', '#2e2d2b');
        document.documentElement.style.setProperty('--primary-color-hover', '#ed5d3e');
        document.documentElement.style.setProperty('--primary-color-active', '#eb4c29');
        document.documentElement.style.setProperty('--primary-color-background', '#FFFFFF');
        document.documentElement.style.setProperty('--primary-text-color', '#eb4c29');
        document.documentElement.style.setProperty('--primary-font', 'Bebas Neue');
        document.documentElement.style.setProperty('--secondary-font', 'Lato');
    }
    if (themeID === "Pizza-02") {
        document.documentElement.style.setProperty('--primary-color', '#313030');
        document.documentElement.style.setProperty('--primary-color-hover', '#545252');
        document.documentElement.style.setProperty('--primary-color-active', '#eb4c29');
        document.documentElement.style.setProperty('--primary-color-background', '#FFFFFF');
        document.documentElement.style.setProperty('--primary-text-color', '#eb4c29');
        document.documentElement.style.setProperty('--primary-font', 'Open Sans');
        document.documentElement.style.setProperty('--secondary-font', 'Open Sans');
    }

    if (themeID === "Arabian-01") {
        document.documentElement.style.setProperty('--primary-color', '#5a5a5a');
        document.documentElement.style.setProperty('--primary-color-hover', '#f7b779');
        document.documentElement.style.setProperty('--primary-color-active', '#dea56e');
        document.documentElement.style.setProperty('--primary-color-background', '#FFFFFF');
        document.documentElement.style.setProperty('--primary-text-color', '#dea56e');
        document.documentElement.style.setProperty('--primary-font', 'Libre Caslon Text');
        document.documentElement.style.setProperty('--secondary-font', 'Lato');
    }

    if (themeID === "Pub-01") {
        document.documentElement.style.setProperty('--primary-color', '#D09F32');
        document.documentElement.style.setProperty('--primary-color-hover', '#D09F32');
        document.documentElement.style.setProperty('--primary-color-active', '#D09F32');
        document.documentElement.style.setProperty('--primary-color-background', '#FFFFFF');
        document.documentElement.style.setProperty('--primary-text-color', '#FFFFFF');
    }
    if (themeID === "Sweet-01") {
        document.documentElement.style.setProperty('--primary-color', '#D02130');
        document.documentElement.style.setProperty('--primary-color-hover', '#D02130');
        document.documentElement.style.setProperty('--primary-color-active', '#5b3f2a');
        document.documentElement.style.setProperty('--primary-color-background', '#E5E1D4');
        document.documentElement.style.setProperty('--secondary-color-background', '#F1EFE8');
        document.documentElement.style.setProperty('--primary-text-color', '#5b3f2a');
        document.body.style.backgroundColor = "#F1EFE8"
        document.documentElement.style.setProperty('--primary-font', 'Jost');
        document.documentElement.style.setProperty('--secondary-font', 'Jost');
    }

    if (themeID === "American-01") {
        document.documentElement.style.setProperty('--primary-color', '#f2af18');
        document.documentElement.style.setProperty('--primary-color-hover', '#D09F32');
        document.documentElement.style.setProperty('--primary-color-active', '#D09F32');
        document.documentElement.style.setProperty('--primary-color-background', '#FFFFFF');
        document.documentElement.style.setProperty('--primary-text-color', '#FFFFFF');
    }

    if (themeID === "Fish-02") {
        document.documentElement.style.setProperty('--primary-color', '#1F3F66');
        document.documentElement.style.setProperty('--primary-color-rgb', '31, 63, 102');
        document.documentElement.style.setProperty('--primary-color-hover', '#1F3F66');
        document.documentElement.style.setProperty('--primary-color-active', '#1F3F66');
        document.documentElement.style.setProperty('--primary-color-background', '#FFFFFF');
        document.documentElement.style.setProperty('--primary-text-color', '#FFFFFF');
    }

}

