import React, { lazy, Suspense } from "react";
import Skeleton from "@mui/material/Skeleton";
import { MyContext } from "../../../App";

const Banch02 = lazy(() => import('./Chinese-Theme-02/BannerBlock'));
const Bansw01 = lazy(() => import('./Sweet-Theme-01/BannerBlock'));
const Bancf01 = lazy(() => import("./Coffee-Theme-01/BannerBlock"));
const BanPub01 = lazy(() => import("./Pub-Theme-01/BannerBlock"));
const BanFis02 = lazy(() => import("./Fish-Theme-02/BannerBlock"));
const BanIta03 = lazy(() => import("./Italian-Theme-03/BannerBlock"));

const BannerBlock = ({ theme }) => {

  const {  PatchNewInfo, SetRestaurantInfo, RestaurantInfo } = React.useContext(MyContext)


  return (
    <div>

{RestaurantInfo?.theme_photos?.home?.menu_banner_1?.isVisible &&     
 <Suspense
        fallback={
          <Skeleton
            variant='rectangular'
            width={"100%"}
            className='mt-[5%] '
            height={600}
            animation='wave'
          />
        }
      >
        {theme === "Chinese-02" ? <Banch02 /> : null}
        {theme === "Sweet-01" ? <Bansw01 /> : null}
        {theme === "Coffee-01" ? <Bancf01 /> : null}
        {theme === "Pub-01" ? <BanPub01 /> : null}
        {theme === "Fish-02" ? <BanFis02 /> : null}
        {theme === "Italian-03" ? <BanIta03 /> : null}

      </Suspense>}

    </div>

  );
};

export default BannerBlock;
