import React from "react"
import Log from "../../Components/Login/Log"
import { MyContext } from "../../App"
import ChangeBGModal from "../../Components/Home/ChangeBGModal"

function Login() {
  const { themeID, PatchNewInfo, SetRestaurantInfo, RestaurantInfo } =
    React.useContext(MyContext)
  const loginlist = { login: themeID }

  const [open, setOpen] = React.useState(false)
  const handleOpen = section => setOpen(true)
  const handleClose = () => setOpen(false)
  return (
    <>
      <Log login={loginlist["login"]} handleOpen={handleOpen} />
      <ChangeBGModal
        WhichPart={"login"}
        handleClose={handleClose}
        open={open}
        PatchNewInfo={PatchNewInfo}
        SetRestaurantInfo={SetRestaurantInfo}
        RestaurantInfo={RestaurantInfo}
      />
    </>
  )
}

export default Login
