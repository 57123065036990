import React, { lazy, Suspense } from 'react';
import { MyContext } from '../../../App';

const Insch01 = lazy(() => import('./Chinese-Theme-01/InstagramBlock'));
const Insch02 = lazy(() => import('./Chinese-Theme-02/InstagramBlock'));
const Insit01 = lazy(() => import('./Italian-Theme-01/InstagramBlock'));
const Insit02 = lazy(() => import('./Italian-Theme-02/InstagramBlock'));
const Insit03 = lazy(() => import('./Italian-Theme-03/InstagramBlock'));
const Insar01 = lazy(() => import('./Arabian-Theme-01/InstagramBlock'));
const Inspz01 = lazy(() => import('./Pizza-Theme-01/InstagramBlock'));
const Inspz02 = lazy(() => import('./Pizza-Theme-02/InstagramBlock'));
const Inspb01 = lazy(() => import('./Pub-Theme-01/InstagramBlock'));
const Inssw01 = lazy(() => import('./Sweet-Theme-01/InstagramBlock'));
const Inscf01 = lazy(() => import('./Coffee-Theme-01/InstagramBlock'));

const InstagramBlock = ({ theme }) => {



  const { Images,RestaurantInfo } = React.useContext(MyContext)
  return (

    <div>

      {Images && Images?.length !== 0 && RestaurantInfo?.theme_photos?.home?.instagram?.isVisible &&
        <Suspense fallback={<div>Pagina in caricamento...</div>}>

          {theme === "Chinese-01" ?

            <Insch01 />
            :
            null

          }


          {theme === "Chinese-02" ?

            <Insch02 />
            :
            null

          }

          {theme === "Italian-01" ?


            <Insit01 />
            :
            null

          }

          {theme === "Italian-02" ?

            <Insit02 />
            :
            null

          }

          {theme === "Italian-03" ?

<Insit03 />
:
null

}

          {theme === "Arabian-01" ?

            <Insar01 />
            :
            null

          }
          {theme === "Pizza-01" ?

            <Inspz01 />
            :
            null

          }

          {theme === "Pizza-02" ?

            <Inspz02 />
            :
            null

          }
          
          {theme === "Pub-01" ?

            <Inspb01 />
            :
            null

          }
          
          {theme === "Sweet-01" ?

            <Inssw01 />
            :
            null

          }
          
          {theme === "Coffee-01" ?

            <Inscf01 />
            :
            null

          }

        </Suspense>}

    </div>

  );
}

export default InstagramBlock;