import React, { lazy, Suspense } from 'react';
import Skeleton from '@mui/material/Skeleton';

const Callpz02 = lazy(() => import('./Pizza-theme-02/CallToActions'));
const Callch02 = lazy(() => import('./Chinese-theme-02/CallToActions'));


const CallToActions = ({ theme }) => {

  return (

    <div>

      <Suspense fallback={<Skeleton variant="rectangular" width={"100%"} className="mt-[5%] " height={600}  animation="wave"/>}>

        {theme === "Pizza-02" ?

          <Callpz02 />
          :
          null

        }
        
        {/* {theme === "Chinese-02" ?

          <Callch02 />
          :
          null

        } */}

      </Suspense>

    </div>


  );
}

export default CallToActions;