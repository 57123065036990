import React from "react";
import ScrollToTop from "../../Reusable/ScrollToTop";
import Menu from "../../Components/Menu/Menu/Menu";
import MenuBanner from "../../Components/Menu/Banner/MenuBanner";
import DailyMenuBlock from "../../Components/Home/DailyMenuBlock/DailyMenuBlock";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { MyContext } from "../../App";

function MenuPage() {
  const { themeID, SetRestaurantInfo, RestaurantInfo } =
    React.useContext(MyContext);

  const Components = [
    // 0
    <DailyMenuBlock theme={themeID} />,
    // 1
    <Menu theme={themeID} />,
  ];
  const Italian01 = [0, 1, 2];
  const Italian02 = [0, 1, 2];
  const Italian03 = [0, 1, 2];
  const Arabian01 = [0, 1, 2];
  const Pizza01 = [0, 1, 2];
  const Pizza02 = [0, 1, 2];
  const Chinese01 = [0, 1, 2];
  const Chinese02 = [0, 1, 2];
  const Sweet01 = [0, 1, 2];
  const Pub01 = [0, 1, 2];
  const Coffee01 = [0, 1, 2];
  const American01 = [0, 1, 2];
  const Fish02 = [0, 1, 2];

  function theDesiredTheme() {
    if (themeID === "Italian-01") return Italian01;
    if (themeID === "Italian-02") return Italian02;
    if (themeID === "Italian-03") return Italian03;
    if (themeID === "Arabian-01") return Arabian01;
    if (themeID === "Pizza-01") return Pizza01;
    if (themeID === "Pizza-02") return Pizza02;
    if (themeID === "Chinese-01") return Chinese01;
    if (themeID === "Chinese-02") return Chinese02;
    if (themeID === "Sweet-01") return Sweet01;
    if (themeID === "Pub-01") return Pub01;
    if (themeID === "Coffee-01") return Coffee01;
    if (themeID === "American-01") return American01;
    if (themeID === "Fish-02") return Fish02;
  }

  const ischefSelectionThere = theDesiredTheme()?.filter(
    (item) => item === 1
  )[0];
  const isMenuThere = theDesiredTheme()?.filter((item) => item === 2)[0];

  return (
    <div>
      <ScrollToTop />

      {/*{ischefSelectionThere && (
        <div className="flex flex-col mt-32">
          <DailyMenuBlock theme={themeID} />
        </div>
      )}*/}

      {isMenuThere && <Menu theme={themeID} />}
    </div>
  );
}

export default MenuPage;
