import React, { lazy, Suspense } from "react";
import ScrollToTop from "../../Reusable/ScrollToTop";
import Skeleton from "@mui/material/Skeleton";
import Hero from "../../Components/Home/Hero/Pub-Theme-01/Hero";
import { MyContext } from "../../App";
import ChangeBGModal from "../../Components/Home/ChangeBGModal";

const Resit01 = lazy(() => import("../../Components/Reservation/Stepperr"));

function Prenotazioni() {
  const { themeID, PatchNewInfo, SetRestaurantInfo, RestaurantInfo } =
    React.useContext(MyContext);

  const [open, setOpen] = React.useState(false);
  const handleOpen = (section) => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className={`${themeID === "Pub-01" ? "mb-12" : "my-12"}`}>
        <ScrollToTop />
        {themeID === "Pub-01" && (
          <Hero
            handleOpen={handleOpen}
            title="PRENOTA IL
                    TUO TAVOLO"
            subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Morbi scelerisque elit laoreet condimentum imperdiet. "
          />
        )}
        <Suspense
          fallback={
            <Skeleton
              variant="rectangular"
              width={"100%"}
              className="mt-[5%] "
              height={600}
              animation="wave"
            />
          }
        >
          <Resit01 />
        </Suspense>
      </div>
      <ChangeBGModal
        WhichPart={"reservation"}
        handleClose={handleClose}
        open={open}
        PatchNewInfo={PatchNewInfo}
        SetRestaurantInfo={SetRestaurantInfo}
        RestaurantInfo={RestaurantInfo}
      />
    </>
  );
}

export default Prenotazioni;
