import React from 'react'
import dayjs from "dayjs";

function OrderCard({ order }) {

    return (

        <>

            <div className='flex flex-col w-fit p-4 text-left elevation-1 rounded-md'>

                <p className='text-[#5A5246] font-semibold text-lg sm:text-sm xsm:text-sm md:text-sm'> Ordine n.{order?._id} </p>

                <p className='text-[#5A5246] font-normal text-lg sm:text-sm xsm:text-sm md:text-sm' > <span className='font-semibold uppercase'> Data: </span> {dayjs(order?.createdAt).format('DD-MM-YYYY')} </p>

                {order.forDelivery &&
                    <p className='text-[#5A5246] font-normal text-lg sm:text-sm xsm:text-sm md:text-sm'> <span className='font-semibold uppercase'> Indirizzo: </span> {order?.billingAddress} </p>}

                <p className='text-[#5A5246] font-normal text-lg sm:text-sm xsm:text-sm md:text-sm '> <span className='font-semibold uppercase'> Metodo di Pagamento: </span> {order?.paymentMethod} </p>
            
            </div>

        </>

    )
}

export default OrderCard
