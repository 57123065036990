import axios from "axios";
import { getSiteId } from '../Data/userData';
import { baseUrl } from "./Urls";

const url = `https://${getSiteId()}.${process.env.REACT_APP_THEMES_PREFIX}${baseUrl()}${process.env.REACT_APP_THEMES_API_PATH}/customers`;
const endpoint2 = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}`;
const endpoint3 = `https://${getSiteId()}.${process.env.REACT_APP_THEMES_PREFIX}${baseUrl()}${process.env.REACT_APP_THEMES_API_PATH}`;

const user = JSON.parse(localStorage.getItem('currentCustomer'))





export const fetchCustomers = async () => await axios.get(`${url}`, { headers: { Authorization: `Bearer ${user.token}` } });
export const createCustomer = async (customer) => await axios.post(`${url}`, customer);
export const editCustomer = async (id, customer) => await axios.patch(`${url}/${id}`, customer);
export const editAuthUser = async (id, customer) => await axios.patch(`${url}/${id}`, customer, { headers: { Authorization: `Bearer ${user.token}` } });

export const changePassword = async (OldPassword, ConfirmPassword, NewPassword) => await axios.post(`${url}/update-password`, { oldPassword: OldPassword, confirmation: ConfirmPassword, newPassword: NewPassword }, { headers: { Authorization: `Bearer ${user.token}` } });

export const fetchCustomer = async (id) => axios.get(`${url}/${id}`, { headers: { Authorization: `Bearer ${user.token}` } });
export const patchCartStatus = async (id, cartStatus) =>
  await axios.patch(`${url}/${id}/cartStatus`, { cartStatus });

export const getOrdersForCustomer = email => axios.get(`${endpoint3}/orders/customer/email/${email}`, { headers: { Authorization: `Bearer ${user.token}` } })
export const getBookingsForCustomer = email => axios.get(`${endpoint3}/booking/customer/email/${email}`, { headers: { Authorization: `Bearer ${user.token}` } })