import axios from "axios";
import { getSiteId } from '../Data/userData';
import { baseUrl } from "./Urls";


const endpoint = `https://${getSiteId()}.${process.env.REACT_APP_THEMES_PREFIX}${baseUrl()}${process.env.REACT_APP_THEMES_API_PATH}/`;

export const fetchCatalogs = () => axios.get(`${endpoint}catalogs`);
export const fetchCatalog = (id) => axios.get(`${endpoint}catalogs/${id}`);
export const createCatalog = (product) =>
  axios.post(`${endpoint}catalogs`, product);
export const updateCatalog = (id, product) =>
  axios.put(`${endpoint}catalogs/${id}`, product);
export const deleteCatalog = (id) => axios.delete(`${endpoint}catalogs/${id}`);


export const editCatalog = async (id, menuData, headersconfig) => {

  try {
    const url = `https://${getSiteId()}.${process.env.REACT_APP_THEMES_PREFIX}${baseUrl()}${process.env.REACT_APP_THEMES_API_PATH}/catalogs/${id}`;
    const result = await axios.patch(url, menuData, headersconfig);

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    console.log(error)
  }
};


export async function handleEditCatalog(catalogId, menuData, headersconfig) {
  const body = { ...menuData }
  delete body?._id
  delete body?.catalogOrder
  try {
    await editCatalog(catalogId, body, headersconfig);
  } catch (error) {
    console.log(error)
  }
}