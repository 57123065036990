import React from "react"
import Reg from "../../Components/Register/Reg"
import { MyContext } from "../../App"
import ChangeBGModal from "../../Components/Home/ChangeBGModal"

function Register() {
  const { themeID, PatchNewInfo, SetRestaurantInfo, RestaurantInfo } = React.useContext(MyContext)
  const registerlist = { register: themeID }
  const [open, setOpen] = React.useState(false)
  const handleOpen = section => setOpen(true)
  const handleClose = () => setOpen(false)
  return (
    <>
      <Reg register={registerlist["register"]} handleOpen={handleOpen} />
      <ChangeBGModal
        WhichPart={"register"}
        handleClose={handleClose}
        open={open}
        PatchNewInfo={PatchNewInfo}
        SetRestaurantInfo={SetRestaurantInfo}
        RestaurantInfo={RestaurantInfo} />
    </>
  )
}

export default Register
