import {useParams, useNavigate} from "react-router-dom";
import React from "react";
import {fetchOrder, deleteOrder} from "../../Util/Api/OrderApi";

// import ilcanguro from "../../Assets/Image/error/il-canguro.svg";
// import poweredby from "../../Assets/Image/error/powered-by.svg";
// import restaurant from "../../Assets/Image/restaurant-club.svg";
// import errorepagamento from "../../Assets/Image/error/errore-pagamento.svg";
import scooty from "../../Assets/Image/error/Error.png";


function PaymentError() {
    const {id} = useParams();
    const navigate = useNavigate()

    React.useEffect(() => {
        const removeOrder = async () => {
            const response = await fetchOrder(id);
            const order = response.data;
            if (!order.isCompleted && !order.paymentConfirmed)
                await deleteOrder(id);
        }
        removeOrder()
    }, []);

    return (
        < div className="flex h-screen xsm:h-fit sm:h-fit md:h-fit xsm:flex-col-reverse sm:flex-col-reverse lg:flex-row xl:flex-row 2xl:flex-row items-center justify-center xsm:my-8 sm:my-8 md:my-12 lg:mt-16 xl:mt-16 2xl:mt-16  md:px-4 xl:px-16 lg:px-16 2xl:px-16 w-full" >
            <div className="flex flex-col w-1/2 xsm:w-[90%] sm:w-[90%]  xsm:mt-4 sm:mt-4 md:mt-4  ">
                <h2 className="text-[#343B32] text-left font-poppins xsm:text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-5xl font-extrabold">Oops! Il tuo pagamento</h2>
                <p className="text-[#343B32] text-left font-poppins xsm:text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-4xl font-normal mb-3">non è andato a buon fine!</p>
                <p className="text-primaryColor mb-3 text-left font-poppins xsm:text-lg sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-bold ">Controlla i tuoi dati e riprovaci!</p>
                <button onClick={() => navigate("/")} type="button" className="flex w-[315px] sm:w-[70%] md:w-[70%] xsm:w-[70%] h-[55px] sm:h-[40px] xsm:h-[40px]   text-white bg-primaryColor rounded-lg font-semibold font-poppins text-xl justify-center items-center">Torna alla Home</button>
            </div>
            <img src={scooty} className="w-1/3 sm:h-[250px] xsm:h-[250px] xsm:object-fit xsm:w-[80%] xsm:mx-auto sm:object-fit sm:w-[70%] sm:mx-auto md:object-fit md:w-[50%] md:mx-auto " alt="Scooty" />
        </div >
    )

}
export default PaymentError
