import React, { lazy, Suspense } from 'react';
import { MyContext } from '../../../App';

const Dlofcch01 = lazy(() => import('./Chinese-Theme-01/DailyMenuBlock'));
const Dlofcch02 = lazy(() => import('./Chinese-Theme-02/DailyMenuBlock'));
const Dlofcit01 = lazy(() => import('./Italian-Theme-01/DailyMenuBlock'));
const Dlofcit02 = lazy(() => import('./Italian-Theme-02/DailyMenuBlock'));
const Dlofcit03 = lazy(() => import('./Italian-Theme-03/DailyMenuBlock'));
const Dlofcar01 = lazy(() => import('./Arabian-Theme-01/DailyMenuBlock'));
const Dlofcpz01 = lazy(() => import('./Pizza-Theme-01/DailyMenuBlock'));
const Dlofcpz02 = lazy(() => import('./Pizza-Theme-02/DailyMenuBlock'));
const Dlofcpb01 = lazy(() => import('./Pub-Theme-01/DailyMenuBlock'));
const Dlofcsw01 = lazy(() => import('./Sweet-Theme-01/DailyMenuBlock'));
const Dlofccf01 = lazy(() => import('./Coffee-Theme-01/DailyMenuBlock'));
const DlofcAm01 = lazy(() => import("./American-Theme-01/dailyMenuBlock"))
const DlofcFs02 = lazy(() => import("./Fish-Theme-02/dailyMenuBlock"))


const DailyMenuBlock = ({ theme }) => {
  const { Catalogs ,RestaurantInfo } = React.useContext(MyContext)

  const FirstMenuProductsList = Catalogs?.map((cata) =>
  (
    {
      ...cata, categories: cata.categories.map((item) => (
        item.products.filter((prd) => prd.isAdviced === true).length === 0 ? null :
          { ...item, products: item.products.filter((prd) => prd.isAdviced === true) }

      )).filter((item) => item !== null)
    }
  )
  )?.filter((item) => item.categories.length !== 0)
  return (
<>{RestaurantInfo?.theme_photos?.home?.chefSelection?.isVisible &&
    <div>

    { FirstMenuProductsList && FirstMenuProductsList?.length !== 0 && 
        <Suspense fallback={<div>Pagina in caricamento...</div>}>

          {theme === "Chinese-01" ?

            <Dlofcch01 />
            :
            null
            
          }
          {theme === "Chinese-02" ?

            <Dlofcch02 />
            :
            null
            
          }

          {theme === "Italian-01" ?

            <Dlofcit01 />
            :
            null
            
          }
          {theme === "Italian-02" ?

            <Dlofcit02 />
            :
            null
            
          }
          {theme === "Italian-03" ?

<Dlofcit03 />
:
null

}

          {theme === "Arabian-01" ?

            <Dlofcar01 />
            :
            null
            
          }
          {theme === "Pizza-01" ?

            <Dlofcpz01 />
            :
            null
            
          }
          {theme === "Pizza-02" ?

            <Dlofcpz02 />
            :
            null
            
          }
          {theme === "Pub-01" ?

            <Dlofcpb01 />
            :
            null
            
          }
          {theme === "Sweet-01" ?

            <Dlofcsw01 />
            :
            null
            
          }
          {theme === "Coffee-01" ?

            <Dlofccf01 />
            :
            null
            
          }
          {theme === "American-01" ?

            <DlofcAm01 />
            :
            null
            
          }
          {theme === "Fish-02" ?

            <DlofcFs02 />
            :
            null
            
          }
        </Suspense>
      }

    </div>}

      </>
  );
}

export default DailyMenuBlock;