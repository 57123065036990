import React, { lazy, Suspense } from "react";
import Skeleton from "@mui/material/Skeleton";
const Navch01 = lazy(() => import("./Chinese-Theme-01/Nav"));
const Navch02 = lazy(() => import("./Chinese-Theme-02/Nav"));
const Navpz01 = lazy(() => import("./Pizza-Theme-01/Nav"));
const Navpz02 = lazy(() => import("./Pizza-Theme-02/Nav"));
const Navit01 = lazy(() => import("./Italian-Theme-01/Nav"));
const Navit02 = lazy(() => import("./Italian-Theme-02/Nav"));
const Navit03 = lazy(() => import("./Italian-Theme-03/Nav"));
const Navar01 = lazy(() => import("./Arabian-Theme-01/Nav"));
const NavSweet01 = lazy(() => import("./Sweet-Theme-01/Nav"));
const Navam01 = lazy(() => import("./American-Theme-01/nav"))
const Navfs02 = lazy(() => import("./Fish-Theme-02/nav"))

const Navbar = ({ nav }) => {


  
  return (
    <div>
      <Suspense fallback={<Skeleton variant='rectangular' width={"100%"} height={70} />}>
        {nav === "Chinese-01" ? <Navch01 /> : null}

        {nav === "Chinese-02" ? <Navch02 /> : null}

        {nav === "Pizza-01" ? <Navpz01 /> : null}

        {nav === "Pizza-02" ? <Navpz02 /> : null}

        {nav === "Italian-01" ? <Navit01 /> : null}

        {nav === "Italian-02" ? <Navit02 /> : null}

        {nav === "Italian-03" ? <Navit03 /> : null}

        {nav === "Arabian-01" ? <Navar01 /> : null}

        {nav === "Sweet-01" ? <NavSweet01 /> : null}

        {nav === "American-01" ? <Navam01 /> : null}

        {nav === "Fish-02" ? <Navfs02 /> : null}

      </Suspense>
    </div>
  );
};

export default Navbar;
