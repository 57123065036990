import axios from "axios";
import { getSiteId } from '../Data/userData';
import { baseUrl } from "./Urls";

const endpoint = `https://${getSiteId()}.${process.env.REACT_APP_THEMES_PREFIX}${baseUrl()}${process.env.REACT_APP_THEMES_API_PATH}/`;
const user = JSON.parse(localStorage.getItem('currentCustomer'));
const headers = {
    'Access-Control-Allow-Origin': `${endpoint}orders`,
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With, Accept, access-control-allow-origin,access-control-allow-methods,access-control-allow-headers',
};

export const fetchOrders = () => axios.get(`${endpoint}orders`);
export const fetchOrder = (id) => axios.get(`${endpoint}orders/${id}`);
export const createOrder = (order) => axios.post(`${endpoint}orders`, order, headers);
export const stripeCheckout = (orderId, items, domain, costOfDelivery) => axios.post(`${endpoint}stripe/checkout`, { orderId, items, domain, costOfDelivery: costOfDelivery || 0 });
export const updateOrder = (id, order) => axios.put(`${endpoint}orders/${id}`, order);
export const deleteOrder = (id) => axios.delete(`${endpoint}orders/${id}`);

