import React from "react";
import axios from "axios";
import { baseUrl } from "../../Api/Urls";
import { getSiteId } from "../../Data/userData";

const endpoint = localStorage.getItem("currentCustomer") ? `https://${getSiteId()}.${process.env.REACT_APP_THEMES_PREFIX}${baseUrl()}${process.env.REACT_APP_THEMES_API_PATH}/customers` : `https://${getSiteId()}.${process.env.REACT_APP_THEMES_PREFIX}${baseUrl()}${process.env.REACT_APP_THEMES_API_PATH}/customers/all/customers`;




export const getCustomerById = async (id, token) => {
    return await axios.get(`${endpoint}/${id}`, { headers: { Authorization: `Bearer ${token}` } });
};

let userId = localStorage.getItem("currentCustomer")
    ? JSON.parse(localStorage.getItem("currentCustomer")).id
    : "";
let token = localStorage.getItem("currentCustomer")
    ? JSON.parse(localStorage.getItem("currentCustomer")).token
    : "";

let user = getCustomerById(userId, token);

// call Api customer get by id


export const initialState = {
    customer: "" || user,
    token: "" || token,
    loading: false,
    errorMessage: null
};

export const AuthorizationReducers = (initialState, action) => {
    switch (action.type) {
        case "REQUEST_LOGIN":
            return {
                ...initialState,
                loading: true
            };
        case "LOGIN_SUCCESS":
            return {
                ...initialState,
                user: action.payload.user,
                token: action.payload.auth_token,
                loading: false
            };
        case "LOGOUT":
            return {
                ...initialState,
                user: "",
                token: ""
            };

        case "LOGIN_ERROR":
            return {
                ...initialState,
                loading: false,
                errorMessage: action.error
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
