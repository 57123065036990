import axios from "axios";
import { getSiteId } from "../../Util/Data/userData";
import { baseUrl } from "../../Util/Api/Urls";

const baseUrlThemes = `https://${getSiteId()}.${
  process.env.REACT_APP_THEMES_PREFIX
}${baseUrl()}${process.env.REACT_APP_THEMES_API_PATH}`;

const url = `${baseUrlThemes}/upload/image`;
const dashSessionToken = sessionStorage.getItem("dashToken");
const headersconfig = {
  headers: {
    "Content-Type": "application/json",
    authorization: `Bearer ${JSON.parse(dashSessionToken)}`,
  },
};
export default async function UploadImageToCDN(file) {
  let data = new FormData();
  data.append("file", file);
  try {
    const res = await axios.post(`${url}`, data, headersconfig);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}
