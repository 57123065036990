// import React, { lazy, Suspense, useEffect } from "react";
// import { MyContext } from "../../App";
// import ScrollToTop from "../../Reusable/ScrollToTop";
// import Hero from "../../Components/Home/Hero/Pub-Theme-01/Hero";
// import ChangeBGModal from "../../Components/Home/ChangeBGModal";
// import LoginModal from "../../Components/Delivery/CheckoutPanel/LoginModal";

// const Deliveryit01 = lazy(() => import("../../Components/Delivery/Delivery"));

// function Delivery() {
//   const { themeID, PatchNewInfo, SetRestaurantInfo, RestaurantInfo } =
//     React.useContext(MyContext);

//   const [open, setOpen] = React.useState(false);
//   const handleOpen = (section) => setOpen(true);
//   const handleClose = () => setOpen(false);
//   const [open3, setOpen3] = React.useState(false);
//   const handleClose3 = () => setOpen3(false);
//   const handleOpen3 = () => setOpen3(true);
//   const user = JSON.parse(localStorage.getItem("currentCustomer"));

//   useEffect(() => {
//     if (user === null) handleOpen3();
//   }, [user]);

//   return (
//     <>
//       <div className="flex flex-col gap-y-4">
//         <ScrollToTop />
//         <Suspense fallback={<div>Pagina in caricamento...</div>}>
//           {themeID === "Pub-01" ? (
//             <Hero
//               handleOpen={handleOpen}
//               title="DELIVERY"
//               subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
//               Morbi scelerisque elit laoreet condimentum imperdiet. "
//             />
//           ) : (
//             <div className="flex flex-row w-full justify-center items-center mx-auto mt-28 sm:mt-8 xsm:mt-8 md:mt-8 mb-8">
//               <h1 className="flex text-5xl xsm:text-lg sm:text-xl md:text-xl text-[#0B0B0B]">
//                 {" "}
//                 Pronti a portarti il miglior cibo italiano della città!{" "}
//               </h1>
//             </div>
//           )}
//         </Suspense>
//         <Suspense fallback={<div>Pagina in caricamento...</div>}>
//           <Deliveryit01 />
//         </Suspense>
//       </div>
//       <ChangeBGModal
//         WhichPart={"delivery"}
//         handleClose={handleClose}
//         open={open}
//         PatchNewInfo={PatchNewInfo}
//         SetRestaurantInfo={SetRestaurantInfo}
//         RestaurantInfo={RestaurantInfo}
//       />
//       <LoginModal open={open3} handleClose={handleClose3} />
//     </>
//   );
// }

// export default Delivery;
import React, { lazy, Suspense } from 'react';
import { MyContext } from '../../App';
import ScrollToTop from '../../Reusable/ScrollToTop';
import Hero from '../../Components/Home/Hero/Pub-Theme-01/Hero';
import ChangeBGModal from '../../Components/Home/ChangeBGModal';

const Deliveryit01 = lazy(() => import("../../Components/Delivery/Delivery"));




function Delivery() {
  const { themeID, PatchNewInfo, SetRestaurantInfo, RestaurantInfo } = React.useContext(MyContext)

  const [open, setOpen] = React.useState(false)
  const handleOpen = (section) => setOpen(true)
  const handleClose = () => setOpen(false);

  return (

    <>

      <div className='flex flex-col gap-y-4'>

        <ScrollToTop />
        <Suspense fallback={<div>Pagina in caricamento...</div>}>
          {
            themeID === "Pub-01" ?
              <Hero handleOpen={handleOpen} title="DELIVERY" subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
              Morbi scelerisque elit laoreet condimentum imperdiet. " />
              :
              <div className='flex flex-row w-full justify-center items-center mx-auto mt-28 sm:mt-8 xsm:mt-8 md:mt-8 mb-8'>
                <h1 className="flex text-5xl xsm:text-lg sm:text-xl md:text-xl text-[#0B0B0B]" style={{ fontFamily: 'var(--primary-font)' }}> Pronti a portarti il miglior cibo italiano della città! </h1>
              </div>
          }
        </Suspense>
        <Suspense fallback={<div>Pagina in caricamento...</div>}>
          <Deliveryit01 />
        </Suspense>
      </div>
      <ChangeBGModal WhichPart={"delivery"} handleClose={handleClose} open={open} PatchNewInfo={PatchNewInfo} SetRestaurantInfo={SetRestaurantInfo} RestaurantInfo={RestaurantInfo} />

    </>
  )
}

export default Delivery