import React from 'react';
import '../../App.css';
import ScrollToTop from '../../Reusable/ScrollToTop';


const Privacypolicy = () => {

  return (

    <>

      <ScrollToTop />

      <div className="flex flex-col w-[80%] text-left justify-center items-center mx-auto pt-20 ">

        <h1 className="my-8 Brevia700 text-4xl text-[#928471] text-center">BANNER COOKIES</h1>

        <p><span className="font-bold"> https://NOME SITO RISTORATORE </span> utilizza cookie tecnici e di profilazione per gestire al meglio il sito internet. Continuando la navigazione si accetta l’utilizzo dei cookie. Per maggiori informazioni o per negare il consenso consultare la <span className="font-bold"> cookie policy (LINK COOKIE POLICY) </span>. </p>


        <br />


        <h1 className="my-8 Brevia700 text-4xl text-[#928471] text-center">COOKIE POLICY</h1>

        <p>Tramite questa pagina AiGot. S.r.l. e NOME RISTORATORE (Titolari del trattamento dei Dati Personali, d’ora innanzi Titolari) forniscono all’Utente informazioni su tipologie, finalità e modalità di utilizzo e gestione dei cookie presenti sul sito internet SITO INTERNET RISTORATORE
          (d’ora innanzi Sito) nel rispetto della normativa in vigore (D.Lgs. 196/2003 e Reg. UE 679/2016 – GDPR).
        </p>

        <br />

        <div className="w-full ">
          <p className="my-5 Brevia700 text-2x1 font-bold text-start ">COSA SONO I COOKIE?</p>

          <p>I cookie sono porzioni di informazioni che il Sito inserisce nel dispositivo di navigazione dell’Utente (computer, tablet, smartphone) quando visita una pagina. Possono comportare la trasmissione di informazioni tra il sito web ed il dispositivo, e tra quest’ultimo e altri siti che operano per conto dei Titolari o in privato.
            <br></br>Sono utilizzati, ad esempio, per consentire o migliorare la navigazione tra le pagine del Sito, registrando e ricordando le preferenze dell’Utente. Inoltre, solo previo consenso dell’Utente, il Sito potrebbe utilizzare i cookie per mostrare, sia dal Sito stesso o da siti web di terzi, annunci pubblicitari basati sull'analisi delle abitudini di navigazione dell'Utente.

          </p>

          <br />
        </div>

        <div className=" w-full ">
          <p className="my-5 Brevia700 text-2x1 font-bold text-start ">TIPOLOGIE DI COOKIE UTILIZZATI</p>

          <p>Il Sito potrebbe utilizzare differenti tipologie di cookie, anche in dipendenza delle preferenze e del consenso fornito dall’Utente:

          </p>

          <br />

          <div className=' w-full'>

          <p className="my-5 Brevia700 text-2x1 font-bold text-start ">Cookie Tecnici</p>

          <p>I cookie tecnici possono essere suddivisi in cookie di navigazione (o permanenti) o cookie di performance.

          </p>

          <br />
    

        <div className="flex flex-col">

          <ol className="list-decimal">

            <div className="flex flex-col ml-4 mb-2">

              <p className="mr-2 font-bold">1) Di navigazione / permanenti </p>

              <br></br>

              <p>	I cookie di navigazione e permanenti sono essenziali per sfruttare appieno le caratteristiche del Sito ad avere accesso ai servizi (ad esempio autenticare l’Utente al momento dell’accesso, se si tratta di un Utente precedentemente registrato).
                I cookie di navigazione sono attivi soltanto per la durata di una sessione di navigazione, mentre i cookie permanenti rimangono attivi per più visite e vengono utilizzati per ricordare le preferenze dell’Utente e memorizzare le azioni compiute durante le visite sul Sito.
                Questi cookie consentono al Sito di memorizzare le scelte fatte dall’Utente (nome, lingua o luogo di provenienza) e forniscono funzionalità avanzate personalizzate. Questi cookie possono essere utilizzati anche per ricordare le modifiche apportate alla dimensione del testo, font ed altre parti di pagine web che è possibile personalizzare.</p>

            </div>



            <div className="flex flex-col ml-4 mb-2 mt-8">

              <p className="mr-2 font-bold">2) Di performance </p>

              <br></br>

              <p>	Tali cookie raccolgono informazioni riguardo alle modalità di utilizzo del Sito da parte degli utenti al fine di migliorare la qualità dello stesso e dei servizi offerti, tenendo traccia delle pagine visitate con maggiore frequenza e delle attività sul sito. I cookie di performance consentono ai Titolari di applicare le scelte effettuate dall’Utente e di ottimizzare il funzionamento del sito, permettendo anche l’immediata identificazione e risoluzione di tutte le problematiche tecniche che si potrebbero incontrare durante la navigazione all’interno del Sito. Tutte le informazioni che questi cookie conservano sono aggregate e anonime e non vengono utilizzate per raccogliere dati personali a scopi pubblicitari.</p>

            </div>


            <br></br>






          </ol>

        </div>

        <br />

        </div>


        <div className=' w-full'>

<p className="my-5 Brevia700 text-2x1 font-bold text-start ">Cookie di profilazione </p>

<p>Oltre ai cookie tecnici, il Sito potrebbe utilizzare anche i cookie di profilazione.
Mediante tali cookie, il Sito può creare un profilo di ciascun Utente, basato sulle preferenze ed i gusti manifestati durante la navigazione nel web, anche al fine di inviare messaggi coerenti con il profilo dell’Utente.
Come previsto dalla normativa vigente, il Sito utilizza i cookie di profilazione solo previo consenso dell’Utente, espresso mediante il banner presente al primo accesso al sito internet; inoltre, ciascun Utente è libero di bloccare l’installazione dei cookie di profilazione in qualsiasi momento, senza che la possibilità di visitare il sito e fruire dei suoi contenuti sia in qualsiasi modo compromessa.
Le informazioni raccolte sono a beneficio esclusivo dei Titolari e verranno condivise con soggetti terzi solo per le finalità di elaborazione di tali informazioni per conto della Società stessa. In determinate circostanze, i Titolari potrebbero incrociare i dati raccolti tramite cookie con i dati personali dell’Utente raccolti per le finalità indicate nella Privacy Policy pubblicata sul medesimo Sito.


</p>

<br />



</div>


<div className=' w-full'>

<p className="my-5 Brevia700 text-2x1 font-bold text-start ">Cookie propri o di terze parti</p>

<p>I cookie possono essere suddivisi in cookie propri o cookie di terze parti:</p>

<br />


<div className="flex flex-col">

<ol className="list-decimal">

  <div className="flex flex-col ml-4 mb-2">

    <p> 1)	I <span className="font-bold"> cookie propri </span> vengono inviati direttamente dal Sito e possono essere letti esclusivamente da questo. Si tratta, ad esempio, dei cookie che ricordano quali articoli sono stati inseriti nel carrello di un Utente mentre l'Utente prosegue nella navigazione sul Sito. </p>
    <br></br>
    <p> 2)	I <span className="font-bold"> cookie di terze parti  </span> sono cookie predisposti e gestiti da siti diversi ed installati per il tramite del Sito.
Alcuni hanno lo scopo di ottenere i dati in modo anonimo, ad esempio le statistiche di navigazione. 
Altri cookie di terze parti, invece, potrebbero tenere traccia della risposta fornita dai visitatori alle attività del Sito, eventualmente valutando le visite da parte dell’Utente a tale Sito o ai siti di terze parti su cui si trovano annunci pubblicitari, per visualizzare annunci mirati o individuare quali banner pubblicitari sono in grado di attirare utenti.
</p>


  </div>



  <br></br>






</ol>

</div>

<br />

</div>




</div>


<div className=" w-full ">
          <p className="my-5 Brevia700 text-2x1 font-bold text-start ">GESTIONE DELLE PREFERENZE RIGUARDO AI COOKIE</p>

          <p>Ciascun Utente ha la possibilità di scegliere in qualsiasi momento di limitare, eliminare, bloccare o limitare parzialmente l’uso dei cookie modificando le impostazioni del proprio browser di navigazione. 
La configurazione dei cookie si trova normalmente nel menu "Preferenze" o "Strumenti".  <br></br>
Tramite i seguenti collegamenti è possibile personalizzare la gestione dei cookie dei browser più diffusi:
</p>

          <br />

          <ul className="flex flex-col list-disc gap-y-4">

         

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><a>Chrome</a></li>

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><a>Firefox</a></li>


            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><a>Internet Explorer 7 en 8 </a></li>


            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><a>Internet Exolorer 9</a></li>

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><a>Safai</a></li>

<li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><a>Opera</a></li>


<li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><a>Facebook </a></li>


<li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><a>Instagram</a></li>

<li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><a>Whatsapp</a></li>

          

          </ul>

          <br />

          <p>Si ricorda che la completa disabilitazione dei cookie potrebbe compromettere la corretta funzionalità di alcune parti del Sito (navigazione tra le pagine del sito, la possibilità di fare acquisti, perdita delle funzioni di personalizzazione). 
</p>

          <br />




</div>


<div className=" w-full ">
          <p className="my-5 Brevia700 text-2x1 font-bold text-start ">PRIVACY POLICY </p>

          <p>Per maggiori informazioni in merito al trattamento dei dati personali svolto dal Sito, si rimanda alla Privacy Policy (link privacy policy del sito dei Ristoratori).
</p>

          <br />





</div>


       


        <p className="flex self-start mb-8"> Ultimo aggiornamento: 13/05/2022 </p>

      </div>

    </>

  )
}

export default Privacypolicy;
