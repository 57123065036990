import React, { lazy, Suspense } from 'react';
import { MyContext } from "../../../App";



const Mapch01 = lazy(() => import('./Chinese-Theme-01/Gmap'));
const Mapch02 = lazy(() => import('./Chinese-Theme-02/Gmap'));
const Mappz01 = lazy(() => import('./Pizza-Theme-01/Gmap'));
const Mappz02 = lazy(() => import('./Pizza-Theme-02/Gmap'));
const Mapit01 = lazy(() => import('./Italian-Theme-01/Gmap'));
const Mapit02 = lazy(() => import('./Italian-Theme-02/Gmap'));
const Mapit03 = lazy(() => import('./Italian-Theme-03/Gmap'));
const Mapar01 = lazy(() => import('./Arabian-Theme-01/Gmap'));
const Mappb01 = lazy(() => import('./Pub-Theme-01/Gmap'));
const Mapsw01 = lazy(() => import('./Sweet-Theme-01/Gmap'));
const MapAm01 = lazy(() => import("./American-Theme-01/Gmap"))

const Map = ({ theme }) => {
  const {  RestaurantInfo } = React.useContext(MyContext)
  return (

    <div>

{RestaurantInfo?.theme_photos?.home?.map?.isVisible &&     
 <Suspense fallback={<div>Pagina in caricamento...</div>}>


        {theme === "Chinese-01" ?

          <Mapch01 />
          :
          null

        }
        {theme === "Chinese-02" ?

          <Mapch02 />
          :
          null

        }


        {theme === "Pizza-01" ?

          <Mappz01 />
          :
          null

        }

        {theme === "Pizza-02" ?

          <Mappz02 />
          :
          null

        }

        {theme === "Italian-01" ?

          <Mapit01 />
          :
          null

        }
        {theme === "Italian-02" ?

          <Mapit02 />
          :
          null

        }

        {theme === "Italian-03" ?

<Mapit03 />
:
null

}

        {theme === "Arabian-01" ?

          <Mapar01 />
          :
          null

        }

        {theme === "Pub-01" ?

          <Mappb01 />
          :
          null

        }

        {theme === "Sweet-01" ?

          <Mapsw01 />
          :
          null

        }

        {theme === "American-01" ?

          <MapAm01 />
          :
          null

        }

      </Suspense>}

    </div>


  );
}

export default Map;