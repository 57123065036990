import React from "react";
import scooty from "../../Assets/Image/thank-you/mobile.png";
import '../../App.css';
import { useParams, useNavigate, useLocation } from "react-router-dom"
// import { createBooking } from "../../Util/Api/BookingApi";
import { MyContext } from '../../App';

function ThankYou() {
    const { RestaurantInfo, SetCart, SetTotal,setSocketBooking,createBooking } = React.useContext(MyContext)


    const param = useParams()
    const { state } = useLocation()
    const navigate = useNavigate()
    const booking = localStorage.getItem("booking")
    const user = JSON.parse(localStorage.getItem('currentCustomer'))

    async function CreateBooking() {
        try {
            setSocketBooking(JSON.parse(booking))
            await createBooking(JSON.parse(booking))
            localStorage.removeItem("booking")
        } catch (error) {
            console.log(error)
            localStorage.removeItem("booking")
        }
    }

    React.useEffect(() => {
        if (!param.id && !state) {
            navigate("/payment/error")
        } else if (param.id && booking) {
            CreateBooking()
        }
        SetCart([])
        SetTotal(0)
    }, [])

    return (

        <div className="flex h-screen xsm:h-fit sm:h-fit md:h-fit xsm:flex-col-reverse sm:flex-col-reverse lg:flex-row xl:flex-row 2xl:flex-row items-center justify-center xsm:my-8 sm:my-8 md:my-12 lg:mt-16 xl:mt-16 2xl:mt-16  md:px-4 xl:px-16 lg:px-16 2xl:px-16 w-full" >

            <div className="flex flex-col w-1/2 xsm:w-[90%] sm:w-[90%]  xsm:mt-4 sm:mt-4 md:mt-4  justify-center">

                <h2 className="text-[#343B32] text-left font-poppins xsm:text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-5xl font-extrabold mb-3" placeholder="Ristorante">{RestaurantInfo?.name}  <br /> ha ricevuto {state && state === true ? "la tua prenotazione!" : "il tuo ordine!"} </h2>
                <p className="text-primaryColor text-left font-poppins xsm:text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-4xl font-bold mb-3">Grazie {user?.firstName}! Ottima Scelta!</p>
                {/* state && state === true && <p className="text-black text-left font-poppins text-xl font-normal mb-8">N° Ordine : {param?.orderNumber ?? 1}</p>}
                { !state && param?.id && <p className="text-black text-left font-poppins text-xl font-normal mb-8">N° Ordine : {param?.orderNumber ?? 1}</p>}
                { state && state !== true && <p className="text-black text-left font-poppins text-xl font-normal mb-8">N° Ordine : {state}</p> */}
                <button onClick={() => navigate("/")} type="button" className="flex w-[315px] sm:w-[70%] md:w-[70%] xsm:w-[70%] h-[55px] sm:h-[40px] xsm:h-[40px]   text-white bg-primaryColor rounded-lg font-semibold font-poppins text-xl justify-center items-center">Torna alla Home</button>

            </div>

            <img src={scooty} className="w-1/3 sm:h-[250px] xsm:h-[250px] xsm:object-fit xsm:w-[80%] xsm:mx-auto sm:object-fit sm:w-[70%] sm:mx-auto md:object-fit md:w-[50%] md:mx-auto " alt="Scooty" />

        </div>

    )
}
export default ThankYou


