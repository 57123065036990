import React from "react";
import "./sidebarMobile.css";
import LogoBeer from "../../../../../Assets/Image/logobeer.png";
import { navPubTheme } from "../../../../../Util/Constants/navPubTheme";
import { NavLink } from "react-router-dom";
import { BsSearch, BsFillCartFill } from "react-icons/bs";

const SidebarMobile = () => {
  return (
    <aside className='SidebarMobile'>
      <img src={LogoBeer} alt='sidebar' />
      <ul className='Hero__sidebar-list'>
        {navPubTheme.map((item, index) => {
          return (
            <li key={index} className='Hero__list-item'>
              <NavLink
                to={item.path}
                className={({ isActive }) => (isActive ? "Hero-item--active" : "")}
                end
              >
                {item.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
      <ul className='Hero__option'>
        <li className='Hero__option-item'>
          <BsSearch />
        </li>
        <li className='Hero__option-item'>
          <BsFillCartFill />
        </li>
      </ul>
    </aside>
  );
};

export default SidebarMobile;
