import { Route, Routes } from "react-router-dom";
import React, { useEffect } from 'react'

import Login from "./Pages/Login/Login";

import Register from "./Pages/Register/Register";

import Profile from "./Pages/Profile/Profile";

import Contatti from "./Pages/Contatti/Contatti";

import HomePage from "./Pages/Home/HomePage";

import Menu from './Pages/Menu/Menu';

import Prenotazioni from './Pages/Prenotazioni/Prenotazioni';

import Delivery from './Pages/Delivery/Delivery';
import Checkout from './Pages/Checkout/CheckoutMain';
import ThankYou from "./Pages/Checkout/ThankYou";
import PaymentError from "./Pages/Checkout/PaymentError";
import Terminiecondizioni from './Pages/PrivacyPolicy/Termini-e-condizioni';
import InformativaPrivacy from './Pages/PrivacyPolicy/Informativa-Privacy';
import CookiePolicy from './Pages/PrivacyPolicy/Cookie-Policy-RISTORATORI';
import { MyContext } from './App';
import RecoveryPassword from "./Pages/Profile/RecoveryPassword/RecoveryPassword";
import RequestPassword from "./Pages/Profile/RecoveryPassword/RequestPassword";
import Errornotfound from "./Reusable/Errors/404";

function Routing() {


  const currentPlan = localStorage.getItem("currentPlan");
  const { DeliverySettings, ReservationSettings } = React.useContext(MyContext)
  return (


    <Routes>

      <Route exact path="/" element={<HomePage />} />

      <Route exact path="/login" element={<Login />} />

      <Route exact path="/register" element={<Register />} />

      <Route exact path="/profile" element={<Profile />} />

      <Route exact path="/recovery-password/:token" element={<RecoveryPassword />} />

      <Route exact path="/request-password" element={<RequestPassword />} />

      <Route exact path="/contatti" element={<Contatti />} />

      <Route exact path="/menu" element={<Menu />} />



      {/* ************************** customizing the website's routes ************************* */}
      <Route exact path="/customize-theme/" element={<HomePage />} />
      <Route exact path="/customize-theme/contatti" element={<Contatti />} />
      <Route exact path="/customize-theme/menu" element={<Menu />} />
      <Route exact path="/customize-theme/delivery" element={<Delivery />} />
      <Route exact path="/customize-theme/prenotazioni" element={<Prenotazioni />} />
      <Route exact path="/customize-theme/login" element={<Login />} />
      <Route exact path="/customize-theme/register" element={<Register />} />
      {/* ------------------------------------------------------------------------------ */}

      <Route exact path="/termini-e-condizoni" element={<Terminiecondizioni />} />
      <Route exact path="/informativa-Privacy" element={<InformativaPrivacy />} />
      <Route exact path="/cookie-Policy" element={<CookiePolicy />} />



      <Route exact path="/payment/thank-you/:id" element={<ThankYou />} />
      <Route exact path="/payment/thank-you" element={<ThankYou />} />
      <Route exact path="/payment/error/" element={<PaymentError />} />
      <Route exact path="/payment/error/:id" element={<PaymentError />} />

            {currentPlan === "nodelivery" || currentPlan === "none" ? (
              // <Route exact path="/delivery" element={<HomePage />} />
    <Route path="/delivery" element={<Errornotfound />} />

      ) : (
        <>
          {DeliverySettings?.isDeliveryAvailable && <Route exact path="/delivery" element={<Delivery />} />}
      <Route exact path="/delivery/checkout" element={<Checkout />} />

        </>
      )}

{currentPlan === "nobooking" || currentPlan === "none" ? (
              // <Route exact path="/prenotazioni" element={<HomePage />} />
    <Route path="/prenotazioni" element={<Errornotfound />} />

      ) : (
       <>
      {ReservationSettings?.isActive && <Route exact path="/prenotazioni" element={<Prenotazioni />} />}


        </>
      )}

    <Route path="*" element={<Errornotfound />} />


    </Routes>

  )
}

export default Routing
