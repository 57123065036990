import React, { lazy, Suspense } from "react";

const Dlofcch01 = lazy(() => import("./Chinese-Theme-01/DailyOfferCarousel"));
const Dlofcch02 = lazy(() => import("./Chinese-Theme-02/DailyOfferCarousel"));
const Dlofcit01 = lazy(() => import("./Italiano-Theme-01/DailyOfferCarousel"));
const Dlofcit02 = lazy(() => import("./Italiano-Theme-02/DailyOfferCarousel"));
const Dlofcit03 = lazy(() => import("./Italiano-Theme-03/DailyOfferCarousel"));
const Dlofcpz01 = lazy(() => import("./Pizza-Theme-01/DailyOfferCarousel"));
const Dlofcpz02 = lazy(() => import("./Pizza-Theme-02/DailyOfferCarousel"));
const Dlofcar01 = lazy(() => import("./Arabian-Theme-01/DailyOfferCarousel"));
const Dlofcam01 = lazy(() => import("./American-Theme-01/DailyOfferCarousel"));
const Dlofcsw01 = lazy(() => import("./Sweet-Theme-01/DailyOfferCarousel"));

const DailyOfferCarousel = ({ theme }) => {
  return (
    <div>
      <Suspense fallback={<div>Page is Loading...</div>}>
        {theme === "Chinese-01" ? <Dlofcch01 /> : null}
        {theme === "Chinese-02" ? <Dlofcch02 /> : null}

        {theme === "Italian-01" ? <Dlofcit01 /> : null}
        {theme === "Italian-02" ? <Dlofcit02 /> : null}
        {theme === "Italian-03" ? <Dlofcit02 /> : null}
        {theme === "Pizza-01" ? <Dlofcpz01 /> : null}
        {theme === "Pizza-02" ? <Dlofcpz02 /> : null}
        {theme === "Arabian-01" ? <Dlofcar01 /> : null}
        {theme === "American-01" ? <Dlofcam01 /> : null}
        {theme === "Sweet-01" ? <Dlofcsw01 /> : null}
      </Suspense>
    </div>
  );
};

export default DailyOfferCarousel;
