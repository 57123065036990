import React from 'react';
import '../../App.css';
import ScrollToTop from '../../Reusable/ScrollToTop';


const Privacypolicy = () => {

  return (

    <>

    <ScrollToTop />

    <div className="flex flex-col w-[80%] text-left justify-center items-center mx-auto pt-20">

      <h1 className="my-8 Brevia700 text-5xl text-[#928471]">Termini e Condizioni</h1>
      
      <p>

      Il presente documento riporta i termini e le condizioni generali di utilizzo del sito  
   <span className="font-bold"> www.restaurants.club.</span>  di proprietà di AiGot S.r.l., tramite il quale vengono fornite informazioni commerciali sul Software denominato “Restaurants Club” e i relativi servizi nonché eventuali altri prodotti e servizi della Società. 
Tramite il sito web possono inoltre essere acquistati e attivati il Software Restaurants Club e i relativi servizi, nonché eventuali altri prodotti e servizi della Società e possono essere effettuati pagamenti.
Tramite il sito web la Società può raccogliere i dati personali degli utenti.



      </p>


      <br/>

      <div className="flex flex-col">

        <ol className="list-decimal">

          <div className="flex flex-row ml-4 mb-2">

            <p className="mr-2 font-bold">1.</p><span className="font-bold">Definizioni</span>

          </div>

          <p className="ml-10 mb-2">Ai fini del presente documento, i seguenti termini (sia al singolare che al plurale) hanno il significato di seguito indicato:</p>

          <ul className="flex flex-col list-disc gap-y-4">

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p><span className="font-bold">Società: AiGot S.r.l.</span> p.iva / c.f. 11498080966, con sede legale in Milano (MI) via Visconti di Modrone 38, cap 20122, e sede operativa in Pisa (PI) via Guglielmo Oberdan 14, cap 56127 -  capitale sociale interamente versato 110.000,00 € -  indirizzo PEC aigot@pec.it;</p></li>

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p><span className="font-bold">Sito:</span> il sito web  <span className="font-bold">www.restaurants.club</span></p></li>

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p><span className="font-bold">Prodotti e Servizi: </span> i prodotti in formato digitale ed i servizi venduti dalla Società; </p></li>

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p><span className="font-bold">Utente: </span> qualunque soggetto che accede e utilizza il Sito creando un profilo personale</p></li>

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p><span className="font-bold">Contenuti: </span> qualsiasi elemento testuale o multimediale presente sul Sito, ad esempio: annunci, inserzioni, recensioni, immagini, etc.</p></li>

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p><span className="font-bold">Condizioni:  </span> il presente documento che disciplina i rapporti tra la Società e gli Utenti in ordine all’utilizzazione del Sito ed offre alcune indicazioni in ordine all’acquisto dei Prodotti della Società tramite lo stesso. </p></li>

          </ul>


        </ol>

      </div>

      <br/>

      <div className="flex flex-col">

        <ol className="list-decimal">

          <div className="flex flex-row ml-4 mb-2">

            <p className="mr-2 font-bold">2.</p><span className="font-bold">Informazioni dettagliate sull’offerta del Sito</span>

          </div>

          <p className="ml-10 mb-2"> Il Sito  </p>

          <ul className="flex flex-col list-disc gap-y-4">

<li className="flex flex-row ml-10"><p className="mr-4 font-bold">-</p><p>fornisce informazioni circa le funzionalità del Software denominato “Restaurants Club” e dei relativi servizi</p></li>

<li className="flex flex-row ml-10"><p className="mr-4 font-bold">-</p><p>attraverso una procedura guidata automatizzata, consente la stipulazione del contratto di Licenza d’uso del Software “Restaurants Club” e dei relativi servizi e la loro attivazione (clicca qui per maggiori informazioni)</p></li>

<li className="flex flex-row ml-10"><p className="mr-4 font-bold">-</p><p>consente all’utente di effettuare i pagamenti contrattualmente previsti</p></li>

<li className="flex flex-row ml-10"><p className="mr-4 font-bold">-</p>consente alla Società di raccogliere i dati degli Utenti<p></p></li>

<li className="flex flex-row ml-10"><p className="mr-4 font-bold">-</p><p>può fornire informazioni circa altri prodotti e servizi della Società, consentire la stipulazione dei relativi contratti e la loro attivazione.</p></li>



</ul>

        </ol>

      </div>

      <br/>

      <div className="flex flex-col">

        <ol className="list-decimal">

          <div className="flex flex-row ml-4 mb-2">

            <p className="mr-2 font-bold">3.</p><span className="font-bold">Termini di utilizzo</span>

          </div>

          <p className="ml-10 mb-2">Accedendo al Sito e creando volontariamente un profilo, tutti gli Utenti riconoscono e accettano espressamente le presenti Condizioni nonché la Privacy Policy e la Cookie Policy.
Le presenti Condizioni possono essere modificate in qualsiasi momento dalla Società.
Prima di utilizzare il Sito, l’Utente è tenuto a leggere attentamente le presenti Condizioni e a salvarle o stamparle per future consultazioni.
La Società si riserva il diritto di variare a propria discrezione, in qualunque momento anche successivo alla registrazione dell’Utente, l’interfaccia grafica del Sito, i suoi contenuti e la loro organizzazione, nonché ogni altro aspetto che caratterizza la funzionalità e la gestione del Sito, comunicando all’Utente le relative istruzioni, ove necessario, senza che da ciò possa discendere alcuna pretesa e/o diritto e/o contestazione da parte dell’Utente.
</p>


        </ol>

      </div>

      <br/>

      <div className="flex flex-col">

        <ol className="list-decimal">

          <div className="flex flex-row ml-4 mb-2">

           <p className="mr-2 font-bold">4.</p><span className="font-bold">Accesso e registrazione degli Utenti</span>

          </div>

          <p className="ml-10 mb-4"> Gli Utenti devono soddisfare i seguenti requisiti:</p>

        </ol>

        <ul className="flex flex-col list-disc gap-y-4">

          <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p>Gli Utenti devono avere almeno 18 anni</p></li>
          
          <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p>Gli Utenti devono completare in modo veritiero i campi obbligatori del modulo di registrazione in cui sono richiesti dati personali quali ad esempio il nome, l’indirizzo e-mail, il numero di telefono, l’indirizzo della sede/domicilio.</p></li>
          
          <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p>Gli Utenti devono accettare le presenti Condizioni.</p></li>
          
          <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p>Gli Utenti devono accettare la Privacy Policy (<a href="/cookiepolicy" className="text-[#928471] underline">www.restaurants.club/privacypolicy</a>)</p></li>
          
          <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p>Gli Utenti devono accettare la Cookie Policy (inserire collegamento)</p></li>
          



          <p className="ml-10 my-2">L’Utente garantisce che tutte le informazioni relative alla sua identità indicate nei moduli di registrazione al Sito sono vere, accurate e complete. Inoltre, l’Utente si impegna a mantenere i propri dati aggiornati.
Se un Utente fornisce informazioni false, imprecise o incomplete o se la Società ritiene che vi siano motivi fondati per dubitare della veridicità, dell’accuratezza o della completezza di tali informazioni, in qualsiasi momento può negare all’Utente l’accesso al Sito ed a qualsiasi suo contenuto e/o servizio.
Al momento della registrazione sul Sito, l’Utente deve scegliere uno username e una password; sia lo username che la password sono strettamente riservati, personali e non trasferibili. 
Per aumentare la sicurezza del profilo, la Società consiglia all’Utente di non utilizzare le stesse credenziali di login utilizzate su altri siti. Resta fermo che se un Utente utilizza le stesse credenziali di login di altri siti, la Società non sarà in grado di garantire la sicurezza dell’account o che l’Utente sia la sola persona ad effettuare l’accesso al suo profilo.
L’Utente si impegna a non divulgare i dettagli del suo account e a non consentire l’accesso al proprio profilo a terze persone. L’Utente è l’unico responsabile in ordine a qualsiasi utilizzo di tali dati e ad ogni attività effettuata sul sito tramite il proprio profilo, quand’anche le sue credenziali vengano utilizzate da terze persone.
La Società non garantisce l’identità degli Utenti registrati e non può quindi essere ritenuta responsabile per l’utilizzo del profilo da parte di terzi non autorizzati. 
L’Utente si impegna a informare immediatamente la Società nel caso in cui le sue credenziali vengano rubate, divulgate o perse.
</p>


        </ul>

      </div>

      <br/>

      <div className="flex flex-col self-start">

<ol className="list-decimal">

  <div className="flex flex-row ml-4 mb-2">

    <p className="mr-2 font-bold">4.1</p><span className="font-bold">Luogo</span>

  </div>

  <p className="ml-10 mb-2">Per completare la registrazione sul Sito, l’Utente deve fornire alcuni dati personali quali (a mero titolo esemplificativo e non esaustivo) nome, cognome, indirizzo e-mail, numero di telefono, indirizzo della sede o del domicilio e dati di pagamento. 
Una volta completato il processo di registrazione, l’Utente può accedere al proprio profilo e può apportarvi modifiche tra quelle consentite.
La Società non tratta direttamente i dati di pagamento dell’Utente, che vengono elaborati, trattati e salvati da un fornitore esterno di servizi di pagamento come descritto nelle presente Condizioni
</p>

</ol>

</div>

<br/>

<div className="flex flex-col self-start">

<ol className="list-decimal">

  <div className="flex flex-row ml-4 mb-2">

    <p className="mr-2 font-bold">4.2</p><span className="font-bold">Furto o appropriazione di carte di credito/debito</span>

  </div>

  <p className="ml-10 mb-2">Dato che la Società non può garantire l’identità dell’Utente registrato, questi ha l’obbligo di informare la Società nel caso in cui la propria carta di credito/debito associata al profilo sia stata rubata e/o sia stata fraudolentemente utilizzata da terze persone non autorizzate. 
La Società non è in alcun modo responsabile per qualsiasi uso fraudolento della stessa da parte di terzi sul profilo dell’Utente. 
La Società, ove necessario, si impegna ad assistere l’Utente ed a collaborare con le autorità competenti per fornire prove circa l’uso non autorizzato della carta di credito o debito. 

</p>

</ol>

</div>
   <br/>

      <div className="flex flex-col self-start">

        <ol className="list-decimal">

          <div className="flex flex-row ml-4 mb-2">

            <p className="mr-2 font-bold">5.</p><span className="font-bold">Cancellazione dell’account e interruzione dell’utilizzo del Sito</span>

          </div>

          <p className="ml-10 mb-2">L’Utente registrato può in ogni momento disattivare il proprio profilo o richiederne la cancellazione attraverso l’interfaccia del Sito stesso oppure inviando una comunicazione scritta all’indirizzo e-mail hello@restaurantsclub.com.
La Società si riserva il diritto di sospendere e/o chiudere il profilo dell’Utente in ogni momento e senza alcun preavviso nel caso in cui riscontri la violazione delle presenti Condizioni, delle disposizioni di legge e del proprio Codice etico.
</p>

        </ol>

      </div>

      <br/>

      <div className="flex flex-col">

        <ol className="list-decimal">

          <div className="flex flex-row ml-4 mb-2">

            <p className="mr-2 font-bold">6.</p><span className="font-bold">Acquisti tramite il Sito</span>

          </div>

          <p className="ml-10 mb-2">

          Tutti i prodotti e i servizi offerti sono descritti in maniera più dettagliata possibile nelle relative sezioni del Sito; ciononostante potrebbero evidenziarsi alcuni errori, imprecisioni o piccole differenze tra quanto pubblicato sul Sito ed i prodotti e servizi rilasciati dalla Società. Rimane fermo che le condizioni di acquisto dei prodotti e dei servizi e le relative descrizioni sono indicate nei contratti eventualmente sottoscritti tra le parti cui pertanto si rimanda per la descrizione completa di tali prodotti e servizi.
Le immagini dei prodotti e dei servizi caricate sul Sito sono solo esemplificative.
L’acquisto di prodotti e servizi tramite il sito è consentito solo a condizione che l’Utente soddisfi i requisiti di cui all’art. 4.
Gli acquisti effettuati da Utenti persone giuridiche devono essere effettuati esclusivamente dal legale rappresentante o da soggetto cui sia stata conferita la rappresentanza dell’ente.
Le condizioni di acquisto di prodotti e servizi della Società sono regolate esclusivamente secondo quanto previsto dai relativi contratti.


          </p>

        </ol>


      </div>

      <br/>

      
      <div className="flex flex-col">

        <ol className="list-decimal">

          <div className="flex flex-row ml-4 mb-2">

            <p className="mr-2 font-bold">6.1 </p><span className="font-bold">Prezzi e pagamenti</span>

          </div>

          <p className="ml-10 mb-2">

          Per tutti i prodotti e servizi offerti dalla Società vengono indicati il prezzo e l’IVA, se dovuta. 
Qualora la natura del prodotto o del servizio comporti l’impossibilità di definire anticipatamente il relativo prezzo, la Società si impegna ad indicare le modalità di calcolo del prezzo.
Per ogni prodotto e servizio sono inoltre indicate tutte le eventuali imposte, spese aggiuntive che possono variare in relazione alla modalità di pagamento utilizzata. Qualora tali voci di spesa non possano ragionevolmente essere calcolate anticipatamente, la Società si impegna a fornire quantomeno l’indicazione del tipo di spesa che deve essere addebitata all’Utente.
La Società si riserva la facoltà di modificare, in qualsiasi momento, il prezzo dei prodotti e dei servizi offerti, fermo restando che le condizioni di acquisto tra la Società e l’Utente saranno sempre regolate dai contratti tra esse sottoscritti.
Per l’elaborazione dei pagamenti, il Sito si avvale di fornitori esterni per l’elaborazione dei pagamenti e pertanto non tratta direttamente i dati di pagamento. Resta inteso che qualora tali fornitori dovessero negare l’autorizzazione al pagamento, la Società non potrà fornire i prodotti e servizi richiesti e non potrà essere considerata in alcun modo responsabile in ordine alla mancata fornitura.
.


          </p>

        </ol>


      </div>

      <br/>

      <div className="flex flex-col">

<ol className="list-decimal">

  <div className="flex flex-row ml-4 mb-2">

    <p className="mr-2 font-bold">6.2 </p><span className="font-bold">Fatturazione</span>

  </div>

  <p className="ml-10 mb-2">

  All’Utente che desideri ricevere la fattura vengono richiesti i dati per la fatturazione. 
Per l’emissione della fattura fanno fede le informazioni fornite dall’Utente in ordine alla cui veridicità l’Utente si assume la piena responsabilità manlevando la Società da qualsiasi responsabilità.



  </p>

</ol>


</div>

<br/>

      <div className="flex flex-col">

        <ol className="list-decimal">

          <div className="flex flex-row ml-4 mb-2">

           <p className="mr-2 font-bold">7.</p><span className="font-bold">Diritti di Proprietà Industriale e Intellettuale</span>

          </div>

          <p className="ml-10 mb-2"> Tutti i contenuti del Sito, compresi testi, documenti, marchi, loghi, immagini, grafica, la loro disposizione e i loro adattamenti, sono di proprietà della Società e sono protetti dalla normativa sul diritto d'autore e dalla normativa a tutela dei marchi. 
Il Sito può contenere anche immagini, documenti, loghi e marchi di terze parti che hanno espressamente autorizzato la Società alla pubblicazione. 
Non è consentito copiare, alterare, distribuire, pubblicare o utilizzare i contenuti senza autorizzazione espressa della Società
</p>

        </ol>


      </div>

      <br/>

      <div className="flex flex-col">

        <ol className="list-decimal">

          <div className="flex flex-row ml-4 mb-2">

           <p className="mr-2 font-bold">8.</p><span className="font-bold">Diritti dell’Interessato</span>

          </div>

          <p className="ml-10 mb-2"> All’Utente può essere concessa (a discrezione della Società) la facoltà di caricare contenuti sul Sito: in tali casi è fatto espresso divieto all’Utente di caricare contenuti illeciti (ossia osceni, intimidatori, diffamatori, pornografici, abusivi o a qualsiasi titolo illegali o che violino la privacy, i diritti di proprietà intellettuale e/o industriale della Società e/o di terzi), ingannevoli, o in qualunque modo lesivi nei confronti della Società e/o di terzi o che contengano virus, propaganda politica, sollecitazione commerciale, e-mail di massa o qualunque altra forma di spamming. 
L’Utente si assume la piena responsabilità in ordine ai contenuti caricati e si impegna a tenere manlevata ed indenne la Società da qualsiasi danno, perdita o spesa che terzi dovessero lamentare in ordine a tali contenuti.
La Società non è in grado di assicurare un controllo puntuale sui contenuti pubblicati direttamente dagli Utenti e si riserva in ogni momento il diritto di cancellare, spostare e modificare quei contenuti che, a suo discrezionale giudizio, appaiono illeciti, abusivi, diffamatori, osceni o lesivi del diritto d’autore e dei marchi o che siano in ogni caso inaccettabili, anche alla luce del proprio Codice etico.
Gli Utenti concedono alla Società un diritto d’uso non esclusivo sui contenuti caricati sul Sito, senza limitazioni di aree geografiche. La Società potrà pertanto, direttamente o tramite terzi, utilizzare, modificare, copiare, trasmettere, estrarre, pubblicare, distribuire, eseguire pubblicamente, diffondere, creare opere derivate, ospitare, indicizzare, memorizzare, annotare, codificare, modificare ed adattare in qualsiasi forma, ogni contenuto che dovesse essere caricato dall’Utente, anche per il tramite di terzi.
La Società non può essere ritenuta responsabile nei confronti degli Utenti per la perdita, modifica o distruzione del contenuto da questi caricato sul Sito.
È espressamente vietato, salvo esplicita autorizzazione della Società:
</p>

        </ol>

        <ul className="flex flex-col list-disc">

          <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> l’utilizzo di sistemi automatici di caricamento di annunci</p></li>

          <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> la pubblicazione e/o la gestione seriale di annunci per conto terzi con ogni mezzo o modalità</p></li>

          <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> 	rivendere a terzi i servizi della Società, senza preventivo accordo scritto con la stessa.</p></li>

          

        </ul>

      </div>

      <br/>

      <div className="flex flex-col">

<ol className="list-decimal">

  <div className="flex flex-row ml-4 mb-2">

   <p className="mr-2 font-bold">9.</p><span className="font-bold">Esclusione della garanzia</span>

  </div>

  <p className="ml-10 mb-2"> La Società non fornisce alcuna garanzia in ordine al fatto che il Sito possa soddisfare le esigenze degli Utenti.
La Società, inoltre, non garantisce il perfetto funzionamento del Sito e quindi che questo non avrà mai interruzioni o sarà privo di errori o di virus o bug.
L’accesso al Sito può essere sospeso temporaneamente e senza preavviso in caso di guasto del sistema, manutenzione, riparazioni o per ragioni del tutto estranee alla volontà della Società o per eventi di forza maggiore, senza che da ciò possa sorgere in capo all’Utente alcun diritto e/o pretesa nei confronti della Società.

</p>

</ol>


</div>

<br/>

<div className="flex flex-col">

<ol className="list-decimal">

  <div className="flex flex-row ml-4 mb-2">

   <p className="mr-2 font-bold">10.</p><span className="font-bold">Limitazione della Responsabilità</span>

  </div>

  <p className="ml-10 mb-2"> La Società non può essere in alcun modo ritenuta responsabile verso l’Utente, salvo il caso di dolo o colpa grave, per disservizi o malfunzionamenti connessi all’utilizzo della rete internet.
La Società non può inoltre essere ritenuta responsabile per

</p>

</ol>

<ul className="flex flex-col list-disc">

  <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> eventuali perdite di opportunità commerciale e qualsiasi altra perdita, anche indiretta, eventualmente subita dall’Utente che non siano conseguenza diretta della violazione del contratto in essere tra le parti.</p></li>

  <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> errato o inidoneo utilizzo del Sito da parte degli Utenti o di terzi</p></li>

  <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> l’emissione di documenti o dati fiscali errati a causa di errori nell’indicazione di tali dati da parte dell’Utente, essendo quest’ultimo l’unico responsabile della corretta comunicazione degli stessi.</p></li>

  

</ul>

</div>

<br/>

<div className="flex flex-col">

<ol className="list-decimal">

  <div className="flex flex-row ml-4 mb-2">

   <p className="mr-2 font-bold">11.</p><span className="font-bold">Forza maggiore</span>

  </div>

  <p className="ml-10 mb-2"> La Società non può essere ritenuta responsabile per il mancato o ritardato adempimento delle proprie obbligazioni dovuto a circostanze al di fuori del suo controllo e/o dovute ad eventi di forza maggiore o, comunque, ad eventi imprevisti ed imprevedibili e indipendenti dalla sua volontà.
L’adempimento delle obbligazioni da parte della Società si intende sospeso per tutto il periodo in cui si verificano questi eventi di forza maggiore.


</p>

</ol>


</div>

<br/>

<div className="flex flex-col">

<ol className="list-decimal">

  <div className="flex flex-row ml-4 mb-2">

   <p className="mr-2 font-bold">12.</p><span className="font-bold">Collegamento a siti di terzi</span>

  </div>

  <p className="ml-10 mb-2"> Il Sito potrebbe contenere collegamenti a siti/applicazioni di terzi.
La Società non esercita alcun controllo su di essi e, pertanto, non può essere in alcun modo ritenuta responsabile in ordine ad eventuali danni riportati dagli Utenti a causa del collegamento con tali siti/applicazioni.



</p>

</ol>


</div>

<br/>

<div className="flex flex-col">

<ol className="list-decimal">

  <div className="flex flex-row ml-4 mb-2">

   <p className="mr-2 font-bold">13.</p><span className="font-bold">Privacy</span>

  </div>

  <p className="ml-10 mb-2"> La tutela e il trattamento dei dati personali degli Utenti avviene in conformità alla normativa vigente con le modalità indicate nella Privacy Policy che può essere consultata alla pagina <a href="/cookiepolicy" className="text-[#928471] underline">www.restaurants.club/privacypolicy</a>



</p>

</ol>


</div>

<br/>

<div className="flex flex-col">

<ol className="list-decimal">

  <div className="flex flex-row ml-4 mb-2">

   <p className="mr-2 font-bold">14.</p><span className="font-bold">Legge applicabile e foro competente</span>

  </div>

  <p className="ml-10 mb-2"> Le presenti Condizioni sono regolate dalla legge italiana.
Per ogni controversia relativa all'applicazione, esecuzione e interpretazione delle presenti Condizioni è competente il Foro di Pisa.</p>




</ol>


</div>

<br/>

      <p className="flex self-start mb-8"> Ultimo aggiornamento: 13/05/2022 </p>

    </div>

    </>

  )
}

export default Privacypolicy;
