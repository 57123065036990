import React from "react";
import Hero from "../../Components/Home/Hero/Hero";
import Map from "../../Components/Home/Map/Map";
import Reviews from "../../Components/Home/Reviews/Reviews";
import DailyOfferCarousel from "../../Components/Home/DailyOfferCarousel/DailyOfferCarousel";
import OpeningHours from "../../Components/Home/OpeningHours/OpeningHours";
import RestaurantBio from "../../Components/Home/RestaurantBio/RestaurantBio";
import DailyMenuBlock from "../../Components/Home/DailyMenuBlock/DailyMenuBlock";
import InstagramBlock from "../../Components//Home/InstagramBlock/InstagramBlock";
import CallToActions from "../../Components/Home/CallToActions/CallToActions";
import BannerBlock01 from "../../Components/Home/BannerBlock-01/BannerBlock";
import BannerBlock02 from "../../Components/Home/BannerBlock-02/BannerBlock";
import ScrollToTop from "../../Reusable/ScrollToTop";
import "./HomePage.css";
import { MyContext } from "../../App";
import ChangeBGModal from "../../Components/Home/ChangeBGModal";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import useWindowSize from "../../Components/Reusable/WindowSize";

const HomePage = () => {
  const {
    themeID,
    PatchNewInfo,
    SetRestaurantInfo,
    RestaurantInfo,
    CustomizingTheWebsite,
    Sections,
  } = React.useContext(MyContext);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [WhichPart, SetPart] = React.useState("hero");
  const [open, setOpen] = React.useState(false);
  const handleOpen = (section) => {
    setOpen(true);
    SetPart(section);
  };

  const [restrictPatch, SetPatch] = React.useState(null);

  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    restrictPatch && PatchNewInfo();

    const handleStorageChange = (event) => {
      if (event.key === 'changedCover' && event.newValue === 'true') {
        window.localStorage.setItem('changedCover', 'false');
      }
    };
    window.addEventListener('storage', handleStorageChange);

    const handleStorageChangeChangedImage = (event) => {
      console.log("handling storage change")
      if (event.key === "changedImage") {
        SetRestaurantInfo((prevRestaurantInfo) => ({
          ...prevRestaurantInfo,
          theme_photos: {
            ...prevRestaurantInfo.theme_photos,
            home: {
              ...prevRestaurantInfo.theme_photos.home,
              hero: {
                ...prevRestaurantInfo.theme_photos.home.hero,
                hero_img: event.newValue,
              },
            },
          },
        }));
      }
    };
    window.addEventListener("storage", handleStorageChangeChangedImage);
    return () => {
      window.localStorage.setItem('changedCover', 'false');
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener("storage", handleStorageChangeChangedImage);
    };

  }, [restrictPatch]);

  function handleChange() {
    SetPatch(Math.random());
  }

  const Components = [
    // 0
    <Hero theme={themeID} handleOpen={handleOpen} />,
    // 1
    // please keep it hide for now
    <DailyOfferCarousel theme={themeID} />,
    // 2
    <RestaurantBio theme={themeID} handleOpen={handleOpen} />,
    // 3
    <CallToActions theme={themeID} />,
    // 4
    <InstagramBlock theme={themeID} />,
    // 5
    <BannerBlock01 theme={themeID} />,
    // 6
    <BannerBlock02 theme={themeID} />,
    // 7
    <Map theme={themeID} />,
    // 8
    <Reviews theme={themeID} />,
    // 9
    <OpeningHours theme={themeID} />,
    //10
    <DailyMenuBlock theme={themeID} />,
  ];
  {
    /*
  
  const Italian01 = [0, 1, 2, 4, 7, 8];
  const Italian02 = [0, 2, 1, 4, 8];
  const Arabian01 = [0, 2, 1, 4, 7, 8];
  const Pizza01 = [0, 1, 2, 5, 4, 7, 8];
  const Pizza02 = [0, 2, 1, 4, 7, 8];
  const Chinese01 = [0, 1, 2, 7, 8, 4];
  const Chinese02 = [0, 1, 5, 2, 7, 8];
  const Sweet01 = [0, 2, 7, 6, 4];
  const Pub01 = [0, 2, 4, 1, 7, 5, 8];
  const Coffee01 = [0, 1, 5, 2, 4];
  const American01 = [0, 2, 1, 6, 7, 8];
  const Fish02 = [0, 5, 1, 2, 6, 8, 9];

  */
  }

  const Italian01 = [0, 10, 2, 4, 7, 8];
  const Italian02 = [0, 10, 2, 4, 7, 8];
  const Italian03 = [0, 10, 2, 4, 7, 8];
  const Arabian01 = [0, 10, 2, 4, 7, 8];
  const Pizza01 = [0, 10, 2, 4, 7, 8];
  const Pizza02 = [0, 10, 2, 4, 7, 8];
  const Chinese01 = [0, 10, 2, 4, 7, 8];
  const Chinese02 = [0, 10, 2, 4, 7, 8];
  const Sweet01 = [0, 10, 2, 4, 7, 8];
  const Pub01 = [0, 10, 2, 4, 7, 8];
  const Coffee01 = [0, 10, 2, 4, 7, 8];
  const American01 = [0, 10, 2, 4, 7, 8];
  const Fish02 = [0, 10, 2, 4, 7, 8];

  function theDesiredTheme() {
    if (themeID === "Italian-01") return Italian01;
    if (themeID === "Italian-02") return Italian02;
    if (themeID === "Italian-03") return Italian03;
    if (themeID === "Arabian-01") return Arabian01;
    if (themeID === "Pizza-01") return Pizza01;
    if (themeID === "Pizza-02") return Pizza02;
    if (themeID === "Chinese-01") return Chinese01;
    if (themeID === "Chinese-02") return Chinese02;
    if (themeID === "Sweet-01") return Sweet01;
    if (themeID === "Pub-01") return Pub01;
    if (themeID === "Coffee-01") return Coffee01;
    if (themeID === "American-01") return American01;
    if (themeID === "Fish-02") return Fish02;
  }

  const isBioThere = theDesiredTheme()?.filter((item) => item === 2)[0];
  const ischefSelectionThere = theDesiredTheme()?.filter(
    (item) => item === 1
  )[0];
  const isInstagramThere = theDesiredTheme()?.filter((item) => item === 4)[0];
  const isMenuBanner1There = theDesiredTheme()?.filter((item) => item === 5)[0];
  const isMenuBanner2There = theDesiredTheme()?.filter((item) => item === 6)[0];
  const isMapThere = theDesiredTheme()?.filter((item) => item === 7)[0];
  const isReviewsThere = theDesiredTheme()?.filter((item) => item === 8)[0];
  const screenWidth = useWindowSize();
  return (
    <div>
      <ScrollToTop />
      {CustomizingTheWebsite && screenWidth > 830 && (
        <div className=" absolute top-10 left-10">
          <FormControl color="success" sx={{ width: 300 }}>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              MenuProps={MenuProps}
              value={["Sections"]}
              renderValue={(selected) => {
                return <em>Sezioni del Sito</em>;
              }}
              color="success"
              displayEmpty
            >
              <MenuItem>
                <Checkbox
                  color="success"
                  onChange={() => {
                    SetRestaurantInfo({
                      ...RestaurantInfo,
                      theme_photos: {
                        ...RestaurantInfo.theme_photos,
                        home: {
                          ...RestaurantInfo.theme_photos.home,

                          hero: {
                            ...RestaurantInfo.theme_photos.home.hero,
                            isVisible:
                              !RestaurantInfo?.theme_photos?.home?.hero
                                ?.isVisible,
                          },
                        },
                      },
                    });
                    handleChange();
                  }}
                  checked={RestaurantInfo?.theme_photos?.home?.hero?.isVisible}
                />
                <ListItemText primary={"Immagine Principale Home"} />
              </MenuItem>
              {/* <MenuItem >
                            <Checkbox color="success" onChange={() => { }} checked={true} />
                            <ListItemText primary={"Consigliati dallo Chef"} />
                        </MenuItem> */}

              {isBioThere && (
                <MenuItem>
                  <Checkbox
                    color="success"
                    onChange={() => {
                      SetRestaurantInfo({
                        ...RestaurantInfo,
                        theme_photos: {
                          ...RestaurantInfo.theme_photos,
                          home: {
                            ...RestaurantInfo.theme_photos.home,

                            bio: {
                              ...RestaurantInfo.theme_photos.home.bio,
                              isVisible:
                                !RestaurantInfo?.theme_photos?.home?.bio
                                  ?.isVisible,
                            },
                          },
                        },
                      });
                      handleChange();
                    }}
                    checked={RestaurantInfo?.theme_photos?.home?.bio?.isVisible}
                  />
                  <ListItemText primary={"Immagine Sezione Descrizione"} />
                </MenuItem>
              )}

              {/* <MenuItem >
                            <Checkbox color="success" onChange={() => { }} checked={true} />
                            <ListItemText primary={"Recensioni"} />
                        </MenuItem>
                        <MenuItem >
                            <Checkbox color="success" onChange={() => { }} checked={true} />
                            <ListItemText primary={"Galleria Foto"} />
                        </MenuItem>
                        <MenuItem >
                            <Checkbox color="success" onChange={() => { }} checked={true} />
                            <ListItemText primary={"Mappa ed Orari"} />
                        </MenuItem> */}

              {isReviewsThere && (
                <MenuItem>
                  <Checkbox
                    color="success"
                    onChange={() => {
                      SetRestaurantInfo({
                        ...RestaurantInfo,
                        theme_photos: {
                          ...RestaurantInfo.theme_photos,
                          home: {
                            ...RestaurantInfo.theme_photos.home,

                            reviews: {
                              ...RestaurantInfo.theme_photos.home.reviews,
                              isVisible:
                                !RestaurantInfo?.theme_photos?.home?.reviews
                                  ?.isVisible,
                            },
                          },
                        },
                      });
                      handleChange();
                    }}
                    checked={
                      RestaurantInfo?.theme_photos?.home?.reviews?.isVisible
                    }
                  />
                  <ListItemText primary={"Recensioni"} />
                </MenuItem>
              )}

              {isMenuBanner2There && (
                <MenuItem>
                  <Checkbox
                    color="success"
                    onChange={() => {
                      SetRestaurantInfo({
                        ...RestaurantInfo,
                        theme_photos: {
                          ...RestaurantInfo.theme_photos,
                          home: {
                            ...RestaurantInfo.theme_photos.home,

                            menu_banner_2: {
                              ...RestaurantInfo.theme_photos.home.menu_banner_2,
                              isVisible:
                                !RestaurantInfo?.theme_photos?.home
                                  ?.menu_banner_2?.isVisible,
                            },
                          },
                        },
                      });
                      handleChange();
                    }}
                    checked={
                      RestaurantInfo?.theme_photos?.home?.menu_banner_2
                        ?.isVisible
                    }
                  />
                  <ListItemText primary={"Banner 2"} />
                </MenuItem>
              )}

              {isMenuBanner1There && (
                <MenuItem>
                  <Checkbox
                    color="success"
                    onChange={() => {
                      SetRestaurantInfo({
                        ...RestaurantInfo,
                        theme_photos: {
                          ...RestaurantInfo.theme_photos,
                          home: {
                            ...RestaurantInfo.theme_photos.home,

                            menu_banner_1: {
                              ...RestaurantInfo.theme_photos.home.menu_banner_1,
                              isVisible:
                                !RestaurantInfo?.theme_photos?.home
                                  ?.menu_banner_1?.isVisible,
                            },
                          },
                        },
                      });
                      handleChange();
                    }}
                    checked={
                      RestaurantInfo?.theme_photos?.home?.menu_banner_1
                        ?.isVisible
                    }
                  />
                  <ListItemText primary={"Banner 1"} />
                </MenuItem>
              )}

              {ischefSelectionThere && (
                <MenuItem>
                  <Checkbox
                    color="success"
                    onChange={() => {
                      SetRestaurantInfo({
                        ...RestaurantInfo,
                        theme_photos: {
                          ...RestaurantInfo.theme_photos,
                          home: {
                            ...RestaurantInfo.theme_photos.home,

                            chefSelection: {
                              ...RestaurantInfo.theme_photos.home.chefSelection,
                              isVisible:
                                !RestaurantInfo?.theme_photos?.home
                                  ?.chefSelection?.isVisible,
                            },
                          },
                        },
                      });
                      handleChange();
                    }}
                    checked={
                      RestaurantInfo?.theme_photos?.home?.chefSelection
                        ?.isVisible
                    }
                  />
                  <ListItemText primary={"Suggeriti dello Chef"} />
                </MenuItem>
              )}

              {isMapThere && (
                <MenuItem>
                  <Checkbox
                    color="success"
                    onChange={() => {
                      SetRestaurantInfo({
                        ...RestaurantInfo,
                        theme_photos: {
                          ...RestaurantInfo.theme_photos,
                          home: {
                            ...RestaurantInfo.theme_photos.home,

                            map: {
                              ...RestaurantInfo.theme_photos.home.map,
                              isVisible:
                                !RestaurantInfo?.theme_photos?.home?.map
                                  ?.isVisible,
                            },
                          },
                        },
                      });
                      handleChange();
                    }}
                    checked={RestaurantInfo?.theme_photos?.home?.map?.isVisible}
                  />
                  <ListItemText primary={"Mappa Google"} />
                </MenuItem>
              )}

              {isInstagramThere && (
                <MenuItem>
                  <Checkbox
                    color="success"
                    onChange={() => {
                      SetRestaurantInfo({
                        ...RestaurantInfo,
                        theme_photos: {
                          ...RestaurantInfo.theme_photos,
                          home: {
                            ...RestaurantInfo.theme_photos.home,

                            instagram: {
                              ...RestaurantInfo.theme_photos.home.instagram,
                              isVisible:
                                !RestaurantInfo?.theme_photos?.home?.instagram
                                  ?.isVisible,
                            },
                          },
                        },
                      });
                      handleChange();
                    }}
                    checked={
                      RestaurantInfo?.theme_photos?.home?.instagram?.isVisible
                    }
                  />
                  <ListItemText primary={"instagram"} />
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      )}
      {theDesiredTheme()?.map((item, index) => (
        <React.Fragment key={index}>{Components[item]}</React.Fragment>
      ))}

      <ChangeBGModal
        WhichPart={WhichPart}
        handleClose={handleClose}
        open={open}
        PatchNewInfo={PatchNewInfo}
        SetRestaurantInfo={SetRestaurantInfo}
        RestaurantInfo={RestaurantInfo}
      />
    </div>
  );
};

export default HomePage;
