import axios from "axios";
import { getSiteId } from '../../../Util/Data/userData';
import { baseUrl } from "../../Api/Urls";
import { toast } from "react-toastify";

const endpoint = `https://${getSiteId()}.${process.env.REACT_APP_THEMES_PREFIX}${baseUrl()}${process.env.REACT_APP_THEMES_API_PATH}/auth/`;




export async function loginUser(path, loginPayload, setError, dontReload) {
    try {
        const { data } = await axios.post(`${endpoint}${path}`, loginPayload);
        localStorage.setItem('currentCustomer', JSON.stringify({ token: data.token, ...data.data, id: data.data._id }));
        !dontReload && window.location.reload()
        return data
    } catch (error) {
        console.log({ ...error })
        if (error?.response?.data?.message) setError(error?.response?.data?.message)
        else if (error?.response?.data?.errors) setError(error?.response?.data?.errors)
        return error?.response?.data?.errors

    }

}

export async function RegisterUser(path, loginPayload, setError, navigate) {
    try {
        const { data } = await axios.post(`${endpoint}${path}`, loginPayload);
        navigate("/login")
        return data
    } catch (error) {
        toast.error("Questa email è stata già utilizzata. Inserisci un'altra email        ")
        console.log({ ...error })
        setError(error?.response?.data?.errors)
        return error?.response?.data?.errors
    }
}

export async function logout(dispatch) {
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem('currentCustomer');
}

export async function requestPasswordChangeApi(data) {
    try {
        const res = await axios.post(`${endpoint}request-password-reset`, { data})
        return res
    } catch (error) {
        console.log(error)
    }
}


export async function RecoveryPasswordApi(NewPassword, RecoveryToken) {
    const headersconfig = {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${RecoveryToken}`
        },
    };
    try {
        const res = await axios.post(`${endpoint}password-recovery`, { newPassword: NewPassword }, headersconfig)
        return res
    } catch (error) {
        console.log(error)
    }
}