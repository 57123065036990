import React from 'react';
import CheckoutIT01 from '../../Components/MakeOrder/Checkout'

function CheckoutMain() {

    return (
        <>
            <div className='my-20'>

                <CheckoutIT01 />
            </div>
        </>
    )
}

export default CheckoutMain