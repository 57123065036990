import React, { lazy, Suspense } from "react";
import Skeleton from '@mui/material/Skeleton';
import { MyContext } from '../../../App';
import ChangeBGModal from "../../Home/ChangeBGModal";


const Menuch01 = lazy(() => import("./Chinese-Theme-01/Menu"));
const Menuch02 = lazy(() => import("./Chinese-Theme-02/Menu"));
const Menupz01 = lazy(() => import("./Pizza-Theme-01/Menu"));
const Menupz02 = lazy(() => import("./Pizza-Theme-02/Menu"));
const Menuit01 = lazy(() => import("./Italian-Theme-01/Menu"));
const Menuit02 = lazy(() => import("./Italian-Theme-02/Menu"));
const Menuit03 = lazy(() => import("./Italian-Theme-03/Menu"));
const Menuar01 = lazy(() => import("./Arabian-Theme-01/Menu"));
const MenuPub01 = lazy(() => import("./Pub-Theme-01/Menu"));
const MenuSweet01 = lazy(() => import("./Sweet-Theme-01/Menu"));
const MenuAm01 = lazy(() => import("./American-Theme-01/menu"))
const MenuFi02 = lazy(() => import("./Fish-Theme-02/Menu"));

const Menu = ({ theme }) => {
  const { Catalogs, Loading, PatchNewInfo, SetRestaurantInfo, RestaurantInfo } = React.useContext(MyContext)
  const [WhichPart, SetPart] = React.useState()
  const [open, setOpen] = React.useState(false)
  const handleOpen = (section) => {
    setOpen(true)
    SetPart(section)
  }
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Suspense fallback={<Skeleton variant="rectangular" width={"100%"} className="mt-[5%] " height={600} animation="wave" />}>
        {!Loading && Catalogs.length !== 0 ?
          <>
            {theme === "Chinese-01" ? <Menuch01 handleOpen={handleOpen} /> : null}

            {theme === "Chinese-02" ? <Menuch02 handleOpen={handleOpen} /> : null}

            {theme === "Pizza-01" ? <Menupz01 handleOpen={handleOpen} /> : null}

            {theme === "Pizza-02" ? <Menupz02 handleOpen={handleOpen} /> : null}

            {theme === "Italian-01" ? <Menuit01 handleOpen={handleOpen} /> : null}

            {theme === "Italian-02" ? <Menuit02 handleOpen={handleOpen} /> : null}

            {theme === "Italian-03" ? <Menuit03 handleOpen={handleOpen} /> : null}
            {theme === "Arabian-01" ? <Menuar01 handleOpen={handleOpen} /> : null}
            {theme === "Pub-01" ? <MenuPub01 handleOpen={handleOpen} /> : null}
            {theme === "Sweet-01" ? <MenuSweet01 handleOpen={handleOpen} /> : null}
            {theme === "American-01" ? <MenuAm01 handleOpen={handleOpen} /> : null}
            {theme === "Fish-02" ? <MenuFi02 handleOpen={handleOpen} /> : null}

          </>
          :
          !Loading && Catalogs.length === 0 && <h1 className="text-center my-24">Menu in aggiornamento!</h1>
        }
        {Loading && <Skeleton variant="rectangular" width={"100%"} className="mt-[5%] " height={600} animation="wave" />}
      </Suspense>
      <ChangeBGModal
        WhichPart={WhichPart}
        Catalogs={Catalogs}
        handleClose={handleClose}
        open={open}
        PatchNewInfo={PatchNewInfo}
        SetRestaurantInfo={SetRestaurantInfo}
        RestaurantInfo={RestaurantInfo}
      />
    </div>
  );
};

export default Menu;
