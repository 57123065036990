import React from 'react';
import OrderCard from './OrderCard';
import { getOrdersForCustomer } from "../../../Util/Api/CustomerAPI";
import Skeleton from '@mui/material/Skeleton';
import ReactPaginate from 'react-paginate';

function OrderHistory({ userData }) {



    const [isLoading, SetLoading] = React.useState(false)

    const [pendingOrders, setPending] = React.useState([])
    const [IncomingOrders, setIncoming] = React.useState([])
    const [DeliveredOrders, setDelieverd] = React.useState([])
    async function getCustomerOrders() {
        SetLoading(true)
        try {
            const res = await getOrdersForCustomer(userData.email)
            setPending(res.data.data.filter((order) => order.deliveryStatus === "pending" || order.deliveryStatus === "confirmed"))
            setIncoming(res.data.data.filter((order) => order.deliveryStatus === "incoming"))
            setDelieverd(res.data.data.filter((order) => order.deliveryStatus === "delivered"))
        } catch (error) {
            console.log(error)
        }
        SetLoading(false)
    }

    React.useEffect(() => {
        getCustomerOrders()
    }, [])


    const [isActive, SetActive] = React.useState(1)
    const [forcePage, setPage] = React.useState(0)

    React.useEffect(() => {
        setPage(0)

    }, [isActive])

    const [itemOffset, setItemOffset] = React.useState(0);
    const endOffset = itemOffset + 5;
    const pageCount = Math.ceil(pendingOrders?.length / 5);
    const [pendingOrdersPaginated, SetPaginatedOrders] = React.useState([])
    const handlePageClick = (event) => {
        const newOffset = (event.selected * 5) % pendingOrders.length;
        setItemOffset(newOffset);
    };
    React.useEffect(() => {
        SetPaginatedOrders(pendingOrders?.slice(itemOffset, endOffset));
    }, [itemOffset, pendingOrders])


    const [itemOffset2, setItemOffset2] = React.useState(0);
    const endOffset2 = itemOffset2 + 5;
    const pageCount2 = Math.ceil(IncomingOrders?.length / 5);
    const [IncomingOrdersPaginated, SetIncomingPaginatedOrders] = React.useState([])
    const handlePageClick2 = (event) => {
        const newOffset = (event.selected * 5) % IncomingOrders.length;
        setItemOffset2(newOffset);
    };
    React.useEffect(() => {
        SetIncomingPaginatedOrders(IncomingOrders?.slice(itemOffset2, endOffset2));
    }, [itemOffset2, IncomingOrders])


    const [itemOffset3, setItemOffset3] = React.useState(0);
    const endOffset3 = itemOffset3 + 5;
    const pageCount3 = Math.ceil(DeliveredOrders?.length / 5);
    const [deliveredOrdersPaginated, SetDeliveredPaginatedOrders] = React.useState([])
    const handlePageClick3 = (event) => {
        const newOffset = (event.selected * 5) % DeliveredOrders.length;
        setItemOffset3(newOffset);
    };
    React.useEffect(() => {
        SetDeliveredPaginatedOrders(DeliveredOrders?.slice(itemOffset3, endOffset3));
    }, [itemOffset3, DeliveredOrders])
    return (

        <div className='flex flex-col h-full overflow-y-scroll pb-12 gap-y-5 pl-[2%] sm:pl-0 xsm:pl-0 md:pl-0' >

            <div className="flex flex-row w-1/2 mx-auto sm:w-full xsm:w-full md:w-full " >
                <button
                    onClick={() => {
                        SetActive(1)
                        setPage(null)
                        SetPaginatedOrders(pendingOrders?.slice(0, 5));
                        setItemOffset(0)
                    }}
                    className={`w-1/3 py-2  ${isActive === 1 ? "border-primaryColor text-primaryColor font-bold" : " font-normal border-gray-200 text-gray-400"} border-b-4 sm:text-sm xsm:text-sm md:text-sm`}>
                    Nuovi Ordini
                </button>
                <button
                    onClick={() => {
                        SetActive(2)
                        setPage(null)
                        SetPaginatedOrders(IncomingOrders?.slice(0, 5));
                        setItemOffset3(0)
                    }}
                    className={`w-1/3 py-2  ${isActive === 2 ? "border-primaryColor text-primaryColor font-bold" : " font-normal border-gray-200 text-gray-400"} border-b-4 sm:text-sm xsm:text-sm md:text-sm`}>
                    Ordini in arrivo
                </button>
                <button
                    onClick={() => {
                        SetActive(3)
                        setPage(null)
                        SetPaginatedOrders(DeliveredOrders?.slice(0, 5));
                        setItemOffset3(0)
                    }}
                    className={`w-1/3 py-2  ${isActive === 3 ? "border-primaryColor text-primaryColor font-bold" : " font-normal border-gray-200 text-gray-400"} border-b-4 sm:text-sm xsm:text-sm md:text-sm`}>
                    Storico Ordini
                </button>

            </div>


            {isLoading &&
                <div className='flex flex-col gap-y-1'>
                    <Skeleton width="20%" animation="wave" variant="text" sx={{ fontSize: '1.5rem', marginBottom: 1 }} />
                    <Skeleton width="25%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton width="25%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton width="25%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton width="25%" animation="wave" variant="text" sx={{ fontSize: '1rem', marginTop: 5 }} />
                    <Skeleton width="25%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton width="25%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                </div>
            }
            {pendingOrders.length > 0 && isActive === 1 &&
                <div className='flex flex-col gap-y-3 sm:items-center xsm:items-center md:items-center'>
                    {pendingOrdersPaginated?.map(order => {
                        return (
                            <OrderCard order={order} />
                        )
                    }
                    )}
                </div>
            }

            {IncomingOrders.length > 0 && isActive === 2 &&
                <div className='flex flex-col gap-y-3 sm:items-center xsm:items-center md:items-center'>
                    {IncomingOrdersPaginated?.map(order => {
                        return (
                            <OrderCard order={order} />
                        )
                    }
                    )}
                </div>
            }
            {DeliveredOrders.length > 0 && isActive === 3 &&
                <div className='flex flex-col gap-y-3 sm:items-center xsm:items-center md:items-center'>
                    {deliveredOrdersPaginated?.map(order => {
                        return (
                            <OrderCard order={order} />
                        )
                    }
                    )}
                </div>
            }

            {
                isActive === 1 && pendingOrders.length > 5 &&
                <div className="mt-auto mx-auto">
                    <ReactPaginate
                        breakLabel="..."
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={window.innerWidth < 700 ? 2 : 5}
                        pageCount={pageCount}
                        forcePage={forcePage}
                        nextLabel="❯"
                        nextClassName="text-primaryColor text-2xl"
                        pageClassName="bg-white  rounded-xl text-center border font-semibold w-10 h-10 flex items-center justify-center hover:text-white   text-primaryColorActive border-primaryColor border-2 border-primaryColor hover:bg-primaryColor hover:border-primaryColorHover active:shadow-innerbig active:bg-primaryColorActive active:border-primaryColorActive"
                        pageLinkClassName="w-full h-full items-center justify-center flex"
                        previousLabel="❮"
                        previousClassName="text-primaryColor text-2xl"
                        activeClassName=" bg-primaryColorActive text-center justify-center rounded-xl border border-primaryColorActive w-10 h-10 text-white forceWhiteText hover:bg-primaryColorHover hover:border-primaryColorHover active:shadow-innerbig active:bg-primaryColorActive active:border-primaryColorActive"
                        containerClassName="flex flex-row gap-x-4 xsm:gap-x-1 items-center  mx-auto  mb-12"
                        renderOnZeroPageCount={false}
                        marginPagesDisplayed={1}
                    />
                </div>
            }
            {
                isActive === 2 && IncomingOrders.length > 5 &&
                <div className="mt-auto mx-auto">
                    <ReactPaginate
                        breakLabel="..."
                        onPageChange={handlePageClick2}
                        forcePage={forcePage}
                        pageRangeDisplayed={window.innerWidth < 700 ? 2 : 5}
                        pageCount={pageCount2}
                        nextLabel="❯"
                        nextClassName="text-primaryColor text-2xl"
                        pageClassName="bg-white  rounded-xl text-center border font-semibold w-10 h-10 flex items-center justify-center hover:text-white   text-primaryColorActive border-primaryColor border-2 border-primaryColor hover:bg-primaryColor hover:border-primaryColorHover active:shadow-innerbig active:bg-primaryColorActive active:border-primaryColorActive"
                        pageLinkClassName="w-full h-full items-center justify-center flex"
                        previousLabel="❮"
                        previousClassName="text-primaryColor text-2xl"
                        activeClassName=" bg-primaryColorActive text-center justify-center rounded-xl border border-primaryColorActive w-10 h-10 text-white forceWhiteText hover:bg-primaryColorHover hover:border-primaryColorHover active:shadow-innerbig active:bg-primaryColorActive active:border-primaryColorActive"
                        containerClassName="flex flex-row gap-x-4 xsm:gap-x-1 items-center  mx-auto  mb-12"
                        renderOnZeroPageCount={false}
                        marginPagesDisplayed={1}
                    />
                </div>
            }
            {
                isActive === 3 && DeliveredOrders.length > 5 &&
                <div className="mt-auto mx-auto">
                    <ReactPaginate
                        breakLabel="..."
                        onPageChange={handlePageClick3}
                        forcePage={forcePage}
                        pageRangeDisplayed={window.innerWidth < 700 ? 2 : 5}
                        pageCount={pageCount3}
                        nextLabel="❯"
                        nextClassName="text-primaryColor text-2xl"
                        pageClassName="bg-white  rounded-xl text-center border font-semibold w-10 h-10 flex items-center justify-center hover:text-white   text-primaryColorActive border-primaryColor border-2 border-primaryColor hover:bg-primaryColor hover:border-primaryColorHover active:shadow-innerbig active:bg-primaryColorActive active:border-primaryColorActive"
                        pageLinkClassName="w-full h-full items-center justify-center flex"
                        previousLabel="❮"
                        previousClassName="text-primaryColor text-2xl"
                        activeClassName=" bg-primaryColorActive text-center justify-center rounded-xl border border-primaryColorActive w-10 h-10 text-white forceWhiteText hover:bg-primaryColorHover hover:border-primaryColorHover active:shadow-innerbig active:bg-primaryColorActive active:border-primaryColorActive"
                        containerClassName="flex flex-row gap-x-4 xsm:gap-x-1 items-center  mx-auto  mb-12"
                        renderOnZeroPageCount={false}
                        marginPagesDisplayed={1}
                    />
                </div>
            }
        </div>

    )
}

export default OrderHistory
