import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import React from "react";
import { editAuthUser } from "../../../Util/Api/CustomerAPI";
import Alert from "@mui/material/Alert";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth < 1000 ? "95%" : 600,
  maxHeight: 700,
  // overflowY: 'scroll',
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 2,
};

function ChangeAddressModal({
  open,
  handleClose,
  SetReload,
  theAddressToBeEdited,
  userData,
  SetAddress,
}) {
  const user = JSON.parse(localStorage.getItem("currentCustomer"));
  const [error, seterror] = React.useState(false);
  function handleChange(e) {
    SetAddress({ ...theAddressToBeEdited, [e.target.name]: e.target.value });
  }

  React.useEffect(() => {
    if (error) {
      setTimeout(() => {
        seterror(false);
      }, 3000);
    }
    return () => {};
  }, [error]);

  let TheCurrentAddressesWithOutID = userData?.shippingAddress?.map((item) => ({
    recipientFullName: item.recipientFullName,
    telephone: item.telephone,
    city: item.city,
    CAP: item.CAP,
    firstLine: item.firstLine,
    secondLine: item.secondLine,
  }));
  const theNewUser = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: userData?.email,
    shippingAddress: TheCurrentAddressesWithOutID?.concat({
      recipientFullName: theAddressToBeEdited.recipientFullName,
      telephone: String(theAddressToBeEdited.telephone),
      city: theAddressToBeEdited.city,
      CAP: String(theAddressToBeEdited.CAP),
      firstLine: theAddressToBeEdited.firstLine,
      secondLine: theAddressToBeEdited.secondLine,
    }),
  };

  const handleSubmit = async () => {
    if (
      !theAddressToBeEdited.recipientFullName ||
      !theAddressToBeEdited.telephone ||
      !theAddressToBeEdited.city ||
      !theAddressToBeEdited.CAP ||
      !theAddressToBeEdited.firstLine ||
      !theAddressToBeEdited.secondLine
    )
      seterror(true);
    else {
      seterror(false);

      try {
        const res = await editAuthUser(user.id, { ...theNewUser });
        SetReload(res);
        handleClose();
      } catch (error) {
        toast.error();
      }
    }
  };
  const handleSubmitEditAddress = async () => {
    const theRestOftheAddresses = userData?.shippingAddress?.filter(
      (item) => item._id !== theAddressToBeEdited._id
    );
    let TheCurrentAddressesWithOutIDD = theRestOftheAddresses?.map((item) => ({
      recipientFullName: item.recipientFullName,
      telephone: item.telephone,
      city: item.city,
      CAP: item.CAP,
      firstLine: item.firstLine,
      secondLine: item.secondLine,
    }));
    const theNewUser22 = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      shippingAddress: TheCurrentAddressesWithOutIDD.concat({
        recipientFullName: theAddressToBeEdited.recipientFullName,
        telephone: theAddressToBeEdited.telephone,
        city: theAddressToBeEdited.city,
        CAP: theAddressToBeEdited.CAP,
        firstLine: theAddressToBeEdited.firstLine,
        secondLine: theAddressToBeEdited.secondLine,
      }),
    };
    if (
      !theAddressToBeEdited.recipientFullName ||
      !theAddressToBeEdited.telephone ||
      !theAddressToBeEdited.city ||
      !theAddressToBeEdited.CAP ||
      !theAddressToBeEdited.firstLine ||
      !theAddressToBeEdited.secondLine
    )
      seterror(true);
    else {
      try {
        const res = await editAuthUser(user.id, { ...theNewUser22 });
        SetReload(res);
        handleClose();
      } catch (error) {
        toast.error();
        console.log(error);
      }
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => handleClose()}
        keepMounted={false}
        closeAfterTransition
        disableScrollLock
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="flex flex-col  relative gap-y-6">
              <button
                onClick={() => handleClose()}
                className="absolute top-0 right-0 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="15" y1="9" x2="9" y2="15"></line>
                  <line x1="9" y1="9" x2="15" y2="15"></line>
                </svg>
              </button>

              <div className="mt-8 w-full flex flex-col gap-y-4">
                <div className="flex flex-col text-left gap-y-2">
                  <p className="text-lg text-black font-semibold">
                    Nome Completo
                  </p>
                  <input
                    name="recipientFullName"
                    value={theAddressToBeEdited?.recipientFullName}
                    onChange={(e) => handleChange(e)}
                    style={{
                      backgroundSize: "25",
                      backgroundPosition: "98% center",
                      backgroundRepeat: "no-repeat",
                    }}
                    placeholder="Es: Angelo D'Angelo"
                    className="h-10 rounded-l-md rounded-r-md bg-transparent py-1 px-4 w-full border text-black"
                  />
                </div>

                <div className="flex flex-col text-left gap-y-2">
                  <p className="text-lg text-black font-semibold">
                    Numero di Telefono
                  </p>
                  <input
                    // onWheel={() => document.activeElement.blur()}
               
                    name="telephone"
                    value={theAddressToBeEdited?.telephone}
                    onChange={(e) => handleChange(e)}
                    style={{
                      backgroundSize: "25",
                      backgroundPosition: "98% center",
                      backgroundRepeat: "no-repeat",
                    }}
                    placeholder="0123456789"
                    className="h-10 rounded-l-md rounded-r-md bg-transparent py-1 px-4 w-full border text-black"
                  />
                </div>


                <div className="flex flex-col text-left gap-y-2">
                  <p className="text-lg text-black font-semibold">Città</p>
                  <input
                    name="city"
                    value={theAddressToBeEdited?.city}
                    onChange={(e) => handleChange(e)}
                    style={{
                      backgroundSize: "25",
                      backgroundPosition: "98% center",
                      backgroundRepeat: "no-repeat",
                    }}
                    placeholder="Palermo"
                    className="h-10 rounded-l-md rounded-r-md bg-transparent py-1 px-4 w-full border text-black"
                  />
                </div>

                <div className="flex flex-col text-left gap-y-2">
                  <p className="text-lg text-black font-semibold">Cap</p>
                  <input
                    onWheel={() => document.activeElement.blur()}
                    type="number"
                    name="CAP"
                    value={theAddressToBeEdited?.CAP}
                    onChange={(e) => handleChange(e)}
                    style={{
                      backgroundSize: "25",
                      backgroundPosition: "98% center",
                      backgroundRepeat: "no-repeat",
                    }}
                    placeholder="41894"
                    className="h-10 rounded-l-md rounded-r-md bg-transparent py-1 px-4 w-full border text-black"
                  />
                </div>

                <div className="flex flex-col text-left gap-y-2">
                  <p className="text-lg text-black font-semibold">
                    Via/Strada/Corso
                  </p>
                  <input
                    name="firstLine"
                    value={theAddressToBeEdited?.firstLine}
                    onChange={(e) => handleChange(e)}
                    style={{
                      backgroundSize: "25",
                      backgroundPosition: "98% center",
                      backgroundRepeat: "no-repeat",
                    }}
                    placeholder="Via delle cicogne"
                    className="h-10 rounded-l-md rounded-r-md bg-transparent py-1 px-4 w-full border text-black"
                  />
                </div>

                <div className="flex flex-col text-left gap-y-2">
                  <p className="text-lg text-black font-semibold">N° Civico</p>
                  <input
                    name="secondLine"
                    value={theAddressToBeEdited?.secondLine}
                    onChange={(e) => handleChange(e)}
                    style={{
                      backgroundSize: "25",
                      backgroundPosition: "98% center",
                      backgroundRepeat: "no-repeat",
                    }}
                    placeholder="40"
                    className="h-10 rounded-l-md rounded-r-md bg-transparent py-1 px-4 w-full border text-black"
                  />
                </div>

                {error && (
                  <Alert className="mb-3 text-red-400" severity="error">
                    Mancano dei campi obbligatori!
                  </Alert>
                )}
                <div className="flex self-center">
                  <button
                    onClick={() =>
                      theAddressToBeEdited._id
                        ? handleSubmitEditAddress()
                        : handleSubmit()
                    }
                    className="bg-primaryColor uppercase hover:bg-[#2E2D2B] px-20 text-bold py-2 rounded-md text-white"
                  >
                    {theAddressToBeEdited?._id ? "Modifica" : "Aggiungi"}
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default ChangeAddressModal;
