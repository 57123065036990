import React, { useContext, useEffect } from 'react'
import { MyContext } from "../../App"
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";

import TableHead from "@mui/material/TableHead";
import "./Modal.css"
function Ricetta() {
    const { totalPrice, cart, RestaurantInfo, DeliverySettings, isForDelivery, cartModalState, handleCloseCartModal, handleRemove, finalShippingCost } = useContext(MyContext)

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            borderBottom: 0,
            color: "white",
            backgroundColor: "darkgray",
            height: "100%"
        },
        [`&.${tableCellClasses.body}`]: {},
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {},
        "&:nth-of-type(even)": {},
        // hide last border
        "td, th": {
            borderBottom: 0,
        },
    }));
    React.useEffect(() => {
        cart.length === 0 && handleCloseCartModal()
    }, [cart])


    useEffect(() => {
        const favicon = document.querySelector('link[rel="icon"]');
        if (favicon && RestaurantInfo) {
            favicon.href = RestaurantInfo?.theme_photos?.favicon;
        }
    }, [RestaurantInfo]);


    return (
        <>

            <Dialog open={cartModalState} maxWidth={false} onClose={handleCloseCartModal}>

                <div className="p-8 sm:p-2 xsm:p-2 md:p-2 detailsrow ">





                    <h1 className="text-xl text-primaryColor font-bold text-center self-center mb-8 ">
                        Dettagli dell'ordine
                    </h1>

                    <div className="OrderDetailsTable sm:hidden xsm:hidden md:hidden">

                        <Table sx={{ minWidth: 790 }}>

                            <TableHead className="OrderDetailsTable">

                                <TableRow style={{ height: 50, alignItems: "center", justifyItems: "center", display: "flex" }} className="flex flex-row items-center justify-center w-full h-full elevation-1 rounded-md">

                                    <StyledTableCell style={{ fontFamily: 'var(--secondary-font)', fontSize: "18px", fontWeight: "bold", width: 310, textAlign: "left" }}>Nome prodotto</StyledTableCell>
                                    <StyledTableCell style={{ fontFamily: 'var(--secondary-font)', fontSize: "18px", fontWeight: "bold", width: 200, textAlign: "center" }} >Quantità</StyledTableCell>
                                    <StyledTableCell style={{ fontFamily: 'var(--secondary-font)', fontSize: "18px", fontWeight: "bold", width: 200, textAlign: "center" }}>Totale</StyledTableCell>
                                    <StyledTableCell style={{ fontFamily: 'var(--secondary-font)', fontSize: "18px", fontWeight: "bold", width: 80, textAlign: "center" }}></StyledTableCell>

                                </TableRow>

                            </TableHead>

                            <TableBody>
                                {cart?.map((productData, index) => {
                                    const product = productData?._id;
                                    const quantity = productData?.quantity;


                                    return (
                                        <StyledTableRow key={index} className=" mb-3 mt-4  rounded-md elevation-1 py-3 bg-white flex items-center " >
                                            <StyledTableCell style={{ fontSize: "15px", fontWeight: "bold", width: 310 }} >
                                                <div className="flex flex-col gap-y-1" >
                                                    <p className="text-primaryColor font-bold uppercase text-lg">
                                                        {product?.title || product?.name}

                                                    </p>
                                                    {!productData?.discount &&
                                                        <div className="flex gap-x-1">
                                                            <p className="text-black text-xs font-thin">
                                                                Formato:
                                                            </p>
                                                            <span className="text-black text-xs font-thin">
                                                                {productData?._id?.DesiredVariations?.name ? productData?._id?.DesiredVariations?.name : productData?._id?.variations[0]?.name ? productData?._id?.variations[0]?.name : "Standard"}
                                                            </span>
                                                        </div>}
                                                    {!productData?.discount && productData?._id?.DesiredOptions?.length !== 0 &&


                                                        <div className="flex gap-x-1">

                                                            <p className="text-black text-xs font-thin">
                                                                Aggiunte:
                                                            </p>
                                                            <span className="text-black text-xs font-thin min-w-full">
                                                                {productData?._id?.DesiredOptions.map(o => o.name).join(", ")}
                                                            </span>

                                                        </div>





                                                    }
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontSize: "18px", fontWeight: "bold", width: 200 }} >
                                                {quantity}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontSize: "18px", fontWeight: "bold", width: 200 }} >
                                                {productData._id?.price * productData?.quantity} €
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => {
                                                handleRemove(productData?.name)
                                                cart.length === 1 && localStorage.removeItem("DeliveryCart")
                                            }} align="center" style={{ fontSize: "12px", width: 80 }} >
                                                <span className="text-red-500 hover:text-red-600 hover:cursor-pointer font-semibold" >Elimina</span>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                                {isForDelivery &&
                                    <StyledTableRow className=" mb-3 mt-1  rounded-md elevation-1 py-3 bg-white flex items-center " >
                                        <StyledTableCell style={{ fontSize: "15px", fontWeight: "bold", width: 310 }} >

                                            <p className="text-black font-bold text-lg">
                                                Costo di Consegna

                                            </p>

                                        </StyledTableCell>
                                        <StyledTableCell align="center" style={{ fontSize: "18px", fontWeight: "bold", width: 200 }} >

                                        </StyledTableCell>
                                        <StyledTableCell align="center" style={{ fontSize: "18px", fontWeight: "bold", width: 200 }} >
                                            {finalShippingCost}€
                                        </StyledTableCell>
                                    </StyledTableRow>
                                }
                            </TableBody>
                        </Table>
                    </div>
                    <div className="lg:hidden xl:hidden xmd:hidden">

                        {cart?.map((productData, index) => {
                            const product = productData?._id;
                            const quantity = productData.quantity;

                            return (
                                <></>
                                // <div key={index} className="w-[95%] mx-auto p-2 flex justify-between items-center flex-row gap-y-2 h-fit my-4 elevation-1" >
                                //     <div className="flex flex-col ">
                                //         <div className="flex flex-row items-center gap-x-2" >
                                //             <p className="text-primaryColor font-bold uppercase text-[14px]">{product?.title}</p>
                                //             <p className="text-[10px] text-black font-normal">X{quantity} </p>
                                //         </div>
                                //         {!productData?.discount &&
                                //             <div className="flex gap-x-1">
                                //                 <p className="text-black text-[10px] font-thin">
                                //                     Formato:
                                //                 </p>
                                //                 <span className="text-black text-[10px] font-thin">
                                //                     {productData?._id?.DesiredVariations?.name ? productData?._id?.DesiredVariations?.name : productData?._id?.variations[0]?.name ? productData?._id?.variations[0]?.name : "Standard"}
                                //                 </span>
                                //             </div>}
                                //         {!productData?.discount && productData?._id?.DesiredOptions?.length !== 0 &&
                                //             <p className="text-black font-normal text-[10px]">


                                //                 aggiunte:{" "}
                                //                 <span>
                                //                     {productData._id?.DesiredOptions.map(o => o.name).join(", ")}
                                //                 </span>


                                //             </p>
                                //         }
                                //     </div>

                                //     <p className="text-[12px] text-black font-normal">     {productData._id?.price * productData?.quantity} €</p>
                                // </div>
                            )
                        })}


                        {/* {isForDelivery &&
                            <div className="flex flex-row justify-between items-center gap-y-1" >
                                <p className="text-black font-bold text-[10px]">Costo di Consegna</p>
                                <p className="text-sm text-black font-normal">   {finalShippingCost}€ </p>
                            </div>
                        } */}
                    </div>


                    <div className="bg-primaryColor flex flex-row justify-between items-center w-full h-10 px-6 rounded-md elevation-1 mt-1 mb-3" >
                        <p className="text-white font-bold tracking-wide ">Totale</p>
                        <p className='text-white font-bold tracking-wide '> {isForDelivery ? (parseFloat(totalPrice) + finalShippingCost) : parseFloat(totalPrice)}€</p>

                    </div>


                    <div className="flex flex-col gap-y-1 pl-3  self-center mx-auto" >
                        <p className="text-black font-normal text-sm sm:text-center xsm:text-center md:text-center">{RestaurantInfo?.name}</p>
                        <p className="text-black font-normal text-sm sm:text-center xsm:text-center md:text-center">{RestaurantInfo?.formatted_address}</p>
                    </div>


                </div>
            </Dialog>
        </>
    )
}

export default Ricetta