export const navPubTheme = [
  {
    name: "Home",
    path: "/",
    CustomizingPath: "/customize-theme/"
  },
  {
    name: "Menu",
    path: "/menu",
    CustomizingPath: "/customize-theme/menu"
  },
  {
    name: "Delivery",
    path: "/delivery",
    CustomizingPath: "/customize-theme/delivery"
  },
  {
    name: "Prenotazioni",
    path: "/prenotazioni",
    CustomizingPath: "/customize-theme/prenotazioni"
  },
  {
    name: "Contatti",
    path: "/contatti",
    CustomizingPath: "/customize-theme/contatti"
  },
];
