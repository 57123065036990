import { CREATE, DELETE, FETCH_ALL, FETCH_ONE, UPDATE, } from "../../Constants/actionTypes";

const INITIAL_STATE = { products: [] };

export const ProductReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL:
      return {
        ...state,
        products: action.payload,
      };
    case FETCH_ONE:
      return {
        ...state,
        product: action.payload,
      };
    case CREATE:
      return {
        ...state,
        products: [...state.products, action.payload],
      };
    case UPDATE:
      return {
        ...state,
        products: state.products.map((product) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case DELETE:
      return {
        ...state,
        products: state.products.filter(
          (product) => product.id !== action.payload.id
        ),
      };
    default:
      return state;
  }
};
