import React, { useEffect, useState } from "react";
import "./Hero.css";
import LogoBeer from "../../../../Assets/Image/logobeer.png";
import Beer from "../../../../Assets/Image/beer.png";
import { navPubTheme } from "../../../../Util/Constants/navPubTheme";
import { NavLink, useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import SidebarMobile from "./sidebarMobile/sidebarMobile";
import { MyContext } from "../../../../App";
import BGbeer from "../../../../Assets/Image/bgBeer.jpg";
import useWindowSize from "../../../Reusable/WindowSize";
import TextareaAutosize from "react-textarea-autosize";
import Newsletter from "./newsletter.js";

const Hero = ({ title, subTitle, handleOpen }) => {
  const [hamburger, setHamburger] = useState(false);
  const {
    RestaurantInfo,
    SetRestaurantInfo,
    PatchNewInfo,
    CustomizingTheWebsite,
  } = React.useContext(MyContext);
  const { pathname } = useLocation();
  const ScreenWidth = useWindowSize();

  const isMobile = ScreenWidth < 1000;
  const HomePage = (pathname === "/") | (pathname === "/customize-theme/");
  const DeliveryPage =
    pathname === "/delivery" || pathname === "/customize-theme/delivery";
  const MenuPage =
    (pathname === "/menu") | (pathname === "/customize-theme/menu");
  const ReservationPage =
    (pathname === "/prenotazioni") |
    (pathname === "/customize-theme/prenotazioni");
  const ContactPage =
    (pathname === "/contatti") | (pathname === "/customize-theme/contatti");
  const HomePageForMobile = HomePage && isMobile;
  const DeliveryPageForMobile = DeliveryPage && isMobile;
  const MenuPageForMobile = MenuPage && isMobile;
  const ReservationPageForMobile = ReservationPage && isMobile;
  const ContactPageForMobile = ContactPage && isMobile;
  const WhichModal = HomePage
    ? "hero"
    : DeliveryPage
    ? "delivery"
    : MenuPage
    ? "menuBanner"
    : ReservationPage
    ? "reservation"
    : "contacts";

  function HeroImage() {
    if (HomePage) {
      if (HomePageForMobile) {
        return `url(${
          RestaurantInfo?.theme_photos?.home?.hero?.hero_mobile_img || BGbeer
        })`;
      } else {
        return `url(${
          RestaurantInfo?.theme_photos?.home?.hero?.hero_img || BGbeer
        })`;
      }
    }
    if (DeliveryPage) {
      if (DeliveryPageForMobile) {
        return `url(${
          RestaurantInfo?.theme_photos?.delivery?.heromobile || BGbeer
        })`;
      } else {
        return `url(${RestaurantInfo?.theme_photos?.delivery?.hero || BGbeer})`;
      }
    }
    if (MenuPage) {
      if (MenuPageForMobile) {
        return `url(${
          RestaurantInfo?.theme_photos?.menu?.heromobile || BGbeer
        })`;
      } else {
        return `url(${RestaurantInfo?.theme_photos?.menu?.hero || BGbeer})`;
      }
    }
    if (ReservationPage) {
      if (ReservationPageForMobile) {
        return `url(${
          RestaurantInfo?.theme_photos?.reservation?.heromobile || BGbeer
        })`;
      } else {
        return `url(${
          RestaurantInfo?.theme_photos?.reservation?.hero || BGbeer
        })`;
      }
    }
    if (ContactPage) {
      if (ContactPageForMobile) {
        return `url(${
          RestaurantInfo?.theme_photos?.contact?.contact_mobile_img || BGbeer
        })`;
      } else {
        return `url(${
          RestaurantInfo?.theme_photos?.contact?.contact_img || BGbeer
        })`;
      }
    }
  }

  function Title() {
    if (HomePage)
      return (
        RestaurantInfo?.theme_photos?.home?.hero?.title || "Paulner Munchen"
      );
    if (DeliveryPage)
      return RestaurantInfo?.theme_photos?.delivery?.title || "Delivery";
    if (MenuPage) return RestaurantInfo?.theme_photos?.menu?.title || "Menu";
    if (ReservationPage)
      return RestaurantInfo?.theme_photos?.reservation?.title || "Reservation";
    if (ContactPage)
      return RestaurantInfo?.theme_photos?.contact?.title || "Contac us";
  }
  function Paragraph() {
    if (HomePage)
      return (
        RestaurantInfo?.theme_photos?.home?.hero?.paragraph ||
        "L’Italia ha il gusto della pasta, di casa, di famiglia, di tradizione Scopri l’Itala attraverso la nostra cucina."
      );
    if (DeliveryPage)
      return (
        RestaurantInfo?.theme_photos?.delivery?.paragraph ||
        "L’Italia ha il gusto della pasta, di casa, di famiglia, di tradizione Scopri l’Itala attraverso la nostra cucina."
      );
    if (MenuPage)
      return (
        RestaurantInfo?.theme_photos?.menu?.paragraph ||
        "L’Italia ha il gusto della pasta, di casa, di famiglia, di tradizione Scopri l’Itala attraverso la nostra cucina."
      );
    if (ReservationPage)
      return (
        RestaurantInfo?.theme_photos?.reservation?.paragraph ||
        "L’Italia ha il gusto della pasta, di casa, di famiglia, di tradizione Scopri l’Itala attraverso la nostra cucina."
      );
    if (ContactPage)
      return (
        RestaurantInfo?.theme_photos?.contact?.paragraph ||
        "L’Italia ha il gusto della pasta, di casa, di famiglia, di tradizione Scopri l’Itala attraverso la nostra cucina."
      );
  }

  const [whichElement, SetElement] = React.useState("hero");
  function handleChange(value) {
    if (HomePage)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          home: {
            ...RestaurantInfo.theme_photos.home,
            hero: { ...RestaurantInfo.theme_photos.home.hero, title: value },
          },
        },
      });
    if (DeliveryPage)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          delivery: { ...RestaurantInfo.theme_photos.delivery, title: value },
        },
      });
    if (MenuPage)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          menu: { ...RestaurantInfo.theme_photos.menu, title: value },
        },
      });
    if (ReservationPage)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          reservation: {
            ...RestaurantInfo.theme_photos.reservation,
            title: value,
          },
        },
      });
    if (ContactPage)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          contact: { ...RestaurantInfo.theme_photos.contact, title: value },
        },
      });
  }

  function handleChange2(value) {
    if (HomePage)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          home: {
            ...RestaurantInfo.theme_photos.home,
            hero: {
              ...RestaurantInfo.theme_photos.home.hero,
              paragraph: value,
            },
          },
        },
      });
    if (DeliveryPage)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          delivery: {
            ...RestaurantInfo.theme_photos.delivery,
            paragraph: value,
          },
        },
      });
    if (MenuPage)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          menu: { ...RestaurantInfo.theme_photos.menu, paragraph: value },
        },
      });
    if (ReservationPage)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          reservation: {
            ...RestaurantInfo.theme_photos.reservation,
            paragraph: value,
          },
        },
      });
    if (ContactPage)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          contact: { ...RestaurantInfo.theme_photos.contact, paragraph: value },
        },
      });
  }

  const [isFocused, SetFocus] = React.useState(false);
  const [isParagraphFocused, SetParaFocus] = React.useState(false);
  function onSubmit(e) {
    e.preventDefault();
    document.getElementById("titleField").blur();
    SetFocus(false);
    PatchNewInfo();
  }
  function onSubmit2(e) {
    e.preventDefault();
    document.getElementById("paragraphField").blur();
    SetParaFocus(false);
    PatchNewInfo();
  }
  const [showNewsletter, setShowNewsletter] = useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNewsletter(true);
      setOpenPopup(true);
    }, 4000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  
  return (
    <>
      <div
        style={{
          backgroundImage: `linear-gradient(90deg, rgba(23,54,89,.2) 0%, rgba(23,54,89,.2) 70%),
      ${HeroImage()}`,
          backgroundSize: "cover",
        }}
        onClick={() => {
          CustomizingTheWebsite &&
            whichElement === "hero" &&
            handleOpen(WhichModal) &&
            SetFocus(false) &&
            SetParaFocus(false);
        }}
        className={`Hero mb-8 z-10
      ${
        CustomizingTheWebsite &&
        " hover:cursor-pointer  border-8 border-transparent mt-28"
      } ${
          whichElement === "hero" &&
          CustomizingTheWebsite &&
          "hover:border-primarybtnhover"
        } 
      `}
      >
        <div className="Hero__wrapper" style={{ fontFamily: 'var(--primary-font)' }}>
          <div
            className="Hero__hamburger"
            onClick={() => setHamburger((prev) => !prev)}
          >
            <GiHamburgerMenu />
          </div>
          {hamburger && <SidebarMobile />}
          <aside
            onMouseEnter={() => SetElement("sideBar")}
            onMouseLeave={() => SetElement("hero")}
            className="Hero__sidebar z-20 hover:cursor-auto"
          >
            <img src={LogoBeer} alt="sidebar" />
            <ul className="Hero__sidebar-list">
              {navPubTheme.map((item, index) => {
                return (
                  <li key={index} className="Hero__list-item">
                    <NavLink
                      to={
                        CustomizingTheWebsite ? item.CustomizingPath : item.path
                      }
                      className={({ isActive }) =>
                        isActive ? "Hero-item--active" : ""
                      }
                      end
                    >
                      {item.name}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </aside>
          <div className="Hero__informations">
            <div className="flex items-center">
              {HomePage && <img className="Hero__img" src={Beer} alt="beer" />}
              <div className="">
                {!CustomizingTheWebsite ? (
                  <>
                    <h1 className="Hero__title whitespace-pre-line">
                      {Title()}{" "}
                    </h1>
                    <p className="Hero__text whitespace-pre-line">
                      {Paragraph()}
                    </p>
                  </>
                ) : (
                  <div className="flex flex-col w-full ">
                    <form onSubmit={(e) => onSubmit(e)} className="flex w-full">
                      <TextareaAutosize
                        id="titleField"
                        rows={1}
                        onChange={(e) => handleChange(e.target.value)}
                        value={Title()}
                        onMouseEnter={() => SetElement("title")}
                        onMouseLeave={() => SetElement("hero")}
                        onFocus={() => SetFocus(true)}
                        className={` resize-none w-full  text-white z-50
                                    ${
                                      whichElement === "title" &&
                                      "underline decoration-2 cursor-text"
                                    }
                                    uppercase leading-none text-7xl font-bold bg-gray-100  focus:outline-none `}
                      />

                      {isFocused && (
                        <button
                          type="submit"
                          onMouseEnter={() => SetElement("title")}
                          onClick={(e) => {
                            onSubmit(e);
                          }}
                          style={{ zIndex: 70 }}
                          className="w-32 px-8 rounded-md h-12 my-auto -ml-28 text-white bg-primarybtn hover:bg-primarybtnhover hover:cursor-pointer"
                        >
                          {" "}
                          Salva
                        </button>
                      )}
                    </form>
                    <form
                      onSubmit={(e) => onSubmit2(e)}
                      className="flex w-full"
                    >
                      <TextareaAutosize
                        id="paragraphField"
                        rows={1}
                        onChange={(e) => handleChange2(e.target.value)}
                        value={Paragraph()}
                        onMouseEnter={() => SetElement("paragraph")}
                        onMouseLeave={() => SetElement("hero")}
                        onFocus={() => SetParaFocus(true)}
                        className={`Hero__text resize-none tracking-wide w-full bg-gray-100 focus:outline-none
                            ${
                              whichElement === "paragraph" &&
                              "underline decoration-2 cursor-text "
                            }
                            text-white font-light`}
                      />
                      {isParagraphFocused && (
                        <button
                          onMouseEnter={() => SetElement("title")}
                          type="submit"
                          onClick={() => {
                            onSubmit2();
                          }}
                          style={{ zIndex: 70 }}
                          className="w-32 px-8 rounded-md h-12 my-auto  text-white bg-primarybtn hover:bg-primarybtnhover hover:cursor-pointer"
                        >
                          {" "}
                          Salva{" "}
                        </button>
                      )}
                    </form>
                  </div>
                )}

                {HomePage && (
                  <div className="Hero__description">
                    <h4 className="Hero__description-label">Descrizione</h4>
                    <ul className="Hero__list">
                      <li className="Hero__item">
                        <span className="Hero__item-label">Size: </span>
                        <span>450ml</span>
                      </li>
                      <li className="Hero__item">
                        <span className="Hero__item-label">Alcool: </span>
                        <span>0,0%</span>
                      </li>
                      <li className="Hero__item">
                        <span className="Hero__item-label">Mosto: </span>
                        <span>8,5%</span>
                      </li>
                      <li className="Hero__item">
                        <span className="Hero__item-label">Luppolo: </span>
                        <span>Herkules</span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*
      {showNewsletter && (
        <Newsletter openPopup={openPopup} setOpenPopup={setOpenPopup} />
      )}
      */}
    </>
  );
};

export default Hero;
