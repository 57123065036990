import React from 'react';
import Contacts from '../../Components/Contacts/Contacts';
import { MyContext } from '../../App';
import ChangeBGModal from "../../Components/Home/ChangeBGModal";



function Contatti() {
  const { themeID, PatchNewInfo, SetRestaurantInfo, RestaurantInfo } = React.useContext(MyContext)
  const contactlist = { cont: themeID };

  const [open, setOpen] = React.useState(false)
  const handleOpen = (section) => setOpen(true)
  const handleClose = () => setOpen(false);

  
  return (
    <>
      <Contacts handleOpen={handleOpen} cont={contactlist['cont']} />
      <ChangeBGModal WhichPart={"contacts"} handleClose={handleClose} open={open} PatchNewInfo={PatchNewInfo} SetRestaurantInfo={SetRestaurantInfo} RestaurantInfo={RestaurantInfo} />

    </>
  );
}

export default Contatti;