import React, { useState } from "react";
import { getSiteId } from "../../../../Util/Data/userData";
import { toast } from "react-toastify";
import { baseUrl } from "../../../../Util/Api/Urls";
import axios from "axios";
import { Backdrop, Box, Checkbox, Fade, FormControlLabel, Modal } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";


const Newsletter = ({openPopup,setOpenPopup}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#fff',
    boxShadow: 24,
    p: 4,
};
  const [email, setEmail] = React.useState();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  async function handleSubscribe(e) {
    e.preventDefault();
    if (!isChecked) {
      toast.error("Please accept the privacy policy and terms and conditions.");
      return;
    }
    try {
      const body = { subscribers: [email] };
      const res = await axios.post(
        `https://${getSiteId()}.${
          process.env.REACT_APP_THEMES_PREFIX
        }${baseUrl()}${
          process.env.REACT_APP_CUSTOMERS_API_PATH
        }/newsletters/subscribers`,
        body
      );
      console.log(res);
      setEmail("");
      toast.success("Complimenti, ti sei iscritto alla Newsletter!");
    } catch (error) {
      console.log(error);
      if (error === 304) {
        setEmail("");
        toast.warning("Sei già iscritto alla Newsletter!");
      } else {
        setEmail("");
        toast.error("Errore durante la registrazione");
      }
    }
  }
  const handleClose = () => setOpenPopup(false);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openPopup}
      onClose={handleClose}
      disableScrollLock
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openPopup}>
        <Box
          sx={style}
          className="w-[700px] xsm:w-full sm:w-full md:w-full xmd:w-full"
        >
          <button
            onClick={() => {
              handleClose();
            }}
            className="absolute text-primaryColor top-0 right-0 m-6"
          >
            <AiOutlineCloseCircle size={30} />
          </button>

          <p className="font-extrabold text-primaryColor text-4xl mt-6 mb-3 mx-auto w-fit ">
            ISCRIVITI ALLA NEWSLETTER
          </p>
          <p className="font-semibold text-black text-lg mb-6 mx-auto w-fit ">
            {" "}
            Iscriviti alla Newsletter del nostro Ristorante e ricevi{" "}
            <span className="font-extrabold">tante offerte</span> sia per il
            delivery che per le tue prenotazioni!
          </p>

          <div className="w-full grid grid-cols-1 justify-items-center gap-y-5">
            <div className="flex flex-col gap-y-1 w-[90%]">
              <p className="text-left">E-mail*</p>
              <form onSubmit={(e) => handleSubscribe(e)}>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  className="lowercase w-full	 mb-4 block bg-transparent border-x-0 border-b border-[#AEBBC3]"
                  placeholder="E-mail"
                  name="mail"
                  required
                  value={email}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      name="Informativa-Privacy-checkbox"
                      color="primary"
                    />
                  }
                  label="Accetto la privacy policy e le condizioni"
                />
                <button
                  type="submit"
                  className="	p-4 fill-primaryColor border-primaryColor mx-[25%] font-bold text-[#FFFFFF] w-1/2 cursor-pointer uppercase subscribe bg-primaryColor"
                >
                  Iscriviti{" "}
                </button>
              </form>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default Newsletter;
