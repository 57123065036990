import React, { useState } from 'react'
import Package02 from "../../../Assets/Image/Login.png";
import CircularProgress from '@mui/material/CircularProgress';
import { MyContext } from '../../../App';
import ScrollToTop from '../../../Reusable/ScrollToTop';
import { toast } from "react-toastify"
import { requestPasswordChangeApi } from '../../../Util/Redux/actions/AuthAction';
import { getSiteId } from '../../../Util/Data/userData';
import { useParams } from 'react-router-dom';

function RequestPassword() {
    const { RestaurantInfo } = React.useContext(MyContext)
    const [isLoading, setIsLoading] = useState(false);
    const [CurrentEmail, SetEmail] = useState("");
    const [isFinished, SetFinish] = React.useState(false)
const siteId =getSiteId()
var currentUrl = window.location.toString();
var url = new URL(currentUrl);
var baseUrl = url.protocol + '//' + url.host;
console.log(siteId); 



    async function requestPasswordChange(e) {
        e.preventDefault()
        setIsLoading(true)
        try {

            // const data ={
            //     email:CurrentEmail,
            //     siteId:siteId,
            //     siteUrl:baseUrl
            // }

            const res = await requestPasswordChangeApi({
                email:CurrentEmail,
                siteId:siteId,
                siteUrl:baseUrl
            })
            console.log(res)
            SetFinish(true)
        } catch (error) {
            toast.error("")
        }
        setIsLoading(false)
    }

    function HeroImage() {
        if (window.innerWidth < 1000) {
            return `${RestaurantInfo?.theme_photos?.login?.login_mobile_img || Package02}`
        } else {
            return `${RestaurantInfo?.theme_photos?.login?.login_img || Package02}`
        }
    }

    return (
        <div className={`w-full sm:w-full md:w-full xsm:w-full h-screen sm:h-fit  xsm:h-fit md:h-fit flex flex-row xsm:flex-col sm:flex-col md:flex-col justify-center items-center  xsm:pb-12 sm:pb-12 md:pb-12 `}>
            <ScrollToTop />
            <div
                className={`flex justify-center  items-center w-[50%] sm:w-full xsm:w-full md:w-full h-full sm:h-[300px] xsm:h-[300px] md:h-[400px]`}>
                <img src={HeroImage()} alt="" className='object-cover object-center w-full h-full' />
            </div>
            <div className='w-[50%] flex flex-col gap-y-6 justify-center   xmd:px-4 px-8 sm:px-2 xsm:px-2 md:px-4 sm:py-4 xsm:py-4 md:py-4 h-[95%]  xsm:w-full sm:w-full md:w-full'>
                {!isFinished ?
                    <form
                        id="myform"
                        onSubmit={(e) => requestPasswordChange(e)}
                        className='w-[95%] mx-auto flex flex-col items-center gap-y-6 my-8 ' >
                        <p className='text-primaryColor text-left Brevia850 mr-auto text-5xl xmd:text-3xl sm:text-2xl xsm:text-2xl md:text-2xl sm:my-5 xsm:my-5 md:my-5'>Richiesta di cambio Password</p>
                        <p className='Brevia700 text-xl text-left mr-auto'>Inserisci l'email con cui ti sei registrato</p>
                        <input
                            type="email"
                            required
                            value={CurrentEmail}
                            onChange={(e) => SetEmail(e.target.value)}
                            disabled={isLoading}
                            placeholder="Es: email@qualcosa.com"
                            className='h-12 w-full border-b-2 border-gray-300 px-1 text-lg pop500 focus:outline-none' />
                    </form>
                    :
                    <p className='text-primaryColor text-left Brevia850 mr-auto text-5xl xmd:text-3xl sm:text-2xl xsm:text-2xl md:text-2xl sm:my-5 xsm:my-5 md:my-5'>Ti è stata inviata una email per confermare il cambio password</p>
                }
                {!isFinished &&
                    <button
                        form="myform"
                        type="submit"
                        onClick={e => requestPasswordChange(e)}
                        className='w-[100%] h-12  bg-primaryColor hover:bg-primaryColorHover items-center flex justify-center '>
                        {isLoading ? <CircularProgress color="inherit" /> : <p className='pop500 text-white'>Conferma</p>}
                    </button>
                }
            </div>
        </div>
    )
}

export default RequestPassword