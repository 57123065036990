import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import React, { useState} from "react";
import { changePassword } from "../../../Util/Api/CustomerAPI";
// import { getCustomerById } from "../../../Util/Redux/reducers/AuthorizationReducers";
import { toast } from "react-toastify"
import CircularProgress from '@mui/material/CircularProgress';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth < 1000 ? "95%" : 600,
    maxHeight: 700,
    bgcolor: 'background.paper',
    borderRadius: 3,
    boxShadow: 24,
    p: 2,
};

function ChangePasswordModal({ open, handleClose, password, SetReload }) {

    
    const [OldPassword, SetOldPassword] = useState(password);
    const [ConfirmPassword, SetConfirmPassword] = useState("");
    const [NewPassword, SetNewPassword] = useState("");

    const [loading, SetLoading] = React.useState(false)


    const handleSubmit = async () => {
        if (NewPassword === ConfirmPassword) {
            SetLoading(true)
            try {
                const res = await changePassword(OldPassword, ConfirmPassword, NewPassword)
                handleClose()
                SetReload(res)
                toast.success("la tua password è stata cambiata")
            } catch (error) {
                console.log(error)
                toast.error("Password Errata")
            }
            SetLoading(false)
        }
        else {
            toast.error("Le password non coincidono")
        }
    }




    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                disableScrollLock
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <div className="flex flex-col  relative gap-y-6" >

                            <button
                                onClick={handleClose}
                                className="absolute top-0 right-0 ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                            </button>
                            <div className="mt-8 flex flex-col">
                                <div className='flex flex-col text-left gap-y-2 mb-4'>
                                    <input type={"password"} onChange={(e) => SetOldPassword(e.target.value)} style={{ backgroundSize: "25", backgroundPosition: "98% center", backgroundRepeat: "no-repeat" }} placeholder="Vecchia Password" className="h-10 rounded-l-md rounded-r-md bg-transparent py-1 px-4 w-full border text-black" />
                                </div>
                                <div className='flex flex-col text-left gap-y-2 mb-2' >
                                    <input type={"password"} onChange={(e) => SetNewPassword(e.target.value)} style={{ backgroundSize: "25", backgroundPosition: "98% center", backgroundRepeat: "no-repeat" }} placeholder="Nuova Password" className="h-10 rounded-l-md rounded-r-md bg-transparent py-1 px-4 w-full border text-black" />
                                </div>
                                <div className='flex flex-col text-left gap-y-2 mb-2' >
                                    <input type={"password"} onChange={(e) => SetConfirmPassword(e.target.value)} style={{ backgroundSize: "25", backgroundPosition: "98% center", backgroundRepeat: "no-repeat" }} placeholder="Conferma Nuova Password" className="h-10 rounded-l-md rounded-r-md bg-transparent py-1 px-4 w-full border text-black" />
                                </div>
                                <button onClick={handleSubmit} className={`bg-primaryColor uppercase hover:bg-[#2E2D2B] ml-auto ${loading ? "px-28" : "px-20"}  text-bold py-2 rounded-l-md rounded-r-md text-white`}>
                                    {loading ? <CircularProgress size={22} color="inherit" /> : "Modifica"}
                                </button>


                            </div>

                        </div>
                    </Box>
                </Fade>

            </Modal>

        </>
    )
}

export default ChangePasswordModal
