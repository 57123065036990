import React, { lazy, Suspense } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { MyContext } from "../../../App"
const Herch01 = lazy(() => import('./Chinese-Theme-01/Hero'));
const Herch02 = lazy(() => import('./Chinese-Theme-02/Hero'));
const Herpz01 = lazy(() => import('./Pizza-Theme-01/Hero'));
const Herpz02 = lazy(() => import('./Pizza-Theme-02/Hero'));
const Herit01 = lazy(() => import('./Italian-Theme-01/Hero'));
const Herit02 = lazy(() => import('./Italian-Theme-02/Hero'));
const Herit03 = lazy(() => import('./Italian-Theme-03/Hero'));
const Herar01 = lazy(() => import('./Arabian-Theme-01/Hero'));
const Herpb01 = lazy(() => import('./Pub-Theme-01/Hero'));
const Hersw01 = lazy(() => import('./Sweet-Theme-01/Hero'));
const Hercf01 = lazy(() => import('./Coffee-Theme-01/Hero'));
const Heram01 = lazy(() => import("./American-Theme-01/hero"))
const Herfs02 = lazy(() => import("./Fish-Theme-02/hero"))

const Hero = ({ theme, handleOpen }) => {
  const { RestaurantInfo } = React.useContext(MyContext)
  return (
    <>
      {/* to prevent the below parts to go behind the navbar */}
      <div className={`${!RestaurantInfo?.theme_photos?.home?.hero?.isVisible && " mb-16 mt-12 sm:mb-12 xsm:mb-12 md:mb-12 xmd:mb-12 xsm:mt-0 sm:mt-0 md:mt-0"}`}>
        {RestaurantInfo?.theme_photos?.home?.hero?.isVisible &&

          <Suspense fallback={<Skeleton variant="rectangular" width={"100%"} className="mt-[5%] " height={600} animation="wave" />}>

            {theme === "Chinese-01" ?

              <Herch01 handleOpen={handleOpen} />
              :
              null

            }

            {theme === "Chinese-02" ?

              <Herch02 handleOpen={handleOpen} />
              :
              null

            }

            {theme === "Pizza-01" ?

              <Herpz01 handleOpen={handleOpen} />
              :
              null

            }

            {theme === "Pizza-02" ?

              <Herpz02 handleOpen={handleOpen} />
              :
              null

            }

            {theme === "Italian-01" ?

              <Herit01 handleOpen={handleOpen} />
              :
              null

            }

            {theme === "Italian-02" ?
              <Herit02 handleOpen={handleOpen} />
              :
              null
            }

            {theme === "Italian-03" ?
              <Herit03 handleOpen={handleOpen} />
              :
              null
            }

            {theme === "Arabian-01" ?
              <Herar01 handleOpen={handleOpen} />
              :
              null
            }

            {theme === "Pub-01" ?
              <Herpb01 handleOpen={handleOpen} />
              :
              null
            }

            {theme === "Sweet-01" ?
              <Hersw01 handleOpen={handleOpen} />
              :
              null
            }

            {theme === "Coffee-01" ?
              <Hercf01 handleOpen={handleOpen} />
              :
              null
            }

            {theme === "American-01" ?
              <Heram01 handleOpen={handleOpen} />
              :
              null
            }

            {theme === "Fish-02" ?
              <Herfs02 handleOpen={handleOpen} />
              :
              null
            }

          </Suspense>

        }
      </div>

    </>

  );
}

export default Hero;