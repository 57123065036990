import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import React, { createContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { io } from "socket.io-client";
import "./App.css";
import {
  PatchRestaurantInfoAPI,
  fetchCatalogsApi,
  fetchCategoriesApi,
  getDeliverySettingsAPI,
  getDiscounts,
  getPhotosAPI,
  getReservationSettingsAPI,
  getRestaurantInfoAPI,
  getSectionsAPI,
  getThemeID_API,
  getWebsiteInfoAPI,
  getWorkingHoursAPI,
  handleDynamicStyling,
} from "./AppJsFunctions";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import Ricetta from "./Components/Navbar/Ricetta";
import useWindowSize from "./Components/Reusable/WindowSize";
import MyCookieBanner from "./Reusable/CookieBar/MyCookieBanner";
import Routing from "./Routes";
import { patchCartStatus } from "./Util/Api/CustomerAPI";
import { getRestaurantInfo } from "./Util/Api/PrivacyPolicyApi";
import { getReviewsAPI } from "./Util/Api/ReviewApi";
import { useAuthDispatch } from "./Util/Context";
import { logout } from "./Util/Redux/actions/AuthAction";
import reducers from "./Util/Redux/reducers/CombinedReducers.js";
import { withLDProvider } from 'launchdarkly-react-client-sdk';

export const MyContext = createContext();
const store = createStore(reducers, compose(applyMiddleware(thunk)));

function App() {
  const dispatch = useAuthDispatch();
  const screenWidth = useWindowSize();
  const { pathname } = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const dashToken = searchParams.get("token");
  const dashSessionToken = sessionStorage.getItem("dashToken");
  const [websiteState, setWebsiteState] = useState(true);
  const [cart, SetCart] = useState([]);
  const [totalPrice, SetTotal] = useState(0);
  const [deliveryTime, setDeliveryTime] = useState(null);
  const [isForDelivery, setIsForDelivery] = React.useState(true);
  const [shippingLabel, setShippingLabel] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [RestaurantInfo, SetRestaurantInfo] = React.useState({});
  const [SocialsInfo, SetSocialsInfo] = React.useState({});
  const [Catalogs, setCatalogs] = useState([]);
  const [items, SetItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [Loading, SetLoading] = React.useState(true);
  const [products, setProducts] = useState([]);
  const [Sections, SetSections] = React.useState([]);
  const [Images, SetImages] = React.useState([]);
  const [reviews, SetReviews] = React.useState([]);
  const [themeID, SetThemeID] = React.useState("");
  const [DeliverySettings, SetDeliverySettings] = React.useState(0);
  const [ReservationSettings, SetReservationSettings] = React.useState();
  const [ItemsForDelivery, SetItemsForDeliver] = React.useState([]);
  const [ProductsForDelivery, SetProductsForDelivery] = React.useState([]);
  const [CategoriesForDelivery, SetCategoriesForDelivery] = React.useState([]);
  const [TokenFromDash, SetTokenFromDash] = React.useState(null);
  const [snackBarState, setSnackBarState] = React.useState({
    state: false,
    msg: "Questa funzione non è disponibile nella modalità modifica del tema",
    type: "info",
  });
  const [WorkingHours, SetWorkingHours] = React.useState({});
  const [isTemporarilyClose, SetClosed] = React.useState(false);
  const [finalShippingCost, setFinalShippingCost] = useState(0);
  const [isOfferClicked, setIsOfferClicked] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const [isChoosen, SetChoosen] = useState("Tutti i Prodotti");
  const [buttonDisplayed, setButtonDisplayed] = useState("");
  const [navigationSection, setNavigationSection] = useState("");
  const [socketBooking, setSocketBooking] = useState({});
  const [deliveryOrNone, setDeliveryOrNone] = useState({
    button: null,
    navigation: null,
  });
  const [reservationOrMenu, setReservationOrMenu] = useState({
    button: null,
    navigation: null,
  });
  const [chineseHeroButtons, setChineseHeroButtons] = useState({
    button: null,
    navigation: null,
  });

  const navfotlist = {
    nav: themeID,
    footer: themeID,
  };

  // const goToReservation = () => {
  //     const reservation=Sections.find(section=>(section["sectionName"]==="Reservation"))
  //     const delivery=Sections.find(section=>(section["sectionName"]==="Delivery"))
  //     const menu=Sections.find(section=>(section["sectionName"]==="Menu"))
  //     if(reservation?.isActive)
  //      CustomizingTheWebsite ? navigate("/customize-theme/prenotazioni") : navigate('/prenotazioni');
  //     if(!reservation?.isActive && delivery?.isActive)
  //      CustomizingTheWebsite ? navigate("/customize-theme/delivery") : navigate('/delivery');
  //      if(!reservation?.isActive && !delivery?.isActive && menu?.isActive)
  //      CustomizingTheWebsite ? navigate("/customize-theme/menu") : navigate('/menu');
  //   };
  const handleSectionNavigation = () => {
    // SetSections(Sections.map(section=>section["sectionName"]==="Reservation"?{...section,isActive:false}:section))
    const reservation = Sections.find(
      (section) => section["sectionName"] === "Reservation"
    );
    const delivery = Sections.find(
      (section) => section["sectionName"] === "Delivery"
    );
    const menu = Sections.find((section) => section["sectionName"] === "Menu");
    if (reservation?.isActive) {
      CustomizingTheWebsite
        ? setNavigationSection("/customize-theme/prenotazioni")
        : setNavigationSection("/prenotazioni");
      setButtonDisplayed("Prenota Ora");
    }
    if (!reservation?.isActive && delivery?.isActive) {
      CustomizingTheWebsite
        ? setNavigationSection("/customize-theme/delivery")
        : setNavigationSection("/delivery");
      setButtonDisplayed("Ordina Adesso");
    }
    if (!reservation?.isActive && !delivery?.isActive && menu?.isActive) {
      CustomizingTheWebsite
        ? setNavigationSection("/customize-theme/menu")
        : setNavigationSection("/menu");
      setButtonDisplayed("Vedi il nostro Menu");
    }
  };
  const handleDeliveryOrNone = (Sections) => {
    const delivery = Sections?.find(
      (section) => section["sectionName"] === "Delivery"
    );
    if (!delivery?.isActive) {
      setDeliveryOrNone({ button: null, navigation: null });
      return;
    }
    setDeliveryOrNone({ button: "Ordina Ora", navigation: "/delivery" });
  };
  const handleReservationOrMenu = (Sections) => {
    const reservation = Sections?.find(
      (section) => section["sectionName"] === "Reservation"
    );
    if (!reservation?.isActive) {
      return setReservationOrMenu({
        button: "Vedi il Menu",
        navigation: "/menu",
      });
    }
    return setReservationOrMenu({
      button: "Prenota Ora",
      navigation: "/reservation",
    });
  };
  const handleChineseHero = (Sections) => {
    const reservation = Sections?.find(
      (section) => section["sectionName"] === "Reservation"
    );
    const delivery = Sections?.find(
      (section) => section["sectionName"] === "Delivery"
    );
    if (!reservation?.isActive && !delivery.isActive) {
      setChineseHeroButtons({ button: null, navigation: null });
      return;
    }
    if (reservation.isActive)
      setChineseHeroButtons({
        button: "Prenota il tavolo",
        navigation: "/prenotazioni",
      });
    if (!reservation.isActive && delivery.isActive)
      setChineseHeroButtons({ button: "Ordina Ora", navigation: "/delivery" });
  };

  async function GETsectionSettings() {
    try {
      const res = await getSectionsAPI();
      SetSections(res.data);
      // handleReservationOrMenu(res.data);
      // handleDeliveryOrNone(res.data);
      // uncomment the line below to test section navigation
      // SetSections(res.data.map(section=>section["sectionName"]==="Reservation"||section["sectionName"]==="Delivery"?{...section,isActive:false}:section))
      // console.log("data",res.data.map(section=>section["sectionName"]==="Reservation"?{...section,isActive:false}:section))
    } catch (error) { }
  }
  async function GETworkingHours() {
    try {
      const res = await getWorkingHoursAPI();
      const WeekDays = [0, 1, 2, 3, 4, 5, 6];
      const VacationDays = WeekDays.filter(
        (DayNumber) => res.data[DayNumber] === undefined
      );
      VacationDays.length !== 0 &&
        VacationDays.map((item) => (res.data[item] = []));
      SetWorkingHours(res.data);
    } catch (error) {
      console.log(error);
      if (error.response.status === 423) SetClosed(true);
    }
  }

  async function GETrestaurantInfo() {
    try {
      const res = await getRestaurantInfoAPI();
      const planList = res?.data?.plan || [];
      const hasBase = planList.includes("base");
      const hasDelivery = planList.includes("delivery");
      const hasReservation = planList.includes("reservation");
      if (hasBase && !hasDelivery && hasReservation) {
        // Perform actions for Plan 2 and reservation
        localStorage.setItem("currentPlan", "nodelivery");
      } else if (hasBase && hasDelivery && !hasReservation) {
        // Perform actions for Plan 1 and 2
        localStorage.setItem("currentPlan", "nobooking");
      } else if (hasBase && !hasDelivery && !hasReservation) {
        // Perform actions for Plan 1, 2, and 3
        localStorage.setItem("currentPlan", "none");
      } else {
        // Handle other cases or no matching plans
        localStorage.removeItem("currentPlan");
      }
      SetRestaurantInfo(res?.data);
    } catch (error) {
      // just for now until we merge
      console.log(error);
    }
  }

  async function GETthemeID() {
    try {
      const res = await getThemeID_API();
      handleDynamicStyling(res.data);
      SetThemeID(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function GETwebsiteSocialInfo() {
    try {
      const res = await getWebsiteInfoAPI();
      SetSocialsInfo(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function GETrestaurantPhotos() {
    try {
      const res = await getPhotosAPI();
      SetImages(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function GETDeliverySettings() {
    try {
      const res = await getDeliverySettingsAPI();
      SetDeliverySettings(res?.data[0]);
      res?.data[0].shippingFeatures === false && setIsForDelivery(false);
      res?.data[0].nohoursFeatures === false &&
        setDeliveryTime("Il prima possibile");
    } catch (error) {
      console.log(error);
    }
  }

  async function GETReservationSettings() {
    try {
      const res = await getReservationSettingsAPI();
      SetReservationSettings(res?.data[0]);
    } catch (error) {
      console.log(error);
    }
  }

  async function GETreviews() {
    try {
      const res = await getReviewsAPI();
      SetReviews(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  const GETcatalogs = async () => {
    try {
      const NoHiddenProducts = await fetchCatalogsApi();
      setCatalogs(NoHiddenProducts);
    } catch (error) {
      console.log(error);
    }
  };

  async function PatchNewInfo() {
    try {
      await PatchRestaurantInfoAPI({ RestaurantInfo, headersconfig });
      handleOpenSnackBar(
        "Le tue modifiche sono state salvate correttamente.",
        "success"
      );
    } catch (error) {
      handleOpenSnackBar("Qualcosa è andato storto", "error");
      console.log(error);
    }
  }

  const GETcatgeories = async () => {
    SetLoading(true);
    try {
      const Data = await fetchCategoriesApi();
      setCategories(Data.noEmptyCategoriesWithHiddenFalse);
      SetItems(Data.produstWithHiddenFalse);
      setProducts(Data.produstWithHiddenFalse);
      SetCategoriesForDelivery(
        Data.noEmptyCategoriesWithHiddenFalseAndNotForDelivery
      );
      SetItemsForDeliver(Data.produstWithHiddenFalseAndNotForDelivery);
      SetProductsForDelivery(Data.produstWithHiddenFalseAndNotForDelivery);
    } catch (error) {
      console.log(error);
    }
    SetLoading(false);
  };

  const getAlldiscounts = async () => {
    try {
      const Data = await getDiscounts();
      setDiscounts(Data.data);
    } catch (error) {
      console.log(error);
    }
  };

  function AddBundleToCart(products) {
    SetCart((prev) => prev.concat(products));
    SetTotal(
      (old) =>
        parseFloat(old) + parseFloat(products._id.price * products.quantity)
    );
  }

  /**
    
    AddToCart function adds the selected product to the cart and also allows updating the quantity and size of the product. 
    The function calculates the total price of the product by adding the price of the product size and the selected add-ons.
    If the product is already present in the cart, the function either updates the quantity of the existing product or creates a new cart item
    with the updated quantity and size. The function also updates the total price of the cart and saves the cart in the local storage.
    @param {Object} Product - The selected product object to be added to the cart.
    @param {number} quantity - The quantity of the selected product.
    @param {Object} size - The selected size object of the product.
    @param {Array} Add_ons - The selected add-ons array of the product.
    @param {boolean} fromCheckOut - A boolean value to determine if the function is called from the checkout page.
    @param {Object} ModalItem - The selected cart item object from the modal.
    */
  // ! you can contact me "***anytime***" for more elaboration -> botros aziz .. botrosazizbotros@gmail.com --> to be removed after finishing developing
  function AddToCart(
    Product,
    quantity = 1,
    size,
    Add_ons,
    fromCheckOut,
    ModalItem
  ) {
    const AddOnsPrice = Add_ons?.map((item) => item.price)?.reduce(
      (a, b) => a + b,
      0
    );
    const cartItem = {
      _id: {
        ...Product,
        price:
          (!size?.price ? Product.price : size?.price) +
          (AddOnsPrice ? parseFloat(AddOnsPrice) : 0),
        DesiredVariations: size ? size : {},
        DesiredOptions: Add_ons ? Add_ons : [],
      },
      originalPrice: parseFloat(size?.price),
      quantity: quantity ? quantity : 1,
      name:
        Product?.title +
        (size?.name ? " " + size.name : "") +
        (Add_ons ? " " + Add_ons?.map((item) => item.name) : ""),
    };
    // Check if the product already exists in the cart
    const filterd = cart.filter((item) => item.name === cartItem.name);
    const RestOfTheCart = cart.filter((item) => item.name !== cartItem.name);
    const index = cart.findIndex((item) => item.name === cartItem.name);
    // If the cart is empty, add the product to the cart
    if (cart.length < 1) {
      SetCart((oldCart) => oldCart.concat(cartItem));
      SetTotal(parseFloat(cartItem._id.price * cartItem.quantity));
      SaveToLocalStoarge(
        cart.concat(cartItem),
        parseFloat(cartItem._id.price * cartItem.quantity)
      );
    }
    // If the product already exists in the cart, update the quantity of the product
    else if (cart.length >= 1 && filterd.length !== 0) {
      if (!fromCheckOut) {
        // Calculate the new price of the cart after updating the quantity
        const oldPrice =
          parseFloat(totalPrice) -
          parseFloat(cart[index].quantity * cart[index]._id.price);
        const newPrice =
          parseFloat(oldPrice) +
          parseFloat((cart[index].quantity + quantity) * cartItem._id.price);
        SetCart([
          ...RestOfTheCart,
          {
            _id: cartItem._id,
            quantity: cart[index].quantity + quantity,
            name: cartItem.name,
          },
        ]);
        SetTotal(parseFloat(newPrice));
        SaveToLocalStoarge(
          [
            ...RestOfTheCart,
            {
              _id: cartItem._id,
              quantity: cart[index].quantity + quantity,
              name: cartItem.name,
            },
          ],
          parseFloat(newPrice)
        );
      } else {
        // Update the quantity of the existing cart item and update the total price of the cart
        const oldPrice =
          parseFloat(totalPrice) -
          parseFloat(cart[index].quantity * cart[index]._id.price);
        const newPrice =
          parseFloat(oldPrice) + parseFloat(quantity * cartItem._id.price);
        SetCart([
          ...RestOfTheCart,
          {
            ...cartItem,
            _id: cartItem._id,
            quantity: quantity,
            name: cartItem.name,
          },
        ]);
        SetTotal(parseFloat(newPrice));
        SaveToLocalStoarge(
          [
            ...RestOfTheCart,
            {
              ...cartItem,
              _id: cartItem._id,
              quantity: quantity,
              name: cartItem.name,
            },
          ],
          parseFloat(newPrice)
        );
      }
    } else if (cart.length >= 1 && filterd.length === 0) {
      if (fromCheckOut) {
        const oldPrice =
          parseFloat(totalPrice) -
          parseFloat(ModalItem.quantity * ModalItem._id.price);
        const newPrice =
          parseFloat(oldPrice) + parseFloat(quantity * cartItem._id.price);
        SetCart((oldCart) => oldCart.concat(cartItem));
        SetTotal(parseFloat(newPrice));
        SaveToLocalStoarge(cart.concat(cartItem), parseFloat(newPrice));
      } else {
        SetCart((oldCart) => oldCart.concat(cartItem));
        SetTotal(
          parseFloat(totalPrice) +
          parseFloat(cartItem._id.price * cartItem.quantity)
        );
        SaveToLocalStoarge(
          cart.concat(cartItem),
          parseFloat(totalPrice) +
          parseFloat(cartItem._id.price * cartItem.quantity)
        );
      }
    }
    localStorage.setItem("cart", JSON.stringify(cart));
    handleCartUpdate(cart?.length);
  }

  function SaveToLocalStoarge(cart, totalPrice) {
    const cartToBeSavedToLocalStoarge = { cart, totalPrice };
    if (pathname === "/delivery") {
      localStorage.setItem(
        "DeliveryCart",
        JSON.stringify(cartToBeSavedToLocalStoarge)
      );
    }
  }

  function handleRemove(name) {
    // Filter out the item with the specified name from the cart
    const oldItem = cart.filter((item) => item.name === name);
    const NewCart = cart.filter((item) => item.name !== name);
    // Set the new cart and calculate the new total price
    SetCart(NewCart);
    SetTotal(
      oldItem.map(
        (item) =>
          parseFloat(totalPrice) - parseFloat(item._id.price * item.quantity)
      )
    );
    // Save the new cart and total price to local storage
    SaveToLocalStoarge(
      NewCart,
      oldItem.map(
        (item) =>
          parseFloat(totalPrice) - parseFloat(item._id.price * item.quantity)
      )[0]
    );
    //updates cart session in the BE
    handleCartUpdate(NewCart?.length);
  }

  function handleCartUpdate(numberOfItems) {
    try {
      const user = JSON.parse(localStorage.getItem("currentCustomer"));
      const cartStatus = {
        numberOfItems,
        lastTimeUpdated: new Date().toISOString(),
      };

      if (user !== null) patchCartStatus(user.id, cartStatus);
    } catch (error) {
      console.error(error);
    }
  }

  const handleOpenSnackBar = (msg, type) => {
    setSnackBarState({ state: true, msg: msg, type: type });
  };

  const handleCloseSnackBar = () => {
    setSnackBarState((old) => ({ ...old, state: false }));
  };
  const navigate = useNavigate();
  const CustomizingTheWebsite =
    pathname.includes("customize-theme") && TokenFromDash;

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackBar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  /**
    
    React effect hook to handle dashboard tokens and session tokens.
    If dashToken is available, logs out the user and sets the token as TokenFromDash,
    saves it to sessionStorage, resets searchParams, log the user out if he is signed in and reloads the page.
    If dashSessionToken is available, sets the token as TokenFromDash.
    */

  React.useEffect(() => {
    if (dashToken) {
      SetTokenFromDash(dashToken);
      logout(dispatch);
      sessionStorage.setItem("dashToken", JSON.stringify(dashToken));
      setSearchParams("");
      window.location.reload();
    } else if (dashSessionToken) {
      SetTokenFromDash(dashSessionToken);
    }
  }, []);

  const headersconfig = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${TokenFromDash}`,
    },
  };

  const [cartModalState, setCartModalState] = React.useState(false);
  const handleOpeCartModal = () => setCartModalState(true);
  const handleCloseCartModal = () => setCartModalState(false);

  ///order and booking contexts/////////////////////////
  const socket = useRef();

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_URL);
  }, []);

  const createSocketOrder = (order) => {
    socket.current.emit("send-order", order);
    socket.current.emit("new-order-add",order)
  };

  const createSocketBooking = (booking, header) => {
    socket.current.emit("send-booking", booking);
    socket.current.emit("new-booking-add",booking)

  };

  const MainData = {
    AddBundleToCart,
    AddToCart,
    totalPrice,
    handleRemove,
    cart,
    SetCart,
    deliveryTime,
    setDeliveryTime,
    isForDelivery,
    setIsForDelivery,
    setShippingLabel,
    shippingLabel,
    setPaymentMethod,
    paymentMethod,
    RestaurantInfo,
    SocialsInfo,
    SetTotal,
    SetItems,
    products,
    items,
    Loading,
    categories,
    Catalogs,
    Sections,
    Images,
    reviews,
    themeID,
    DeliverySettings,
    ItemsForDelivery,
    ProductsForDelivery,
    CategoriesForDelivery,
    SetItemsForDeliver,
    ReservationSettings,
    handleOpenSnackBar,
    CustomizingTheWebsite,
    PatchNewInfo,
    SetRestaurantInfo,
    headersconfig,
    cartModalState,
    handleOpeCartModal,
    handleCloseCartModal,
    WorkingHours,
    isTemporarilyClose,
    finalShippingCost,
    setFinalShippingCost,
    discounts,
    setDiscounts,
    isOfferClicked,
    setIsOfferClicked,
    isChoosen,
    SetChoosen,
    buttonDisplayed,
    navigationSection,
    handleSectionNavigation,
    deliveryOrNone,
    reservationOrMenu,
    handleChineseHero,
    chineseHeroButtons,
    socketBooking,
    setSocketBooking,
    createSocketOrder,
    createSocketBooking,
  };

  React.useEffect(() => {
    localStorage.removeItem("DeliveryCart");
    GETrestaurantInfo();
    GETworkingHours();
    GETthemeID();
    GETsectionSettings();
    GETwebsiteSocialInfo();
    GETrestaurantPhotos();
    GETreviews();
    GETDeliverySettings();
    GETReservationSettings();
    GETcatalogs();
    GETcatgeories();
    getAlldiscounts();
  }, []);

  /**
    
    useEffect hook to handle the loading of the cart on specific page paths.
    If the user is not logged in and attempts to access the "/customize-theme" path, they will be redirected to the home page.
    Dependencies: cart, pathname
    */

  React.useEffect(() => {
    if (
      pathname.includes("/customize-theme") &&
      !dashToken &&
      !dashSessionToken
    ) {
      navigate("/");
    }

    //    If a delivery cart exists in localStorage and the current cart is empty and the current page path is not "/prenotazioni", the delivery cart will be loaded.
    //     this is here to make two versions of carts one for the delivery and one for the reservations

    const DeliveryCart = localStorage.getItem("DeliveryCart");
    if (DeliveryCart && cart.length === 0 && pathname !== "/prenotazioni") {
      const CART = JSON.parse(DeliveryCart);
      SetCart(CART.cart);
      SetTotal(CART.totalPrice);
    }
  }, [pathname]);
  useEffect(() => {
    handleReservationOrMenu(Sections);
    handleDeliveryOrNone(Sections);
    handleSectionNavigation();
    return () => { };
  }, [Sections]);

  // Firefox 1.0+
  const isFirefox = typeof InstallTrigger !== "undefined";

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    const fetchRestaurantInfo = async () => {
      const restaurantInfo = await getRestaurantInfo();
      setWebsiteState(restaurantInfo?.data?.website);
    };
    fetchRestaurantInfo();
  }, []);

  return (
    <>
      {websiteState ? (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <link rel="icon" href={RestaurantInfo?.theme_photos?.logo} />
            <title>{RestaurantInfo?.name}</title>
            <meta name="description" content={RestaurantInfo?.description} />
          </Helmet>
          <Provider store={store}>
            <MyContext.Provider value={{ ...MainData }}>
              {CustomizingTheWebsite && screenWidth > 830 && (
                <button
                  onClick={() =>
                    window.open(
                      window.location.href,
                      "",
                      "width=500, height=800"
                    )
                  }
                  className="bg-primarybtn rounded-md w-32 h-12 absolute top-10 right-10 z-50 text-white font-semibold hover:bg-primarybtnhover elevation-1 active:shadow-innerbig"
                >
                  Mobile
                </button>
              )}
              <Ricetta />
              <div
                id="outer-container"
                className={`App flex flex-col justify-between min-h-screen   ${isFirefox && "scroller"
                  }`}
              >
                <Navbar nav={navfotlist["nav"]} />
                <div id="page-wrap" className="h-full">
                  <Routing />
                </div>
                <Footer footer={navfotlist["footer"]} />
              </div>
              <link rel="stylesheet" type="text/css" href={"App.css"} />
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={snackBarState.state}
                autoHideDuration={4000}
                onClose={handleCloseSnackBar}
                key={"top right"}
                action={action}
              >
                <Alert
                  onClose={handleCloseSnackBar}
                  severity={snackBarState.type}
                  sx={{ width: "100%" }}
                >
                  {snackBarState?.msg}
                </Alert>
              </Snackbar>
              <ToastContainer theme="colored" />
            </MyContext.Provider>
          </Provider>
          <MyCookieBanner />
        </>
      ) : (
        <>
          <div id="errorContainer">
            <div id="errorContent" class="error-content">
              <div id="error404" class="error-404">
                <h1>Sito web non accessibile!</h1>
              </div>
              <h2 id="errorHeading">404 - PAGINA NON TROVATA</h2>
              <p id="errorText">
                La pagina che stai cercando potrebbe essere stata rimossa,
                cambiato il suo nome o essere temporaneamente non disponibile.
              </p>
              <a href="mailto:assistenza@restaurants.club" id="errorLink">
                Contattaci
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default withLDProvider({
  clientSideID: '64ec5f42b1173112ef5c7efa',
  context: {
    "kind": "user",
    "key": "dev_user",
    "name": "Developer user",
    "email": "development@aigot.com"
  }
})(App);
