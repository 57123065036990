import React, { useState, useEffect } from 'react';
import Account from "./Account/Account";
import OrderHistory from "./OrderHistory/OrderHistory";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Util/Redux/actions/AuthAction";
import { getCustomerById } from "../../Util/Redux/reducers/AuthorizationReducers";
import { useAuthDispatch } from "../../Util/Context";
import './Profile.css';
import BookingHistory from './BookingHistory/BookingHistory';
function Profile() {
  const [isActive, SetActive] = useState("1")
  const navigate = useNavigate();
  const dispatch = useAuthDispatch();
  const [userData, setUserData] = useState(null);
  const [Reload, SetReload] = React.useState("asd")
  const [Loading, SetLoading] = React.useState(false)
  const user = JSON.parse(localStorage.getItem('currentCustomer'));
  const currentPlan = localStorage.getItem("currentPlan");

  async function getCustomer() {
    SetLoading(true)
    try {
      const res = await getCustomerById(user._id)
      setUserData(res.data)
    } catch (error) {
      console.log(error)
    }
    SetLoading(false)
  }

  useEffect(() => {
    if (!user)
      navigate("/login");
    else {
      getCustomer()
    }
  }, [Reload]);

  function handleTabButtons(id) {
    SetActive(id)
  }
  function handleTabsChange() {
    if (isActive === "1") return <Account Loading={Loading} userData={userData} SetReload={SetReload} />
    if (isActive === "2") return <OrderHistory userData={userData} />
    if (isActive === "3") return <BookingHistory userData={userData} />
  }

  function logOut() {
    logout(dispatch);
    navigate('/');
  }

  return (
    <>
      <div className='flex flex-row items-center w-full text-center sm:flex-col xsm:flex-col md:flex-col xsm:h-fit sm:h-fit md:h-fit  h-screen '>



        <div className='flex flex-col w-1/5  h-full  pl-[3%] sm:pl-0 xsm:pl-0 md:pl-0 sm:justify-evenly xsm:justify-evenly md:justify-evenly sm:pt-[5%] xsm:pt-[5%] md:pt-[5%] pt-[10%] bg-[#FAF8FC] sm:flex-row xsm:flex-row md:flex-row sm:w-full md:w-full xsm:w-full' style={{ fontFamily: 'var(--secondary-font)' }}>
          <p className='font-bold text-4xl text-black text-left mb-12 sm:hidden xsm:hidden md:hidden'>Profilo</p>

          <button
            onClick={(e) => handleTabButtons(e.target.id)}
            className={`${isActive === "1" ? "text-primaryColor font-bold" : "text-[#2C2C2C] font-normal"} py-2 text-left text-xl sm:text-[12px] xsm:text-[12px] md:text-[12px] tracking-wide   sm:tracking-normal xsm:tracking-normal md:tracking-normal`}
            id="1">
            Account
          </button>
          

          {currentPlan === "nodelivery" || currentPlan === "none" ? (
        <></>
      ) : (
        <>
<button
            onClick={(e) => handleTabButtons(e.target.id)}
            className={`${isActive === "2" ? "text-primaryColor font-bold" : "text-[#2C2C2C] font-normal"} py-2  text-left text-xl sm:text-[12px] xsm:text-[12px] md:text-[12px] tracking-wide sm:tracking-normal xsm:tracking-normal md:tracking-normal`}
            id="2">
            Cronologia ordine
          </button>
        </>
      )}
          
      
      

       
          {currentPlan === "nobooking" || currentPlan === "none" ? (
        <></>
      ) : (
        <>
         <button
            onClick={(e) => handleTabButtons(e.target.id)}
            className={`${isActive === "3" ? "text-primaryColor font-bold" : "text-[#2C2C2C] font-normal"} py-2  text-left text-xl sm:text-[12px] xsm:text-[10px] md:text-[12px] tracking-wide sm:tracking-normal xsm:tracking-normal md:tracking-normal `}
            id="3">
            Cronologia delle <br />
            prenotazioni
          </button>
        </>
      )}
       
          <button
            onClick={() => logOut()}
            className={`${isActive === "4" ? "text-black border-b-4 border-[#00B27A] font-bold" : "text-[#2C2C2C] font-normal"} py-2 text-left text-xl sm:text-[12px] xsm:text-[12px] md:text-[12px] tracking-wide sm:tracking-normal xsm:tracking-normal md:tracking-normal`}
            id="4">
            Esci
          </button>
        </div>
        <div className='w-4/5 h-screen pt-[6%]' >
          {handleTabsChange()}
        </div>
      </div>
    </>
  )
}

export default Profile
