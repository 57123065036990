import React, { lazy, Suspense } from "react"

const Logit01 = lazy(() => import("./Italian-Theme-01/Login"))
const Logit02 = lazy(() => import("./Italian-Theme-02/Login"))
const Logit03 = lazy(() => import("./Italian-Theme-03/Login"))
const Logpz01 = lazy(() => import("./Pizza-Theme-01/Login"))
const Logpz02 = lazy(() => import("./Pizza-Theme-02/Login"))
const Logch01 = lazy(() => import("./Chinese-Theme-01/Login"))
const Logch02 = lazy(() => import("./Chinese-Theme-02/Login"))
const Logar02 = lazy(() => import("./Arabian-Theme-01/Login"))
const Logsw01 = lazy(() => import("./Sweet-Theme-01/Login"))
const Logcf01 = lazy(() => import("./Coffee-Theme-01/Login"))

{/*
const Logpub01 = lazy(() => import("./Pub-Theme-01/Login"))
*/}

const Log = ({ login, handleOpen }) => {
  return (
    <div>
      <Suspense fallback={<div>Pagina in caricamento...</div>}>
        {login === "Italian-01" ? <Logit01 handleOpen={handleOpen} /> : null}
        {login === "Italian-02" ? <Logit02 handleOpen={handleOpen} /> : null}
        {login === "Italian-03" ? <Logit03 handleOpen={handleOpen} /> : null}
        {login === "Pizza-01" ? <Logpz01 handleOpen={handleOpen} /> : null}
        {login === "Pizza-02" ? <Logpz02 handleOpen={handleOpen} /> : null}
        {login === "Chinese-01" ? <Logch01 handleOpen={handleOpen} /> : null}
        {login === "Chinese-02" ? <Logch02 handleOpen={handleOpen} /> : null}
        {login === "Arabian-01" ? <Logar02 handleOpen={handleOpen} /> : null}
        {login === "Sweet-01" ? <Logsw01 handleOpen={handleOpen} /> : null}
        {login === "Coffee-01" ? <Logcf01 handleOpen={handleOpen} /> : null}
        {login === "Pub-01" ? <Logit01 handleOpen={handleOpen} /> : null}
      </Suspense>
    </div>
  )
}

export default Log
