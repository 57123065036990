import American01 from "../../Assets/Image/HeroImages/American/American-01.webp"
import American02 from "../../Assets/Image/HeroImages/American/American-02.webp"
import American03 from "../../Assets/Image/HeroImages/American/American-03.webp"
import American04 from "../../Assets/Image/HeroImages/American/American-04.webp"
import American05 from "../../Assets/Image/HeroImages/American/American-05.webp"
import American06 from "../../Assets/Image/HeroImages/American/American-06.webp"
import American07 from "../../Assets/Image/HeroImages/American/American-07.webp"
import American08 from "../../Assets/Image/HeroImages/American/American-08.webp"
import American09 from "../../Assets/Image/HeroImages/American/American-09.webp"
import American10 from "../../Assets/Image/HeroImages/American/American-10.webp"

import Argentino01 from "../../Assets/Image/HeroImages/Argentino/Argentino-01.webp"
import Argentino02 from "../../Assets/Image/HeroImages/Argentino/Argentino-02.webp"
import Argentino03 from "../../Assets/Image/HeroImages/Argentino/Argentino-03.webp"
import Argentino04 from "../../Assets/Image/HeroImages/Argentino/Argentino-04.webp"
import Argentino05 from "../../Assets/Image/HeroImages/Argentino/Argentino-05.webp"
import Argentino06 from "../../Assets/Image/HeroImages/Argentino/Argentino-06.webp"
import Argentino07 from "../../Assets/Image/HeroImages/Argentino/Argentino-07.webp"
import Argentino08 from "../../Assets/Image/HeroImages/Argentino/Argentino-08.webp"
import Argentino09 from "../../Assets/Image/HeroImages/Argentino/Argentino-09.webp"
import Argentino010 from "../../Assets/Image/HeroImages/Argentino/Argentino-10.webp"

import Sweets01 from "../../Assets/Image/HeroImages/Sweets/Sweets-01.webp"
import Sweets02 from "../../Assets/Image/HeroImages/Sweets/Sweets-02.webp"
import Sweets03 from "../../Assets/Image/HeroImages/Sweets/Sweets-03.webp"
import Sweets04 from "../../Assets/Image/HeroImages/Sweets/Sweets-04.webp"
import Sweets05 from "../../Assets/Image/HeroImages/Sweets/Sweets-05.webp"
import Sweets06 from "../../Assets/Image/HeroImages/Sweets/Sweets-06.webp"
import Sweets07 from "../../Assets/Image/HeroImages/Sweets/Sweets-07.webp"
import Sweets08 from "../../Assets/Image/HeroImages/Sweets/Sweets-08.webp"
import Sweets09 from "../../Assets/Image/HeroImages/Sweets/Sweets-09.webp"
import Sweets10 from "../../Assets/Image/HeroImages/Sweets/Sweets-10.webp"

import Greek01 from "../../Assets/Image/HeroImages/Greek/Greek-01.webp"
import Greek02 from "../../Assets/Image/HeroImages/Greek/Greek-02.webp"
import Greek03 from "../../Assets/Image/HeroImages/Greek/Greek-03.webp"
import Greek04 from "../../Assets/Image/HeroImages/Greek/Greek-04.webp"
import Greek05 from "../../Assets/Image/HeroImages/Greek/Greek-05.webp"
import Greek06 from "../../Assets/Image/HeroImages/Greek/Greek-06.webp"
import Greek07 from "../../Assets/Image/HeroImages/Greek/Greek-07.webp"
import Greek08 from "../../Assets/Image/HeroImages/Greek/Greek-08.webp"
import Greek09 from "../../Assets/Image/HeroImages/Greek/Greek-09.webp"
import Greek010 from "../../Assets/Image/HeroImages/Greek/Greek-10.webp"

import Mexican01 from "../../Assets/Image/HeroImages/Mexican/Mexican-01.webp"
import Mexican02 from "../../Assets/Image/HeroImages/Mexican/Mexican-02.webp"
import Mexican03 from "../../Assets/Image/HeroImages/Mexican/Mexican-03.webp"
import Mexican04 from "../../Assets/Image/HeroImages/Mexican/Mexican-04.webp"
import Mexican05 from "../../Assets/Image/HeroImages/Mexican/Mexican-05.webp"
import Mexican06 from "../../Assets/Image/HeroImages/Mexican/Mexican-06.webp"
import Mexican07 from "../../Assets/Image/HeroImages/Mexican/Mexican-07.webp"
import Mexican08 from "../../Assets/Image/HeroImages/Mexican/Mexican-08.webp"
import Mexican09 from "../../Assets/Image/HeroImages/Mexican/Mexican-09.webp"
import Mexican10 from "../../Assets/Image/HeroImages/Mexican/Mexican-10.webp"

import Italian01 from "../../Assets/Image/HeroImages/Italian/Italian-01.webp"
import Italian02 from "../../Assets/Image/HeroImages/Italian/Italian-02.webp"
import Italian03 from "../../Assets/Image/HeroImages/Italian/Italian-03.webp"
import Italian04 from "../../Assets/Image/HeroImages/Italian/Italian-04.webp"
import Italian05 from "../../Assets/Image/HeroImages/Italian/Italian-05.webp"
import Italian06 from "../../Assets/Image/HeroImages/Italian/Italian-06.webp"
import Italian07 from "../../Assets/Image/HeroImages/Italian/Italian-07.webp"
import Italian08 from "../../Assets/Image/HeroImages/Italian/Italian-08.webp"
import Italian09 from "../../Assets/Image/HeroImages/Italian/Italian-09.webp"
import Italian10 from "../../Assets/Image/HeroImages/Italian/Italian-10.webp"

import Pizza01 from "../../Assets/Image/HeroImages/Pizza/Pizza-01.webp"
import Pizza02 from "../../Assets/Image/HeroImages/Pizza/Pizza-02.webp"
import Pizza03 from "../../Assets/Image/HeroImages/Pizza/Pizza-03.webp"
import Pizza04 from "../../Assets/Image/HeroImages/Pizza/Pizza-04.webp"
import Pizza05 from "../../Assets/Image/HeroImages/Pizza/Pizza-05.webp"
import Pizza06 from "../../Assets/Image/HeroImages/Pizza/Pizza-06.webp"
import Pizza07 from "../../Assets/Image/HeroImages/Pizza/Pizza-07.webp"
import Pizza08 from "../../Assets/Image/HeroImages/Pizza/Pizza-08.webp"
import Pizza09 from "../../Assets/Image/HeroImages/Pizza/Pizza-09.webp"
import Pizza10 from "../../Assets/Image/HeroImages/Pizza/Pizza-10.webp"

import Sushi01 from "../../Assets/Image/HeroImages/Sushi/Sushi-01.webp"
import Sushi02 from "../../Assets/Image/HeroImages/Sushi/Sushi-02.webp"
import Sushi03 from "../../Assets/Image/HeroImages/Sushi/Sushi-03.webp"
import Sushi04 from "../../Assets/Image/HeroImages/Sushi/Sushi-04.webp"
import Sushi05 from "../../Assets/Image/HeroImages/Sushi/Sushi-05.webp"
import Sushi06 from "../../Assets/Image/HeroImages/Sushi/Sushi-06.webp"
import Sushi07 from "../../Assets/Image/HeroImages/Sushi/Sushi-07.webp"
import Sushi08 from "../../Assets/Image/HeroImages/Sushi/Sushi-08.webp"
import Sushi09 from "../../Assets/Image/HeroImages/Sushi/Sushi-09.webp"
import Sushi10 from "../../Assets/Image/HeroImages/Sushi/Sushi-10.webp"

import Fish01 from "../../Assets/Image/HeroImages/Fish/Fish-01.webp"
import Fish02 from "../../Assets/Image/HeroImages/Fish/Fish-02.webp"
import Fish03 from "../../Assets/Image/HeroImages/Fish/Fish-03.webp"
import Fish04 from "../../Assets/Image/HeroImages/Fish/Fish-04.webp"
import Fish05 from "../../Assets/Image/HeroImages/Fish/Fish-05.webp"
import Fish06 from "../../Assets/Image/HeroImages/Fish/Fish-06.webp"
import Fish07 from "../../Assets/Image/HeroImages/Fish/Fish-07.webp"
import Fish08 from "../../Assets/Image/HeroImages/Fish/Fish-08.webp"
import Fish09 from "../../Assets/Image/HeroImages/Fish/Fish-09.webp"
import Fish10 from "../../Assets/Image/HeroImages/Fish/Fish-10.webp"

import Arabian01 from "../../Assets/Image/HeroImages/Arabian/Arabian-01.webp"
import Arabian02 from "../../Assets/Image/HeroImages/Arabian/Arabian-02.webp"
import Arabian03 from "../../Assets/Image/HeroImages/Arabian/Arabian-03.webp"
import Arabian04 from "../../Assets/Image/HeroImages/Arabian/Arabian-04.webp"
import Arabian05 from "../../Assets/Image/HeroImages/Arabian/Arabian-05.webp"
import Arabian06 from "../../Assets/Image/HeroImages/Arabian/Arabian-06.webp"
import Arabian07 from "../../Assets/Image/HeroImages/Arabian/Arabian-07.webp"
import Arabian08 from "../../Assets/Image/HeroImages/Arabian/Arabian-08.webp"
import Arabian09 from "../../Assets/Image/HeroImages/Arabian/Arabian-09.webp"
import Arabian10 from "../../Assets/Image/HeroImages/Arabian/Arabian-10.webp"

import Cocktail01 from "../../Assets/Image/HeroImages/Cocktail/Cocktail-01.png"
import { alpha, styled } from '@mui/material/styles';
import { Switch } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';

export const AmericanImages = [

    American01,
    American02,
    American03,
    American04,
    American05,
    American06,
    American07,
    American08,
    American09,
    American10,

]

export const ArgentinoImages = [

    Argentino01,
    Argentino02,
    Argentino03,
    Argentino04,
    Argentino05,
    Argentino06,
    Argentino07,
    Argentino08,
    Argentino09,
    Argentino010,

]

export const SweetsImages = [

    Sweets01,
    Sweets02,
    Sweets03,
    Sweets04,
    Sweets05,
    Sweets06,
    Sweets07,
    Sweets08,
    Sweets09,
    Sweets10,

]

export const Greek = [

    Greek01,
    Greek02,
    Greek03,
    Greek04,
    Greek05,
    Greek06,
    Greek07,
    Greek08,
    Greek09,
    Greek010,

]

export const Mexican = [

    Mexican01,
    Mexican02,
    Mexican03,
    Mexican04,
    Mexican05,
    Mexican06,
    Mexican07,
    Mexican08,
    Mexican09,
    Mexican10,

]

export const Italian = [

    Italian01,
    Italian02,
    Italian03,
    Italian04,
    Italian05,
    Italian06,
    Italian07,
    Italian08,
    Italian09,
    Italian10,

]

export const Pizza = [

    Pizza01,
    Pizza02,
    Pizza03,
    Pizza04,
    Pizza05,
    Pizza06,
    Pizza07,
    Pizza08,
    Pizza09,
    Pizza10,

]

export const Sushi = [

    Sushi01,
    Sushi02,
    Sushi03,
    Sushi04,
    Sushi05,
    Sushi06,
    Sushi07,
    Sushi08,
    Sushi09,
    Sushi10,

]

export const Fish = [

    Fish01,
    Fish02,
    Fish03,
    Fish04,
    Fish05,
    Fish06,
    Fish07,
    Fish08,
    Fish09,
    Fish10,

]

export const Arabian = [

    Arabian01,
    Arabian02,
    Arabian03,
    Arabian04,
    Arabian05,
    Arabian06,
    Arabian07,
    Arabian08,
    Arabian09,
    Arabian10,

]

export const Cocktail = [

    Cocktail01,

]


export const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));


export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderTop: '16px solid #00b27A',
    boxShadow: 24,
    p: 4,
    borderRadius: 8,
    zIndex: 1000,
    outline: "none"
};


export function RemoveFromLocalStorage() {
    localStorage.removeItem("CatalogID")
}


export const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#00B27A",
        '&:hover': {
            backgroundColor: alpha("#00B27A", theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#00B27A",
    },
}));