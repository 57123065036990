import React, { useEffect, useState } from 'react'
import Package02 from "../../../Assets/Image/Login.png";
import CircularProgress from '@mui/material/CircularProgress';
import { MyContext } from '../../../App';
import ScrollToTop from '../../../Reusable/ScrollToTop';
import { toast } from "react-toastify"
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RecoveryPasswordApi } from '../../../Util/Redux/actions/AuthAction';

function RecoveryPassword() {
    const { RestaurantInfo } = React.useContext(MyContext)
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [RecoveryTokenState, SetRecoveryToken] = React.useState('')
    const [NewPassword, SetNewPassword] = useState("");
    const [ConfirmPassword, SetConfirmPassword] = useState("");
    const [isFinished, SetFinish] = React.useState(false)
    // let [searchParams, setSearchParams] = useSearchParams();
    const {token} = useParams()
    console.log(token,"token")
    // React.useEffect(() => {
    //     if (RecoveryToken) {
    //         SetRecoveryToken(dashToken)
    //         // setSearchParams('')
    //     } else {
    //         navigate("/login")
    //     }
    // }, [])

    useEffect(()=>{
SetRecoveryToken(token)
    },[])



console.log(NewPassword,"NewPassword")
console.log(ConfirmPassword,"ConfirmPassword")    


    async function RecoverPassword(e) {
        e.preventDefault()
        if (NewPassword === ConfirmPassword) {
            setIsLoading(true)
            try {
                const res = await RecoveryPasswordApi(NewPassword, RecoveryTokenState)
                console.log(res)
                SetFinish(true)
            } catch (error) {
                toast.error("")
            }
            setIsLoading(false)
        }
        else {
            alert("Le password non coincidono")
        }
    }


    function HeroImage() {
        if (window.innerWidth < 1000) {
            return `${RestaurantInfo?.theme_photos?.login?.login_mobile_img || Package02}`
        } else {
            return `${RestaurantInfo?.theme_photos?.login?.login_img || Package02}`
        }
    }
    return (
        <div className={`w-full sm:w-full md:w-full xsm:w-full h-screen sm:h-fit   xsm:h-fit md:h-fit flex flex-row xsm:flex-col sm:flex-col md:flex-col justify-center items-center  xsm:pb-12 sm:pb-12 md:pb-12 `}>
            <ScrollToTop />
            <div className={`flex justify-center  items-center w-[50%] sm:w-full xsm:w-full md:w-full h-full sm:h-[300px] xsm:h-[300px] md:h-[400px]`}>
                <img src={HeroImage()} alt="" className='object-cover object-center w-full h-full' />
            </div>
            <div className='w-[50%] py-8 xmd:py-2 xmd:px-4 px-8 sm:px-2 xsm:px-2 md:px-4 sm:py-4 xsm:py-4 md:py-4 flex flex-col h-[95%] lg:h-[85%] my-auto xl:h-[70%] justify-evenly xsm:w-full sm:w-full md:w-full'>
                {!isFinished ?
                    <form
                        id="myform"
                        onSubmit={(e) => RecoverPassword(e)}
                        className='w-full flex flex-col items-center gap-y-6 my-8 ' >
                        <p className='text-primaryColor text-left mr-auto  Brevia850 text-5xl xmd:text-3xl sm:text-2xl xsm:text-2xl md:text-2xl sm:my-5 xsm:my-5 md:my-5'>Reset della Password</p>
                        <div className='flex flex-col  w-[95%]'>
                            <p className='Brevia700 text-xl text-left'>Nuova Password</p>
                            <input
                                type="password"
                                required
                                value={NewPassword}
                                onChange={(e) => SetNewPassword(e.target.value)}
                                disabled={isLoading}
                                className='h-12 border-b-2 border-gray-300 px-1 text-lg pop500 focus:outline-none' />
                        </div>
                        <div className='flex flex-col  w-[95%]'>
                            <p className='Brevia700 text-xl text-left'>Conferma Nuova Password</p>
                            <input
                                type="password"
                                required
                                value={ConfirmPassword}
                                onChange={(e) => SetConfirmPassword(e.target.value)}
                                disabled={isLoading}
                                className='h-12 border-b-2 border-gray-300 px-1 text-lg pop500 focus:outline-none' />
                        </div>
                    </form>
                    :
                    <p className='text-primaryColor text-left mr-auto  Brevia850 text-5xl xmd:text-3xl sm:text-2xl xsm:text-2xl md:text-2xl sm:my-5 xsm:my-5 md:my-5'>
                        La tua password è stata correttamente modificata
                    </p>
                }
                <button
                    form="myform"
                    type="submit"
                    onClick={e => isFinished ? navigate("/login") : RecoverPassword(e)}
                    className='w-[100%] h-12  bg-primaryColor hover:bg-primaryColorHover items-center flex justify-center '>
                    {isLoading ? <CircularProgress color="inherit" /> :
                        isFinished ?
                            <p className='pop500 text-white'>Login</p>
                            :
                            <p className='pop500 text-white'>Accedi</p>
                    }
                </button>
            </div>
        </div >
    )
}

export default RecoveryPassword