import React, { lazy, Suspense } from 'react';

const Fotch01 = lazy(() => import('./Chinese-Theme-01/Footer'));
const Fotch02 = lazy(() => import('./Chinese-Theme-02/Footer'));
const Fotpz01 = lazy(() => import('./Pizza-Theme-01/Footer'));
const Fotpz02 = lazy(() => import('./Pizza-Theme-02/Footer'));
const Fotit01 = lazy(() => import('./Italian-Theme-01/Footer'));
const Fotit02 = lazy(() => import('./Italian-Theme-02/Footer'));
const Fotit03 = lazy(() => import('./Italian-Theme-03/Footer'));
const Fotar01 = lazy(() => import('./Arabian-Theme-01/Footer'));
const FotPub01 = lazy(() => import('./Pub-Theme-01/Footer'));
const FotSweet01 = lazy(() => import('./Sweet-Theme-01/Footer'));
const FotAm01 = lazy(() => import("./American-Theme-01/footer"))
const FotFi02 = lazy(() => import("./Fish-Theme-02/footer"))

const Footer = ({ footer }) => {

  return (
    <div>
      <Suspense fallback={<div>Pagina in caricamento...</div>}>
        {footer === "Chinese-01" ? <Fotch01 /> : null}
        {footer === "Chinese-02" ? <Fotch02 /> : null}
        {footer === "Pizza-01" ? <Fotpz01 /> : null}
        {footer === "Pizza-02" ? <Fotpz02 /> : null}
        {footer === "Italian-01" ? <Fotit01 /> : null}
        {footer === "Italian-02" ? <Fotit02 /> : null}
        {footer === "Italian-03" ? <Fotit03 /> : null}
        {footer === "Arabian-01" ? <Fotar01 /> : null}
        {footer === "Pub-01" ? <FotPub01 /> : null}
        {footer === "Sweet-01" ? <FotSweet01 /> : null}
        {footer === "American-01" ? <FotAm01 /> : null}
        {footer === "Fish-02" ? <FotFi02 /> : null}
      </Suspense>
    </div>
  );
}

export default Footer;