import React, { lazy, Suspense } from "react"

const Regit01 = lazy(() => import("./Italian-Theme-01/Register"))
const Regit02 = lazy(() => import("./Italian-Theme-02/Register"))
const Regit03 = lazy(() => import("./Italian-Theme-03/Register"))
const Regpz01 = lazy(() => import("./Pizza-Theme-01/Register"))
const Regpz02 = lazy(() => import("./Pizza-Theme-02/Register"))
const Regch01 = lazy(() => import("./Chinese-Theme-01/Register"))
const Regch02 = lazy(() => import("./Chinese-Theme-02/Register"))
const Regar01 = lazy(() => import("./Arabian-Theme-01/Register"))
const Regcf01 = lazy(() => import("./Coffee-Theme-01/Register"))

{/*
const Regpub01 = lazy(() => import("./Pub-Theme-01/Register"))
*/}

const Reg = ({ register, handleOpen }) => {
  return (
    <div>
      <Suspense fallback={<div>Pagina in caricamento...</div>}>
        {register === "Italian-01" ? <Regit01 handleOpen={handleOpen} /> : null}
        {register === "Italian-02" ? <Regit01 handleOpen={handleOpen} /> : null}
        {register === "Italian-03" ? <Regit03 handleOpen={handleOpen} /> : null}
        {register === "Pizza-01" ? <Regit01 handleOpen={handleOpen} /> : null}
        {register === "Pizza-02" ? <Regit01 handleOpen={handleOpen} /> : null}
        {register === "Chinese-01" ? <Regit01 handleOpen={handleOpen} /> : null}
        {register === "Chinese-02" ? <Regit01 handleOpen={handleOpen} /> : null}
        {register === "Arabian-01" ? <Regit01 handleOpen={handleOpen} /> : null}
        {register === "coffee-01" ? <Regit01 handleOpen={handleOpen} /> : null}
        {register === "pub-01" ? <Regit01 handleOpen={handleOpen} /> : null}
        {register === "Sweet-01" ? <Regit01 handleOpen={handleOpen} /> : null}
      </Suspense>
    </div>
  )
}

export default Reg
