import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import InformazioniModal from "./InformazioniModal";
import ChangePasswordModal from "./ChangePasswordModal";
import ChangeAddressModal from "./ChangeAddressModal";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai"
import { editAuthUser } from "../../../Util/Api/CustomerAPI";
import { toast } from 'react-toastify';
import Skeleton from '@mui/material/Skeleton';

function Account({ userData, SetReload, Loading }) {



    const handleCloseInfo = () => setOpenInfo(false);
    const [openInfo, setOpenInfo] = React.useState(false);
    const handleOpenInfo = () => setOpenInfo(true);

    const handleCloseChangePassword = () => setOpenChangePassword(false);
    const [openChangePassword, setOpenChangePassword] = React.useState(false);
    const handleOpenChangePassword = () => setOpenChangePassword(true);



    const [openChangeAddress, setOpenChangeAddress] = React.useState(false);

    function handleCloseChangeAddress() {
        SetAddress({})
        setOpenChangeAddress(false)
    };

    const [theAddressToBeEdited, SetAddress] = React.useState({})

    function handleOpenChangeAddress() {
        setOpenChangeAddress(true)
    }



    const handleDeleteAddress = async (itemm) => {
        const theRestOftheAddresses = userData?.shippingAddress?.filter((item) => item._id !== itemm._id)
        let TheCurrentAddressesWithOutID = theRestOftheAddresses?.map(item => ({
            recipientFullName: item.recipientFullName,
            telephone: item.telephone,
            city: item.city,
            CAP: item.CAP,
            firstLine: item.firstLine,
            secondLine: item.secondLine
        }));

        const theNewUser = {
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
        }
        try {
            const res = await editAuthUser(userData?._id, { ...theNewUser, shippingAddress: [...TheCurrentAddressesWithOutID] });
            SetReload(res)
        } catch (error) {
            toast.error()
            console.log(error)
        }
    }
    return (
        <>
            {userData && <InformazioniModal userData={userData} SetReload={SetReload} open={openInfo} handleClose={handleCloseInfo} />}
            {userData && <ChangePasswordModal SetReload={SetReload} open={openChangePassword} handleClose={handleCloseChangePassword} password={userData?.password} />}
            {theAddressToBeEdited && <ChangeAddressModal userData={userData} SetAddress={SetAddress} theAddressToBeEdited={theAddressToBeEdited} SetReload={SetReload} open={openChangeAddress} handleClose={handleCloseChangeAddress} />}

            <div className='px-20 sm:px-3 xsm:px-3 md:px-3 sm:h-fit xsm:h-fit md:h-fit'
           style={{ fontFamily: 'var(--primary-font)' }}>
                <div className="flex flex-col gap-y-8 mt-8 sm:h-fit xsm:h-fit md:h-fit">



                    <div className='grid grid-cols-2
                                        xsm:flex sm:flex xsm:flex-col  sm:flex-col
                                        xsm:w-full sm:w-full'>
                        <div className='bg-white h-fit relative flex flex-col p-5 sm:p-0 xsm:p-0 md:p-2 xsm:mr-0 sm:mr-0  gap-y-4 mr-5 mb-8 md:mr-0 '>

                            <div className="flex flex-row items-center gap-x-2 sm:w-full xsm:w-full" >
                                <p className='text-left  font-bold text-2xl sm:text-lg xsm:text-lg md:text-lg text-primaryColor'>
                                    Informazione del cliente
                                </p>
                                <button onClick={() => handleOpenInfo()} className=' text-primaryColor'>
                                    <EditIcon fontSize='medium' />
                                </button>

                            </div>

                            {Loading ?
                                <div className="grid grid-cols-2 gap-y-5 text-left mt-4">
                                    <div className='flex flex-col ' >
                                        <Skeleton width="50%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton width="50%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                    </div>
                                    <div className='flex flex-col ' >
                                        <Skeleton width="50%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton width="50%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                    </div>
                                    <div className='flex flex-col ' >
                                        <Skeleton width="50%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton width="50%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                    </div>
                                    <div className='flex flex-col ' >
                                        <Skeleton width="50%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton width="50%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                    </div>
                                    <div className='flex flex-col ' >
                                        <Skeleton width="50%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton width="50%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                    </div>
                                    <div className='flex flex-col ' >
                                        <Skeleton width="50%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton width="50%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                    </div>
                                </div>
                                :
                                <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-1 xsm:grid-cols-1 gap-y-5 text-left mt-4">
                                    <div className='flex flex-col text-left' >
                                        <p className='  text-xl text-black font-semibold'>Nome</p>
                                        <p className='font-normal  text-xl text-[#555555] capitalize'>{userData?.firstName} </p>
                                    </div>
                                    <div className='flex flex-col text-left' >
                                        <p className=' text-xl text-black font-semibold'>Cognome</p>
                                        <p className='font-normal  text-xl text-[#555555] capitalize'> {userData?.lastName}</p>
                                    </div>
                                    {userData?.phoneNumber &&
                                        <div className='flex flex-col text-left' >
                                            <p className='  text-xl text-black font-semibold'>Telefono</p>
                                            <p className='font-normal  text-xl text-[#555555] capitalize'>{userData?.phoneNumber}</p>
                                        </div>
                                    }
                                    <div className='flex flex-col text-left' >
                                        <p className=' text-xl text-black font-semibold'>Data di nascita</p>
                                        <p className='font-normal  text-xl text-[#555555] capitalize'>{new Date(userData?.dateofbirth).toLocaleDateString()}</p>
                                    </div>
                                </div>}
                        </div>


                        <div className='bg-white h-fit  flex flex-col p-5 sm:p-0 xsm:p-0 md:p-2  xsm:ml-0 sm:ml-0 gap-y-4 ml-5 md:ml-0 mb-8 sm:pl-0 xsm:pl-0 md:pl-0 '>
                            <p className='text-left   font-bold text-2xl text-primaryColor  sm:text-lg xsm:text-lg md:text-lg '>
                                E-mail e password
                            </p>
                            {Loading ?
                                <div className="flex flex-col gap-y-5 text-left mt-4">
                                    <div className='flex flex-col ' >
                                        <Skeleton width="50%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton width="50%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                    </div>
                                    <div className='flex flex-col ' >
                                        <Skeleton width="50%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton width="50%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                                    </div>
                                </div>
                                :
                                <div className="flex flex-col gap-y-5 text-left mt-4">
                                    <div className='flex flex-col text-left' >
                                        <p className='text-black font-semibold  text-xl'>Email</p>
                                        <p className='font-normal  text-xl text-[#555555]'>{userData?.email}</p>
                                    </div>
                                    <div className='flex flex-col text-left' >
                                        <div className="flex flex-row items-center gap-x-2" >
                                            <p className='text-black font-semibold  text-xl '>Password</p>
                                            
                                            
                                            <button onClick={() => handleOpenChangePassword()} className=' text-primaryColor'><EditIcon fontSize='medium' /></button>
                                            

                                        </div>
                                        <p className='font-normal  text-xl text-[#555555]'>******** </p>
                                    </div>
                                </div>}
                        </div>


                    </div>

                    <div className='bg-white h-fit w-full  flex flex-col p-4  sm:p-0 xsm:p-0 md:p-2 gap-y-2  mb-8'>

                        <div className="flex flex-row items-center gap-x-2" >
                            <p className=' font-bold text-2xl  sm:text-lg xsm:text-lg md:text-lg  text-primaryColor'>Indirizzi </p>
                            <button className=' text-primaryColor'>
                                <AiOutlinePlus size={25} onClick={() => handleOpenChangeAddress()} />
                            </button>
                        </div>

                        {Loading && <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem' }} />}
                        {Loading && <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem' }} />}
                        {Loading && <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem' }} />}
                        {userData?.shippingAddress?.map((item,index) =>
                        (
                            <div key={index} className="flex flex-row gap-x-2 items-center text-left px-3 sm:px-0 xsm:px-0 md:px-0">


                                <p className='font-normal  text-lg  sm:text-sm xsm:text-sm md:text-sm'>{item.recipientFullName},</p>
                                <p className='font-normal  text-lg sm:text-sm xsm:text-sm md:text-sm'>{item.firstLine},</p>
                                <p className='font-normal  text-lg sm:text-sm xsm:text-sm md:text-sm'>{item.secondLine},</p>
                                <p className='font-semibold  text-lg sm:text-sm xsm:text-sm md:text-sm'>{item.city},</p>
                                <p className='font-normal  text-lg sm:text-sm xsm:text-sm md:text-sm'>{item.CAP}</p>
                                <button className=' text-primaryColor'>
                                    <EditIcon fontSize='medium' onClick={() => {
                                        SetAddress(item)
                                        handleOpenChangeAddress()
                                    }} />
                                </button>
                                <button onClick={() => handleDeleteAddress(item)} className=' text-primaryColor'>
                                    <AiOutlineClose size={20} fontSize='medium' />
                                </button>

                            </div>
                        )
                        )}


                    </div>
                </div>
            </div>


        </>
    )
}

export default Account
