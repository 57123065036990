import { MyContext } from "../../App";
import "./Timepicker.css"
import { useState, useEffect, useContext } from "react";
import { eachMinuteOfInterval } from "date-fns";

function Step2({ notes, SetNotes, Step1FormUserData }) {
    const { setDeliveryTime, setShippingLabel, deliveryTime, DeliverySettings } = useContext(MyContext);
    const [EnteredTime, SetTime] = useState();

    const weekdayMap = {
        "martedì": "martedi",
        "mercoledì": "mercredi",
        "giovedì": "giovedi",
        "venerdì": "venerdi",
        "sabato": "sabato",
        "domenica": "domenica",
        "lunedì": "lunedi"
    };

    const fixBackendFromFrontend = (date) => weekdayMap[date];

    const date = new Date().toLocaleString('it-IT', { 'weekday': 'long' });
    const workingDays = DeliverySettings?.workingDays || [];
    const theDay = workingDays.find((item) => item.day.toLowerCase() === fixBackendFromFrontend(date) && item.isWorkingDay);

    const getStartAndEndTimes = (session) => {
        const startTime = new Date().setHours(session?.startTime?.hour, session?.startTime?.minute);
        const endTime = new Date().setHours(session?.endTime?.hour, session?.endTime?.minute);
        return [startTime, endTime];
    };

    const [MorningOpeningTimee, MorningClosingTimee] = getStartAndEndTimes(theDay?.sessions?.morningSession);
    const [AfternoonOpeningTime, AfternoonClosingTime] = getStartAndEndTimes(theDay?.sessions?.afternoonSession);

    const MorningSession = eachMinuteOfInterval({ start: MorningOpeningTimee, end: MorningClosingTimee }, { step: 15 });
    const AfteerNoonSession = eachMinuteOfInterval({ start: AfternoonOpeningTime, end: AfternoonClosingTime }, { step: 15 });

    const isNotPast = (item) => item.getHours() > new Date().getHours();
    const Stringfy = (item) => `${item.getHours()}:${item.getMinutes().toString().padStart(2, '0')}`;



    return (
        <div className='flex flex-col items-center text-left relative pb-12 mb-12 sm:mb-6 xsm:mb-6' >

            <p className='font-bold text-4xl sm:text-2xl xsm:text-xl text-black font-sans mb-6'>Scegli orario di <span className="text-primaryColor">consegna</span></p>

            <div className='flex flex-row w-[700px] h-[350px] xsm:h-fit sm:h-fit border rounded-lg xsm:w-[90%] sm:w-[90%] md:w-[65%] xsm:flex-col sm:flex-col md:flex-col'>

                <div className='flex flex-col gap-y-6 shadow-lg rounded-lg w-[280px] p-6 h-full  bg-[#FCF7EE] xsm:w-full sm:w-full md:w-full  '>
                    <button onClick={() => setDeliveryTime("Il prima possibile")} className={`${deliveryTime === "Il prima possibile" ? "bg-primaryColor text-white" : "bg-white text-primaryColor"} shadow text-md  h-fit mx-auto  text-semibold py-4 sm:text-py-2 xsm:py-2 sm:text-[14px] xsm:text-[14px] sm:w-[90%] xsm:w-[90%]  rounded-xl w-full `}>
                        Il prima possibile
                    </button>

                    {DeliverySettings?.nohoursFeatures &&
                        <>
                            <p className='font-thin text-md text-center'>Oppure <br /> digita un orario</p>

                            <select
                                onChange={(e) => {
                                    SetTime(e.target.value)
                                    setDeliveryTime(e.target.value)
                                }}
                                className={`${EnteredTime === deliveryTime ? "bg-primaryColor text-white" : "bg-white text-primaryColor"} shadow text-md  h-fit mx-auto items-center text-center justify-center flex text-semibold py-4 sm:text-py-2 xsm:py-2 sm:text-[14px] xsm:text-[14px] sm:w-[90%] xsm:w-[90%]  rounded-xl w-full focus:outline-none`}>

                                {MorningSession.map((item, index) => {

                                    return (
                                        isNotPast(item) &&
                                        <option key={index} value={Stringfy(item)}>{Stringfy(item)}</option>
                                    )
                                })}
                                {AfteerNoonSession?.map((item, index) => {

                                    return (
                                        isNotPast(item) &&
                                        <option key={index} value={Stringfy(item)}>{Stringfy(item)}</option>
                                    )
                                })}
                            </select>
                        </>}
                </div>
                <textarea
                    value={notes}
                    className='w-full h-full resize-none shadow-lg rounded-lg p-6 focus:outline-none '
                    onChange={(e) => SetNotes(e.target.value)}
                    placeholder='Aggiungi note alla consegna, ad es: “il citofono non funziona”. '
                />

            </div>

        </div>
    )
}

export default Step2