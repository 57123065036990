import { useState, useEffect } from "react";

function useWindowSize() {
    const [ScreenWidth, setScreenWidth] = useState();

    useEffect(() => {
        function handleResize() {
            setScreenWidth(window.innerWidth, );
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return ScreenWidth;
}
export default useWindowSize