import React from "react";
import ScrollToTop from "../../Reusable/ScrollToTop";
import Ricetta from "../Navbar/Ricetta";

function Step1({
  SetUserDataForm,
  Step1FormUserData,
  userData,
  isLoading,
  NeedsNewAddress,
  SetNewAddress,
  user,
  getCustomer
}) {
  function handleChange(e) {
    SetUserDataForm({ ...Step1FormUserData, [e.target.name]: e.target.value });
  }
  //  to fill the form automatically on load so if he after rendering just clicked continue his data is already saved on our variable
  React.useEffect(() => {
    if (userData?.shippingAddress?.length === 0) SetNewAddress(true);
    else
      SetUserDataForm({
        recipientFullName: userData?.shippingAddress[0]?.recipientFullName,
        telephone: userData?.shippingAddress[0]?.telephone,
        city: userData?.shippingAddress[0]?.city,
        CAP: userData?.shippingAddress[0]?.CAP,
        firstLine: userData?.shippingAddress[0]?.firstLine,
        secondLine: userData?.shippingAddress[0]?.secondLine,
      });
    if (user === null) {
      SetNewAddress(true)
    }
  }, [userData, SetUserDataForm]);

  function handleNewInfo(address) {
    SetUserDataForm({
      recipientFullName: address.recipientFullName,
      telephone: address.telephone,
      city: address.city,
      CAP: address.CAP,
      firstLine: address.firstLine,
      secondLine: address.secondLine,
    });
  }

  return (
    <>
      <div className="flex flex-col w-full items-center text-left relative mb-12 pb-12 sm:mb-3 xsm:mb-3 ">
        <ScrollToTop />
        <p style={{ fontFamily: 'var(--primary-font)' }} className="font-bold text-4xl  text-black mb-6 xsm:text-xl sm:text-xl md:text-xl">
          Scegli l’indirizzo di{" "}
          <span className="text-primaryColor">consegna</span>
        </p>
        {!isLoading && (
          <div className="flex flex-col gap-y-6 sm:w-[95%] mx-auto xsm:w-[95%] w-[900px] ">
            {userData && (
              <label className="flex flex-row items-center gap-x-2 w-full mx-auto">
                <input
                  defaultChecked={userData?.shippingAddress?.length !== 0}
                  disabled={userData?.shippingAddress?.length === 0}
                  onClick={() => {
                    SetNewAddress(false);
                    (
                      NeedsNewAddress
                        ? handleNewInfo(userData?.shippingAddress[0])
                        : null
                    );
                    getCustomer();
                  }}
                  type="radio"
                  className="w-6 h-6 sm:h-4 xsm:h-4 sm:w-4 accent-primaryColor"
                  name="1"
                  value={1}
                />

                <p style={{ fontFamily: 'var(--secondary-font)' }} className="text-black text-3xl sm:text-xl xsm:text-lg tracking-wide font-semibold ">
                  Consegna al mio indirizzo
                </p>
              </label>
            )}

            {!NeedsNewAddress &&
              userData?.shippingAddress?.map((item, index) => (
                <label key={index} className="hover:cursor-pointer">
                  <div className="flex flex-row gap-x-2 items-center ml-10">
                    <input
                      onClick={() => handleNewInfo(item)}
                      name="address"
                      value={item._id}
                      defaultChecked={
                        index === 0 ||
                        Step1FormUserData.firstLine === item.firstLine
                      }
                      type="radio"
                      className="w-4 h-4  sm:h-4 xsm:h-4 sm:w-4 accent-primaryColor"
                    />
                    <p className="font-normal text-2xl sm:text-[16px] xsm:text-[16px]  font-sans">
                      Nome: {item?.recipientFullName}
                    </p>
                  </div>
                  <p className="font-normal text-2xl  sm:text-[16px] xsm:text-[16px] ml-6">
                    Indirizzo: {item?.firstLine}, {item?.secondLine}{" "}
                    {item?.city} , {item?.CAP}
                  </p>
                </label>
              ))}
            <label className="flex flex-row items-center gap-x-2 w-full  mx-auto">
              <input
                //                defaultChecked={userData?.shippingAddress?.length === 0}
                defaultChecked={userData === null || NeedsNewAddress === true}
                onClick={() => {
                  SetNewAddress(true);
                  SetUserDataForm({
                    recipientFullName: "",
                    email: "",
                    telephone: "",
                    password: "",
                    city: "",
                    CAP: "",
                    firstLine: "",
                    secondLine: "",
                  });
                }}
                type="radio"
                className="w-6 h-6  sm:h-4 xsm:h-4 sm:w-4 accent-primaryColor"
                name="1"
                value={1}
              />
              <p style={{ fontFamily: 'var(--secondary-font)' }} className="text-black text-3xl sm:text-xl xsm:text-lg  tracking-wide font-semibold ">
                Consegna ad un nuovo indirizzo
              </p>
            </label>
            {NeedsNewAddress && (
              <div className="flex flex-col w-full sm:w-[90%] xsm:w-[90%] mx-auto gap-y-2 items-center">
                <div className="flex flex-row gap-x-4 xsm:flex-col sm:flex-col md:flex-col xsm:gap-y-4 sm:gap-y-4 md:gap-y-4 w-full ">
                  <div className="flex flex-col gap-y-1">
                    <p style={{ fontFamily: 'var(--secondary-font)' }} className="font-semibold text-lg sm:text-[16px] xsm:text-[16px] ">
                      Nome e Cognome
                    </p>
                    <input
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={Step1FormUserData.recipientFullName}
                      type="text"
                      name="recipientFullName"
                      className="rounded-lg border-2 border-[#D5D5D5] pl-5 h-12 w-[400px] sm:w-[90%] sm:h-10 xsm:h-10 sm:mx-auto xsm:mx-auto xsm:w-[90%] focus:outline-none "
                      placeholder="Es: Manuelo Giangelo"
                      style={{ fontFamily: 'var(--secondary-font)' }}
                    />
                  </div>
                  <div className="flex flex-col gap-y-1">
                    <p style={{ fontFamily: 'var(--secondary-font)' }} className="font-semibold text-lg sm:text-[16px] xsm:text-[16px] ">
                      Telefono
                    </p>
                    <input
                      style={{ fontFamily: 'var(--secondary-font)' }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={Step1FormUserData.telephone}
                      onWheel={() => document.activeElement.blur()}
                      type="number"
                      name="telephone"
                      className="rounded-lg border-2 border-[#D5D5D5] pl-5 h-12 w-[400px] focus:outline-none sm:w-[90%] sm:h-10 xsm:h-10 sm:mx-auto xsm:mx-auto xsm:w-[90%]"
                      placeholder="Es: 0123456789"
                    />
                  </div>
                </div>
                <div className="flex flex-row  gap-x-4 xsm:flex-col sm:flex-col md:flex-col xsm:gap-y-4 sm:gap-y-4 md:gap-y-4 w-full">
                  <div className="flex flex-col gap-y-1">
                    <p style={{ fontFamily: 'var(--secondary-font)' }} className="font-semibold text-lg sm:text-[16px] xsm:text-[16px] ">
                      CAP
                    </p>
                    <input
                      style={{ fontFamily: 'var(--secondary-font)' }}
                      onWheel={() => document.activeElement.blur()}
                      type="number"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={Step1FormUserData.CAP}
                      name="CAP"
                      className="rounded-lg border-2 border-[#D5D5D5] pl-5 h-12 w-[400px] focus:outline-none sm:w-[90%] sm:h-10 xsm:h-10 sm:mx-auto xsm:mx-auto xsm:w-[90%]"
                      placeholder="Es: 54139"
                    />
                  </div>
                  <div className="flex flex-col gap-y-1">
                    <p style={{ fontFamily: 'var(--secondary-font)' }} className="font-semibold text-lg sm:text-[16px] xsm:text-[16px] ">
                      Città
                    </p>
                    <input
                      style={{ fontFamily: 'var(--secondary-font)' }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={Step1FormUserData.city}
                      type="text"
                      name="city"
                      className="rounded-lg border-2 border-[#D5D5D5] pl-5 h-12 w-[400px] focus:outline-none sm:w-[90%] sm:h-10 xsm:h-10 sm:mx-auto xsm:mx-auto xsm:w-[90%]"
                      placeholder="Es: Catania"
                    />
                  </div>
                </div>
                {/* <div className="flex flex-row  gap-x-4 xsm:flex-col sm:flex-col md:flex-col xsm:gap-y-4 sm:gap-y-4 md:gap-y-4 w-full" >
                                <input onChange={(e) => { handleChange(e) }} value={Step1FormUserData.City} type="text" name='city' className='rounded-lg border-2 border-[#D5D5D5] pl-5 h-12 w-[400px] focus:outline-none sm:w-[90%] sm:h-10 xsm:h-10 sm:mx-auto xsm:mx-auto xsm:w-[90%]' placeholder='Cita*' />
                                <input onChange={(e) => { handleChange(e) }} value={Step1FormUserData.Cap} type="text" name='CAP' className='rounded-lg border-2 border-[#D5D5D5] pl-5 h-12 w-[400px] focus:outline-none sm:w-[90%] sm:h-10 xsm:h-10 sm:mx-auto xsm:mx-auto xsm:w-[90%]' placeholder='Via*' />
                            </div> */}
                <div className="flex flex-row  gap-x-4 xsm:flex-col sm:flex-col md:flex-col xsm:gap-y-4 sm:gap-y-4 md:gap-y-4 w-full">
                  <div className="flex flex-col gap-y-1">
                    <p style={{ fontFamily: 'var(--secondary-font)' }} className="font-semibold text-lg">Indirizzo</p>
                    <input
                      style={{ fontFamily: 'var(--secondary-font)' }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={Step1FormUserData.firstLine}
                      type="text"
                      name="firstLine"
                      className="rounded-lg border-2 border-[#D5D5D5] pl-5 h-12 w-[400px] focus:outline-none sm:w-[90%] sm:h-10 xsm:h-10 sm:mx-auto xsm:mx-auto xsm:w-[90%]"
                      placeholder="Es: Via dei Pini"
                    />
                  </div>
                  <div className="flex flex-col gap-y-1 ">
                    <p style={{ fontFamily: 'var(--secondary-font)' }} className="font-semibold text-lg">N° Civico</p>
                    <input
                      style={{ fontFamily: 'var(--secondary-font)' }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={Step1FormUserData.secondLine}
                      type="text"
                      name="secondLine"
                      className="rounded-lg border-2 border-[#D5D5D5] pl-5 h-12 w-[400px] focus:outline-none sm:w-[90%] sm:h-10 xsm:h-10 sm:mx-auto xsm:mx-auto xsm:w-[90%]"
                      placeholder="Es: 45"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Step1;