import React, { useContext } from 'react';
import { MyContext } from "../../App"
function Step4({ Step1FormUserData, userData }) {
    const { cart, totalPrice, isForDelivery, DeliverySettings, finalShippingCost } = useContext(MyContext)
    return (
        <>
            <div className='flex flex-col w-full gap-x-2 mt-10 xsm:flex-col sm:flex-col md:flex-col xsm:justfy-center sm:justfy-center md:justfy-center xsm:items-center sm:items-center md:items-center xsm:gap-y-4 sm:gap-y-4 md:gap-y-4'>
                <div className='flex flex-col w-[70%] md:w-[85%] sm:w-full xsm:w-full xsm:px-2 sm:px-2 mx-auto  '>
                    <p className='text-4xl md:text-3xl sm:text-2xl xsm:text-xl font-semibold text-left mb-3 text-black '>Informazioni Fatturazione</p>
                    <div className="flex flex-row w-full  ">
                        {isForDelivery &&
                            <div className='flex flex-col gap-y-2 text-left mt-4' >
                                <p className='text-xl md:text-lg sm:text-md xsm:text-sm font-normal'>Nome: <span className='font-semibold'>{Step1FormUserData?.recipientFullName}</span> </p>
                                <p className='text-xl md:text-lg sm:text-md xsm:text-sm font-normal'>Indirizzo: <span className='font-semibold'>{Step1FormUserData.firstLine} , {Step1FormUserData.secondLine} , {Step1FormUserData.city} , {Step1FormUserData.CAP}</span></p>
                                <p className='text-xl md:text-lg sm:text-md xsm:text-sm font-normal'>Numero di telefono: <span className='font-semibold'>{Step1FormUserData?.telephone}</span></p>

                            </div>
                        }
                        {!isForDelivery &&
                            <div className='flex flex-col gap-y-2 text-left mt-4' >
                                <p className='text-xl md:text-lg sm:text-md xsm:text-sm font-normal'>Nome: <span className='font-semibold'>{userData?.firstName} {userData?.lastName}</span> </p>
                                {userData?.phoneNumber && <p className='text-xl md:text-lg sm:text-md xsm:text-sm font-normal'>Numero di telefono: <span className='font-semibold'>{userData?.phoneNumber}</span></p>}

                            </div>
                        }
                        <div className='flex flex-col gap-y-2 text-left mt-4 ml-[12.5%]' >
                            {/* <p className='text-xl md:text-lg sm:text-md xsm:text-sm font-normal'>Cognome: <span className='font-semibold'>{userData?.lastName}</span></p>
                            <p className='text-xl md:text-lg sm:text-md xsm:text-sm font-normal opacity-0 sm:hidden xsm:hidden'>Cognome: <span className='font-semibold'>{userData?.lastName}</span></p> */}

                            {/* <p className='text-xl md:text-lg sm:text-md xsm:text-sm font-normal'>E-mail: <span className='font-semibold'>{userData?.email}</span></p> */}

                        </div>
                    </div>

                </div>

                <div className="w-[70%] md:w-[85%] sm:w-full xsm:w-full xsm:px-2 sm:px-2 mx-auto text-left mt-8">
                    <p className='text-4xl md:text-3xl sm:text-2xl xsm:text-xl font-semibold text-left mb-3 text-black '>Informazioni Ordine</p>

                    <div className="flex -mx-1 border-b py-2 items-start">
                        <div className="flex-1 xsm:w-[38%] sm:w-[38%] px-1">
                            <p className="text-gray-800 uppercase tracking-wide text-sm xsm:text-xs sm:text-xs font-bold">Nome</p>
                        </div>

                        <div className="px-1 w-20  xsm:w-[20%] sm:w-[20%] text-center">
                            <p className="text-gray-800 uppercase tracking-wide text-sm xsm:text-xs sm:text-xs font-bold">Quantità</p>
                        </div>

                        <div className="px-1 w-32  xsm:w-[20%] sm:w-[20%] text-center">
                            <p className="leading-none">
                                <span className="block uppercase tracking-wide text-sm xsm:text-xs sm:text-xs font-bold text-gray-800">Prezzo Base</span>
                            </p>
                        </div>

                        <div className="px-1 w-32  xsm:w-[20%] sm:w-[20%] text-center">
                            <p className="leading-none">
                                <span className="block uppercase tracking-wide text-sm xsm:text-xs sm:text-xs font-bold text-gray-800">Totale</span>
                            </p>
                        </div>
                    </div>

                    {cart.map((item, index) => {
                        return (
                            <div key={index} className='flex flex-col border-b py-2'>

                                <div className="flex -mx-1">
                                    <div className="flex-1 px-1 flex gap-x-2 items-center ">
                                        <p className="text-gray-800  xsm:w-[38%] sm:w-[38%]  xsm:text-xs" >{item._id.title}
                                            <span className="text-xs ml-1">
                                                ({item?._id?.DesiredVariations?.name ? item?._id?.DesiredVariations?.name : item?._id?.variations[0]?.name ? item?._id?.variations[0]?.name : "Standard"})
                                            </span>
                                        </p>
                                        {/* <p className="text-xs underline hover:cursor-pointer" onClick={() => SetExpand(!isExpanded)}>{isExpanded ? "See less" : "See more"}</p> */}
                                    </div>

                                    <div className="px-1 w-20 xsm:w-[20%] sm:w-[20%] text-center">
                                        <p className="text-gray-800" >{item.quantity}</p>
                                    </div>

                                    <div className="px-1 w-32 xsm:w-[20%] sm:w-[20%] text-center">
                                        <p className="text-gray-800" >{item.originalPrice}€</p>
                                    </div>

                                    <div className="px-1 w-32 xsm:w-[20%] sm:w-[20%] text-center">
                                        <p className="text-gray-800" >{item._id.price * item.quantity}€</p>
                                    </div>
                                    {/* 
                                    <div

                                        className="px-1 w-20 text-right">
                                        <button className="text-red-500 hover:text-red-600 text-sm font-semibold">Delete</button>
                                    </div> */}
                                </div>

                                <div className="flex-1 px-1 flex gap-x-2 items-center">
                                    {item?._id?.DesiredOptions?.length === 0 && <p className="text-black text-xs font-thin">Aggiunte: nessuna</p>}
                                    {item?._id?.DesiredOptions?.length !== 0 &&


                                        <div className="flex gap-x-1">

                                            <p className="text-black text-xs font-thin">
                                                Aggiunte:
                                            </p>
                                            {item._id.DesiredOptions.map((opt, index) => (
                                                <p key={index} className="text-black text-xs font-thin">
                                                    {opt.name}
                                                </p>
                                            ))}

                                        </div>





                                    }
                                </div>

                            </div>
                        )
                    })}




                    <div className="py-2 ml-auto mt-2 w-full">
                        {isForDelivery &&
                            <>
                                <div className="flex justify-between mb-1 gap-x-24 w-fit ml-auto">
                                    <div className="text-gray-800 text-left flex-1">Subtotale</div>
                                    <div className="text-left ">
                                        <div className="text-gray-800 font-medium" x-html="netTotal">{totalPrice}€</div>
                                    </div>
                                </div>
                                <div className="flex justify-between mb-2 gap-x-14 w-fit ml-auto ">
                                    <div className="text-sm text-gray-600 text-left flex-1 ">Costo di consegna</div>
                                    <div className="text-left ">
                                        <div className="text-sm text-gray-600" x-html="totalGST">{finalShippingCost} €</div>
                                    </div>
                                </div>
                            </>}

                        <div className={`py-2 ${isForDelivery ? "border-t " : "border-none "} `}>
                            <div className="flex justify-between gap-x-8">
                                <div className="text-xl text-gray-600 text-right flex-1">Totale Ordine</div>
                                <div className="text-right">
                                    <div className="text-xl text-gray-800 font-bold" x-html="netTotal">{isForDelivery ? (parseFloat(totalPrice) + finalShippingCost) : parseFloat(totalPrice)}€</div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <div className="flex flex-row gap-x-6 pr-7 py-2  ml-auto mt-5 w-full  ">
                        <div className="text-xl text-gray-600 text-right flex-1">Totale Ordine</div>
                        <div className="text-right ">
                            <div className="text-xl text-gray-800 font-bold" >{totalPrice}€</div>
                        </div>
                    </div> */}


                </div>
            </div>
        </>
    )
}

export default Step4