import React, { lazy, Suspense } from "react";
import Skeleton from "@mui/material/Skeleton";
import ChangeBGModal from "../ChangeBGModal";
import { MyContext } from "../../../App";

const Bansw01 = lazy(() => import("./Sweet-Theme-01/BannerBlock"));
const Bancf01 = lazy(() => import("./Coffee-Theme-01/BannerBlock"));
const BanAm01 = lazy(() => import("../BannerBlock-02/American-Theme-01/banner"))
const BanFis02 = lazy(() => import("../BannerBlock-02/Fish-Theme-02/BannerBlock"))

const BannerBlock = ({ theme }) => {
  const {  PatchNewInfo, SetRestaurantInfo, RestaurantInfo } = React.useContext(MyContext)
  const [open, setOpen] = React.useState(false)
  const handleOpen = (section) => setOpen(true)
  const handleClose = () => setOpen(false);
  
  
  return (
    <div>
    {/* {RestaurantInfo?.theme_photos?.home?.menu_banner_2?.isVisible && */}
      <Suspense
        fallback={
          <Skeleton
            variant="rectangular"
            width={"100%"}
            className="mt-[5%] "
            height={600}
            animation="wave"
          />
        }
      >
        {theme === "Sweet-01" ? <Bansw01 handleOpen={handleOpen} /> : null}

        {theme === "Coffee-01" ? <Bancf01 /> : null}
        <ChangeBGModal
          WhichPart={"Banner01"}
          handleClose={handleClose}
          open={open}
          PatchNewInfo={PatchNewInfo}
          SetRestaurantInfo={SetRestaurantInfo}
          RestaurantInfo={RestaurantInfo}
        />
        {theme === "American-01" ? <BanAm01 /> : null}
        {theme === "Fish-02" ? <BanFis02 /> : null}
      </Suspense>
      {/* } */}
    </div>
  );
};

export default BannerBlock;
