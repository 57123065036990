import React from 'react';
import BookingCard from './BookingCard';
import { getBookingsForCustomer } from "../../../Util/Api/CustomerAPI";
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import Skeleton from '@mui/material/Skeleton';
import ReactPaginate from 'react-paginate';
import "../Profile.css"
function BookingHistory({ userData }) {




    const [PastBookings, SetPastBookings] = React.useState([])
    const [inComingBookings, SetIncoming] = React.useState([])
    const [isLoading, SetLoading] = React.useState(false)
    async function getCustomerBookings() {
        SetLoading(true)
        try {
            const res = await getBookingsForCustomer(userData.email)
            const incomingBookings = res.data.filter((item) => isAfter(new Date(item?.booked_at), new Date()))
            const PastBookings = res.data.filter((item) => isBefore(new Date(item?.booked_at), new Date()))
            SetIncoming(incomingBookings)
            SetPastBookings(PastBookings)
        } catch (error) {
            console.log(error)
        }
        SetLoading(false)
    }
    React.useEffect(() => {
        getCustomerBookings()
    }, [])

    const [isActive, SetActive] = React.useState(1)
    const [itemOffset, setItemOffset] = React.useState(0);

    const [ForceActive, SetPage] = React.useState(0)
    const endOffset = itemOffset + 5;
    const [inComingBookingsPaginated, setIncomingPaginated] = React.useState({})
    React.useEffect(() => {
        setIncomingPaginated(inComingBookings?.slice(itemOffset, endOffset));
    }, [itemOffset, inComingBookings])
    const pageCount = Math.ceil(inComingBookings?.length / 5);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * 5) % inComingBookings.length;
        setItemOffset(newOffset);
    };

    const [itemOffset2, setItemOffset2] = React.useState(0);

    const endOffset2 = itemOffset2 + 5;
    const [PastBookingsPaginated, SetPastPaginated] = React.useState([])
    React.useEffect(() => {
        SetPastPaginated(PastBookings?.slice(itemOffset2, endOffset2));
    }, [itemOffset2, PastBookings])
    const pageCount2 = Math.ceil(PastBookings?.length / 5);


    const handlePageClick2 = (event) => {
        const newOffset = (event.selected * 5) % PastBookings.length;
        setItemOffset2(newOffset);
    };
    return (
        <div className='flex flex-col overflow-y-scroll gap-y-5 pl-[2%] sm:pl-0 xsm:pl-0 md:pl-0'  >

            <div className="flex flex-row w-1/2 sm:w-full xsm:w-full md:w-full mx-auto mb-8 " >
                <button
                    onClick={() => {
                        SetActive(1)
                        SetPage(0)
                    }}
                    className={`w-1/2 py-2  ${isActive === 1 ? "border-primaryColor text-primaryColor font-bold" : " font-normal border-gray-400 text-gray-400"} border-b-4 sm:text-sm xsm:text-sm md:text-sm`}>
                    Nuove Prenotazioni
                </button>
                <button
                    onClick={() => SetActive(2)}
                    className={`w-1/2 py-2  ${isActive === 2 ? "border-primaryColor text-primaryColor font-bold" : " font-normal border-gray-200 text-gray-400"} border-b-4 sm:text-sm xsm:text-sm md:text-sm `}>
                    Storico Prenotazioni
                </button>

            </div>




            {isLoading &&
                <div className='flex flex-col gap-y-1'>
                    <Skeleton width="20%" animation="wave" variant="text" sx={{ fontSize: '1.5rem', marginBottom: 1 }} />
                    <Skeleton width="25%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton width="25%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton width="25%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton width="25%" animation="wave" variant="text" sx={{ fontSize: '1rem', marginTop: 5 }} />
                    <Skeleton width="25%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton width="25%" animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                </div>
            }

            {isActive === 1 && !isLoading &&
                <div className='flex flex-col  sm:items-center xsm:items-center md:items-center'>

                    {inComingBookings.length === 0 ?

                        <p className="text-2xl font-primaryColor font-semibold mt-24">Non hai ancora effettutato una Prenotazione</p>

                        : inComingBookingsPaginated?.map(booking =>


                            <div className='p-4 bg-white elevation-1 rounded-md my-2 w-fit'>

                                <BookingCard booking={booking} />

                            </div>)}
                </div>
            }


            {isActive === 2 && !isLoading &&
                <div className='flex flex-col  sm:items-center xsm:items-center md:items-center'>

                    {inComingBookings.length === 0 ?

                        <p className="text-2xl font-primaryColor font-semibold mt-24">Non hai ancora effettutato una Prenotazione</p>

                        : PastBookingsPaginated?.map(booking =>


                            <div className='p-4 bg-white elevation-1 rounded-md my-2 w-fit'>

                                <BookingCard booking={booking} />

                            </div>)}
                </div>
            }



            {
                isActive === 1 && inComingBookings.length > 5 &&
                <div className="mt-auto mx-auto">
                    <ReactPaginate
                        breakLabel="..."
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={window.innerWidth < 700 ? 2 : 5}
                        pageCount={pageCount}
                        forcePage={ForceActive}
                        nextLabel="❯"
                        nextClassName="text-primaryColor text-2xl"
                        pageClassName="bg-white  rounded-xl text-center border font-semibold w-10 h-10 flex items-center justify-center hover:text-white   text-primaryColorActive border-primaryColor border-2 border-primaryColor hover:bg-primaryColor hover:border-primaryColorHover active:shadow-innerbig active:bg-primaryColorActive active:border-primaryColorActive"
                        pageLinkClassName="w-full h-full items-center justify-center flex"
                        previousLabel="❮"
                        previousClassName="text-primaryColor text-2xl"
                        activeClassName=" bg-primaryColorActive text-center justify-center rounded-xl border border-primaryColorActive w-10 h-10 text-white forceWhiteText hover:bg-primaryColorHover hover:border-primaryColorHover active:shadow-innerbig active:bg-primaryColorActive active:border-primaryColorActive"
                        containerClassName="flex flex-row gap-x-4 xsm:gap-x-1 items-center  mx-auto  mb-12"
                        renderOnZeroPageCount={false}
                        marginPagesDisplayed={1}
                    />
                </div>}
            {isActive === 2 && PastBookings.length > 5 &&
                <div className="mt-auto mx-auto">
                    <ReactPaginate
                        breakLabel="..."
                        onPageChange={handlePageClick2}
                        pageRangeDisplayed={window.innerWidth < 700 ? 2 : 5}
                        pageCount={pageCount2}
                        forcePage={ForceActive}
                        nextLabel="❯"
                        nextClassName="text-primaryColor text-2xl"
                        pageClassName="bg-white  rounded-xl text-center border font-semibold w-10 h-10 flex items-center justify-center hover:text-white   text-primaryColorActive border-primaryColor border-2 border-primaryColor hover:bg-primaryColor hover:border-primaryColorHover active:shadow-innerbig active:bg-primaryColorActive active:border-primaryColorActive"
                        pageLinkClassName="w-full h-full items-center justify-center flex"
                        previousLabel="❮"
                        previousClassName="text-primaryColor text-2xl"
                        activeClassName=" bg-primaryColorActive text-center justify-center rounded-xl border border-primaryColorActive w-10 h-10 text-white forceWhiteText hover:bg-primaryColorHover hover:border-primaryColorHover active:shadow-innerbig active:bg-primaryColorActive active:border-primaryColorActive"
                        containerClassName="flex flex-row gap-x-4 xsm:gap-x-1 items-center  mx-auto  mb-12"
                        renderOnZeroPageCount={false}
                        marginPagesDisplayed={1}
                    />
                </div>}


        </div>
    )
}

export default BookingHistory