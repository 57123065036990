import React, { lazy, Suspense } from 'react';

const Ophch01 = lazy(() => import('./Chinese-Theme-01/OpeningHours'));
const Ophfs02 = lazy(() => import("../OpeningHours/Fish-Theme-02/OpeningHours"))

const OpeningHours = ( { theme } ) => {

    return (

      <div>

        <Suspense fallback={<div>Pagina in caricamento...</div>}>

          {theme === "Chinese-01" ? 
          
            <Ophch01/>
            :
             null
             
          }

          {theme === "Fish-02" ?

            <Ophfs02 />
            :
            null

          }

        </Suspense>

      </div>

    );
}

export default OpeningHours;