import React from 'react'
import Ricetta from '../Navbar/Ricetta'
// import card from "../../../Assets/Icons/Schermata 2022-03-22 alle 17.21 1.png"
import { MyContext } from "../../App";
function Step3() {
    const { setPaymentMethod, DeliverySettings } = React.useContext(MyContext)

    return (
        <>
            <div className='flex flex-col relative items-center pb-12 mb-20 h-[500px] sm:h-fit xsm:h-fit'>
                <p className='font-bold text-5xl sm:text-2xl xsm:text-2xl md:text-3xl text-black font-sans'>Scegli il metodo di <span className="text-primaryColor">pagamento</span></p>
                <div className='flex flex-col gap-y-4 mt-4'>

                    {DeliverySettings?.creditCard &&
                        <label className='flex flex-row items-center gap-x-2 w-fit mr-auto'>
                            <input onClick={(e) => setPaymentMethod(e.target.value)} value="stripe" type="radio" className='w-6 h-6  sm:h-4 xsm:h-4 sm:w-4 xsm:h-4 accent-primaryColor' name='1' />
                            <p className='text-black font-sans text-xl sm:text-[16px] xsm:text-[16px] tracking-wide font-normal '>Carta di credito o di debito</p>
                        </label>
                    }

                    <label className='flex flex-row items-center gap-x-2 w-fit mr-auto'>
                        <input onClick={(e) => setPaymentMethod(e.target.value)} value="cash" type="radio" className='w-6 h-6  sm:h-4 xsm:h-4 sm:w-4 xsm:h-4 accent-primaryColor' name='1' />
                        <p className='text-black font-sans text-xl sm:text-[16px] xsm:text-[16px] tracking-wide font-normal '>Pagamento alla consegna</p>
                    </label>

                    {/*
                    {type === "visa" && <div className='flex flex-col gap-y-2' >
                        <p className=' text-left' >Numero di sicurezza</p>
                        <div className='flex flex-row items-center gap-x-4'>
                            <input onWheel={() => document.activeElement.blur()} type="number" className='w-20 pl-2 h-12 rounded-lg border focus:outline-none' />
                            <img src={card} alt='' />
                            <p>Il numero a tre cifre<br />sul <span className='font-semibold'>retro</span> della carta</p>
                        </div>
                    </div>}



                    <label className='flex flex-row items-center gap-x-2 w-fitmr-auto'>
                        <input onClick={(e) => SetType(e.target.value)} value="card" type="radio" className='w-6 h-6  sm:h-4 xsm:h-4 sm:w-4 xsm:h-4 accent-primaryColor' name='1' />
                        <p className='text-black font-sans text-xl tracking-wide font-normal '>Carta di credito o di debito</p>
                    </label>
                    <label className='flex flex-row items-center gap-x-2 w-fit mr-auto'>
                        <input onClick={(e) => SetType(e.target.value)} value="paypal" type="radio" className='w-6 h-6  sm:h-4 xsm:h-4 sm:w-4 xsm:h-4 accent-primaryColor' name='1' />
                        <p className='text-black font-sans text-xl tracking-wide font-normal '>Paypal</p>
                    </label>
                    <label className='flex flex-row items-center gap-x-2 w-fit mr-auto'>
                        <input onClick={(e) => SetType(e.target.value)} value="cash" type="radio" className='w-6 h-6  sm:h-4 xsm:h-4 sm:w-4 xsm:h-4 accent-primaryColor' name='1' />
                        <p className='text-black font-sans text-xl tracking-wide font-normal '>Contanti</p>
                    </label>
                    
                    */}

                </div>

            </div>
        </>
    )
}

export default Step3