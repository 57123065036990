import React, { lazy, Suspense } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { MyContext } from "../../../App"


const Rsbch01 = lazy(() => import('./Chinese-Theme-01/RestaurantBio'));
const Rsbch02 = lazy(() => import('./Chinese-Theme-02/RestaurantBio'));
const Rsbit01 = lazy(() => import('./Italian-Theme-01/RestaurantBio'));
const Rsbit02 = lazy(() => import('./Italian-Theme-02/RestaurantBio'));
const Rsbit03 = lazy(() => import('./Italian-Theme-03/RestaurantBio'));
const Rsbar01 = lazy(() => import('./Arabian-Theme-01/RestaurantBio'));
const Rsbpz01 = lazy(() => import('./Pizza-Theme-01/RestaurantBio'));
const Rsbpz02 = lazy(() => import('./Pizza-Theme-02/RestaurantBio'));
const Rsbpb01 = lazy(() => import('./Pub-Theme-01/RestaurantBio'));
const Rsbsw01 = lazy(() => import('./Sweet-Theme-01/RestaurantBio'));
const Rsbcf01 = lazy(() => import('./Coffee-Theme-01/RestaurantBio'));
const Rsbam01 = lazy(() => import("./American-Theme-01/RestaurantBio"))
const Rsbfs02 = lazy(() => import("./Fish-Theme-02/restaurantBio"))




const RestaurantBio = ({ theme, handleOpen }) => {

  const { RestaurantInfo } = React.useContext(MyContext)

  return (
    <>
      {RestaurantInfo?.theme_photos?.home?.bio?.isVisible && <div>


        <Suspense fallback={<Skeleton variant="rectangular" width={"100%"} height={70} />}>

          {theme === "Chinese-01" ?

            <Rsbch01 handleOpen={handleOpen}  />
            :
            null
          }
          {theme === "Chinese-02" ?

            <Rsbch02  handleOpen={handleOpen} />
            :
            null
          }

          {theme === "Italian-01" ?
            <Rsbit01 handleOpen={handleOpen} />
            :
            null
          }
          {theme === "Italian-02" ?
            <Rsbit02 handleOpen={handleOpen}  />
            :
            null
          }
          {theme === "Italian-03" ?
            <Rsbit03 handleOpen={handleOpen}  />
            :
            null
          }
          {theme === "Pizza-01" ?
            <Rsbpz01  handleOpen={handleOpen} />
            :
            null
          }
          {theme === "Pizza-02" ?
            <Rsbpz02  handleOpen={handleOpen} />
            :
            null
          }

          {theme === "Arabian-01" ?
            <Rsbar01 handleOpen={handleOpen}  />
            :
            null
          }

          {theme === "Pub-01" ?
            <Rsbpb01 handleOpen={handleOpen}  />
            :
            null
          }

          {theme === "Sweet-01" ?
            <Rsbsw01 handleOpen={handleOpen}  />
            :
            null
          }

          {theme === "Coffee-01" ?
            <Rsbcf01 handleOpen={handleOpen}  />
            :
            null
          }

          {theme === "American-01" ?
            <Rsbam01 handleOpen={handleOpen}  />
            :
            null
          }

          {theme === "Fish-02" ?
            <Rsbfs02 handleOpen={handleOpen}  />
            :
            null
          }

        </Suspense>

      </div>
      }
    </>

  );
}

export default RestaurantBio;