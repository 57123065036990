import React, { useEffect, useState } from "react";
import "../../App.css";
import ScrollToTop from "../../Reusable/ScrollToTop";
import axios from "axios";
import { getSiteId } from "../../Util/Data/userData";
import { baseUrl } from "../../Util/Api/Urls";
import { getRestaurantInfo } from "../../Util/Api/PrivacyPolicyApi";

const Privacypolicy = () => {
  const [data, setData] = useState();
  useEffect(() => {
    const fetchRestaurantInfo = async () => {
      const restaurantInfo = await getRestaurantInfo();
      setData(Object.values(restaurantInfo));
    };
    fetchRestaurantInfo();
  }, []);

  return (
    <>
      <ScrollToTop />

      <div className="flex flex-col w-[80%] text-left justify-center items-center mx-auto pt-20 " style={{ fontFamily: 'var(--secondary-font)' }}>
        <h1 style={{ fontFamily: 'var(--primary-font)' }} className="my-8 Brevia700 text-4xl text-primaryColor text-center">
          INFORMATIVA PRIVACY<br></br>
          AI SENSI DELL’ART. 13 REGOLAMENTO UE 649/2016 - GDPR
        </h1>

        <p>
          Lo scopo del presente documento è di informare la persona fisica (di
          seguito "<span className="font-bold"> Interessato </span> ”)
          relativamente al trattamento dei suoi dati personali (di seguito “{" "}
          <span className="font-bold"> Dati Personali </span> ”) raccolti
          tramite il sito internet e l’App del Ristoratore.
        </p>

        <br />

        <div className="flex flex-col">
          <ol className="list-decimal">
            <div className="flex flex-row ml-4 mb-2">
              <p className="mr-2 font-bold">1.</p>
              <span className="font-bold">Titolari del trattamento</span>
            </div>

            <ul className="flex flex-col list-disc gap-y-4">
              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  {" "}
                  {Array.isArray(data) && data[0].name}, con sede in
                  {Array.isArray(data) && data[0].formatted_address}, CF/P.IVA:
                  {Array.isArray(data) && data[0].vatNumber}, indirizzo e-mail
                  {Array.isArray(data) && data[0].email}, telefono{" "}
                  {Array.isArray(data) && data[0].formatted_phone_number} (di
                  seguito “Ristoratore”){" "}
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  {" "}
                  AiGot S.r.l., con sede legale in Milano (MI) via Visconti di
                  Modrone 38, cap 20122, e sede operativa in Pisa (PI) via
                  Guglielmo Oberdan 14, cap 56127, CF/P.IVA 11498080966,
                  indirizzo e-mail privacy@aigot.com, indirizzo PEC
                  aigot@pec.com, telefono 0500985159 (di seguito “AiGot”)
                </p>
              </li>
            </ul>
            <br></br>
            <p>
              {" "}
              Per le finalità di trattamento indicate nella presente Informativa
              il Ristoratore e AiGot agiscono quali titolari autonomi del
              trattamento.
            </p>
          </ol>
        </div>

        <br />

        <div className="flex flex-col">
          <ol className="list-decimal">
            <div className="flex flex-row ml-4 mb-2">
              <p className="mr-2 font-bold">2.</p>
              <span className="font-bold">
                Categorie di dati personali trattati
              </span>
            </div>

            <p className="ml-10 mb-2">
              {" "}
              I Titolari trattano le seguenti tipologie di Dati Personali
              forniti dall’Interessato:{" "}
            </p>

            <ul className="flex flex-col list-disc gap-y-4">
              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">•</p>
                <p>
                  <span className="font-bold"> Dati di contatto: </span> nome,
                  cognome, indirizzo, e-mail, recapito telefonico, codice
                  fiscale, credenziali di autenticazione al sito web e app.
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">•</p>
                <p>
                  <span className="font-bold">
                    {" "}
                    Dati fiscali e di pagamento:{" "}
                  </span>{" "}
                  partita IVA, dati di acquisto, dati di pagamento{" "}
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">•</p>
                <p>
                  <span className="font-bold"> Dati tecnici: </span> dati di
                  navigazione, indirizzo IP, indicazione tipo di browser,
                  indicazione provider internet, dati di localizzazione.
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">•</p>
                <p>
                  <span className="font-bold"> Dati particolari </span> di cui
                  all’art. 9 GDPR, a titolo esemplificativo dati relativi alla
                  salute dell’Interessato con particolare riferimento ad
                  allergie e intolleranze alimentari.
                </p>
              </li>
            </ul>
            <br></br>
            <p className="ml-10 mb-2">
              {" "}
              L’Interessato che comunichi al Titolare Dati Personali di terzi è
              direttamente ed esclusivamente responsabile della provenienza,
              raccolta, trattamento, comunicazione e diffusione di tali dati.
            </p>
          </ol>
        </div>

        <br />

        <div className="flex flex-col">
          <ol className="list-decimal">
            <div className="flex flex-row ml-4 mb-2">
              <p className="mr-2 font-bold">3.</p>
              <span className="font-bold">Finalità del Trattamento </span>
            </div>

            <p className="ml-10 mb-2 font-bold ">
              {" "}
              A) Erogazione di servizi in favore dell’Interessato ed adempimento
              degli obblighi derivanti dal rapporto precontrattuale o
              contrattuale in essere tra il Ristoratore e l’Interessato
            </p>
            <ul className="flex flex-col list-disc gap-y-4">
              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  vendita di prodotti e servizi mediante il sito web e l’app del
                  Ristoratore;
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  erogazione del servizio di consegna a domicilio e del servizio
                  di asporto;
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  erogazione del servizio di prenotazione tramite sito web e
                  app;
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  contatti diretti con l’Interessato per rispondere alle
                  richieste di questo nonché al fine di fornire assistenza e
                  supporto;{" "}
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  adempimenti relativi ai pagamenti effettuati dall’Interessato.
                </p>
              </li>
            </ul>

            <p className="ml-10 mb-2">
              Per tutte le suddette finalità il conferimento dei dati è
              obbligatorio. Il rifiuto di fornire i dati non consente
              l'esecuzione dei servizi richiesti dall’Interessato.
            </p>

            <br></br>

            <p className="ml-10 mb-2 font-bold ">
              {" "}
              B) Finalità relative alla gestione, ottimizzazione, manutenzione
              dei sistemi
            </p>
            <ul className="flex flex-col list-disc gap-y-4">
              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  gestione, ottimizzazione e monitoraggio dell’infrastruttura
                  tecnica del sito web e dell’app del Ristoratore;
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  gestione, ottimizzazione e monitoraggio dell’infrastruttura
                  tecnica sottesa ai servizi offerti;
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  identificazione e risoluzione di eventuali problemi tecnici
                  del sistema informatico;
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  gestione e organizzazione delle informazioni del sistema
                  informatico (ad es. server, database etc.)
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  miglioramento delle performance del sistema informatico, del
                  software e dei i servizi ad esso correlati.
                </p>
              </li>
            </ul>

            <br></br>

            <p className="ml-10 mb-2 font-bold ">
              C) Finalità di marketing e profilazione da parte del Ristoratore,
              previo consenso dell’Interessato:
            </p>
            <ul className="flex flex-col list-disc gap-y-4">
              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  invio di comunicazioni commerciali con modalità di contatto
                  automatizzate e non (ad es. sms, e-mail, notifiche push
                  tramite app e messaggistica istantanea), tradizionali (ad es.
                  telefonate, posta ordinaria), canali online (ad. es. notifiche
                  pop up sul sito web) o attraverso social network e altre
                  piattaforme web;
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  promozione di prodotti e/o servizi del Ristoratore effettuato
                  ai recapiti dell’Interessato;
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  raccolta automatizzata delle informazioni personali
                  dell’Interessato volta ad analizzare, prevedere e valutare le
                  sue preferenze o comportamenti (c.d. profilazione);
                </p>
              </li>
            </ul>

            <br></br>

            <p className="ml-10 mb-2 font-bold ">
              D) Finalità di marketing e profilazione da parte del Ristoratore,
              previo consenso dell’Interessato:
            </p>
            <ul className="flex flex-col list-disc gap-y-4">
              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  invio di comunicazioni commerciali con modalità di contatto
                  automatizzate e non (ad es. sms, e-mail, notifiche push
                  tramite app e messaggistica istantanea), tradizionali (ad es.
                  telefonate, posta ordinaria), canali online (ad. es. notifiche
                  pop up sul sito web) o attraverso social network e altre
                  piattaforme web;
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  elaborazione statistica per lo sviluppo di Campagne Marketing;
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  raccolta automatizzata delle informazioni personali
                  dell’Interessato volta ad analizzare, prevedere e valutare le
                  sue preferenze o comportamenti (c.d. profilazione);
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">-</p>
                <p>
                  Ricerche di mercato con modalità automatizzate e tradizionali
                </p>
              </li>
            </ul>

            <br></br>

            <p className="ml-10 mb-2 font-bold ">
              E) Adempimento di obblighi previsti dalle normative vigenti o da
              un’ordine dell’Autorità
            </p>
            <p className="ml-10 mb-2 font-bold ">
              F) Finalità di prevenzione e repressione di frodi / abusi /
              attività fraudolente
            </p>
            <p className="ml-10 mb-2 ">
              {" "}
              <span className="font-bold">
                {" "}
                G) Accertamento, esercizio e difesa di diritti
              </span>{" "}
              in qualunque sede, giudiziale e stragiudiziale, ed in qualunque
              grado sino all’esaurimento dei termini di esperibilità delle
              impugnazioni e delle azioni previste dall'ordinamento.
            </p>
          </ol>
        </div>

        <div className="flex flex-col">
          <ol className="list-decimal">
            <div className="flex flex-row ml-4 mb-2">
              <p className="mr-2 font-bold">4.</p>
              <span className="font-bold">
                Modalità di trattamento dei Dati Personali e soggetti
                autorizzati
              </span>
            </div>

            <p className="ml-10 mb-2">
              Il trattamento dei Dati Personali viene effettuato mediante
              strumenti cartacei e/o informatizzati con modalità organizzative e
              con logiche strettamente correlate alle finalità indicate dalla
              presente Informativa e mediante l'adozione di adeguate misure di
              sicurezza. Tutte le operazioni di trattamento dei dati sono
              attuate in modo da garantire l’integrità, la riservatezza e la
              disponibilità dei dati personali.
              <br></br>I Dati Personali sono trattati esclusivamente da:
            </p>
            <ul className="flex flex-col list-disc gap-y-4">
              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">•</p>
                <p>
                  persone autorizzate dai Titolari del trattamento che si sono
                  impegnate alla riservatezza o che hanno un adeguato obbligo
                  legale di riservatezza;
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">•</p>
                <p>
                  soggetti che operano in autonomia come distinti titolari del
                  trattamento o da soggetti designati quali responsabili del
                  trattamento dal Titolare al fine di svolgere tutte le attività
                  di trattamento necessarie a perseguire le finalità di cui alla
                  presente Informativa (ad esempio Partner commerciali,
                  consulenti, società informatiche, fornitori di servizi,
                  hosting provider);{" "}
                </p>
              </li>

              <li className="flex flex-row ml-10">
                <p className="mr-4 font-bold">•</p>
                <p>
                  soggetti o enti a cui è necessario comunicare i Dati Personali
                  ai sensi dell’art. 5 o per obbligo di legge o per ordine
                  dell’Autorità.
                </p>
              </li>
            </ul>

            <p className="ml-10 mb-2">
              I soggetti sopra elencati sono tenuti a utilizzare le dovute
              cautele previste dalla legge nonché tutti i comportamenti che si
              rendano necessari nel caso concreto al fine di proteggere i Dati
              Personali. Tali soggetti possono accedere solo a quei dati
              necessari per eseguire i compiti a loro assegnati.
              <br></br>I Dati Personali non verranno diffusi indiscriminatamente
              in alcun modo
            </p>
          </ol>
        </div>

        <br />

        <div className="flex flex-col">
          <ol className="list-decimal">
            <div className="flex flex-row ml-4 mb-2">
              <p className="mr-2 font-bold">5.</p>
              <span className="font-bold">Ambito di comunicazione</span>
            </div>

            <p className="ml-10 mb-4">
              {" "}
              I dati personali dell’Interessato potranno essere comunicati a
              soggetti quali, a titolo esemplificativo, autorità di vigilanza e
              controllo, soggetti pubblici legittimati, quali l’Autorità
              giudiziaria e/o di pubblica sicurezza, società del gruppo cui i
              Titolari eventualmente appartengano. I dati potranno inoltre
              essere trattati per conto dei Titolari da soggetti designati
              responsabili del trattamento quali, ad esempio, società che
              erogano servizi informatici (manutenzione, hosting, mailing, invio
              di sms), Partner commerciali, consulenti, società del gruppo cui i
              Titolari eventualmente appartengano per servizi infragruppo, Meta
              e Google. Gli Utenti devono soddisfare i seguenti requisiti:
            </p>
          </ol>
        </div>

        <br />

        <div className="flex flex-col ">
          <ol className="list-decimal">
            <div className="flex flex-row ml-4 mb-2">
              <p className="mr-2 font-bold">6.</p>
              <span className="font-bold">
                Periodo di conservazione dei Dati Personali
              </span>
            </div>

            <p className="ml-10 mb-2">
              {" "}
              I Dati Personali saranno conservati per il periodo di tempo
              necessario per il raggiungimento delle finalità per i quali sono
              stati raccolti, in particolare:
            </p>
          </ol>

          <ul className="flex flex-col list-disc">
            <li className="flex flex-row ml-10">
              <p className="mr-4 font-bold">•</p>
              <p>
                I Dati Personali raccolti per l’erogazione di servizi in favore
                dell’Interessato ed adempimento degli obblighi derivanti dal
                rapporto precontrattuale o contrattuale in essere tra il
                Ristoratore e l’Interessato (art. 3 let. A) saranno conservati
                per tutta la durata di tali rapporti e, dopo la cessazione, per
                il periodo di prescrizione ordinario pari a 10 anni;
              </p>
            </li>

            <li className="flex flex-row ml-10">
              <p className="mr-4 font-bold">•</p>
              <p>
                I Dati Personali raccolti per finalità relative alla gestione,
                ottimizzazione, manutenzione dei sistemi (art. 3 let. B) saranno
                conservati per tutta la durata del rapporto con l’Interessato e,
                dopo la cessazione, per il periodo di prescrizione ordinario
                pari a 10 anni;
              </p>
            </li>

            <li className="flex flex-row ml-10">
              <p className="mr-4 font-bold">•</p>
              <p>
                I Dati Personali raccolti per Finalità di marketing e
                profilazione da parte del Ristoratore (art. 3 let. C) saranno
                trattati sino alla revoca del consenso da parte
                dell’Interessato.{" "}
              </p>
            </li>

            <li className="flex flex-row ml-10">
              <p className="mr-4 font-bold">•</p>
              <p>
                I Dati Personali raccolti per Finalità di marketing, statistica,
                profilazione e ricerche di mercato da parte del AiGot (art. 3
                let. D) saranno trattati sino alla revoca del consenso da parte
                dell’Interessato e comunque.
              </p>
            </li>

            <li className="flex flex-row ml-10">
              <p className="mr-4 font-bold">•</p>
              <p>
                I Dati Personali raccolti per adempimento degli obblighi
                previsti dalle normative vigenti (art. 3 let. E), quelli
                raccolti su ordine dell’Autorità (art. 3 let. E) e quelli
                raccolti raccolti per finalità di prevenzione e repressione di
                frodi / abusi / attività fraudolente (art. 3 let. F) saranno
                conservati nei termini previsti da tali normative e comunque
                sino al termine prescrizionale previsto dalle norme vigenti;
              </p>
            </li>

            <li className="flex flex-row ml-10">
              <p className="mr-4 font-bold">•</p>
              <p>
                I Dati personali raccolti per accertare, esercitare difendere i
                diritti dei Titolari (art. 3 let. G) saranno conservati per
                tutta la durata del contenzioso, in qualunque sede, giudiziale e
                stragiudiziale, ed in qualunque grado sino all’esaurimento dei
                termini di esperibilità delle impugnazioni e delle azioni
                previste dall'ordinamento.
              </p>
            </li>
          </ul>
          <br></br>
          <p className="ml-10 mb-2">
            {" "}
            Al termine del periodo di conservazione, tutti i Dati Personali
            saranno cancellati o conservati in una forma che non consenta
            l’identificazione dell’Interessato.
          </p>
        </div>

        <br />

        <div className="flex flex-col  w-full">
          <ol className="list-decimal ">
            <div className="flex flex-row ml-4 mb-2">
              <p className="mr-2 font-bold">7.</p>
              <span className="font-bold">Diritti dell’Interessato</span>
            </div>

            <p className="ml-10 mb-2">
              {" "}
              In relazione ai dati oggetto del trattamento di cui alla presente
              informativa, all’Interessato è riconosciuto in qualsiasi momento
              il diritto di:
            </p>
          </ol>

          <ul className="flex flex-col list-disc">
            <li className="flex flex-row ml-10">
              <p className="mr-4 font-bold">•</p>
              <p>essere informato sul trattamento dei propri Dati Personali</p>
            </li>

            <li className="flex flex-row ml-10">
              <p className="mr-4 font-bold">•</p>
              <p>accedere ai propri Dati Personali (art 15 GDPR)</p>
            </li>

            <li className="flex flex-row ml-10">
              <p className="mr-4 font-bold">•</p>
              <p>
                chiedere la rettifica dei propri Dati Personali (art. 16 GDPR)
              </p>
            </li>

            <li className="flex flex-row ml-10">
              <p className="mr-4 font-bold">•</p>
              <p>
                ottenere la cancellazione dei propri Dati Personali («diritto
                all’oblio» - art. 17 GDPR), salvo che non sussista fondamento
                giuridico per il trattamento
              </p>
            </li>

            <li className="flex flex-row ml-10">
              <p className="mr-4 font-bold">•</p>
              <p>
                limitare il trattamento dei propri Dati Personali (art. 18 GDPR){" "}
              </p>
            </li>

            <li className="flex flex-row ml-10">
              <p className="mr-4 font-bold">•</p>
              <p>
                di trasferire o chiedere al Titolare la trasmissione dei propri
                Dati Personali ad altro titolare (art 20 GDPR){" "}
              </p>
            </li>

            <li className="flex flex-row ml-10">
              <p className="mr-4 font-bold">•</p>
              <p>
                opporsi in qualsiasi momento al trattamento dei propri Dati
                Personali (art. 21 GDPR) salvo che non sussista fondamento
                giuridico per il trattamento
              </p>
            </li>

            <li className="flex flex-row ml-10">
              <p className="mr-4 font-bold">•</p>
              <p>
                opporsi al trattamento automatizzato dei propri Dati Personali
                (art. 22 GDPR)
              </p>
            </li>

            <li className="flex flex-row ml-10">
              <p className="mr-4 font-bold">•</p>
              <p>
                revocare in qualsiasi momento il consenso prestato per finalità
                di marketing e profilazione
              </p>
            </li>

            <li className="flex flex-row ml-10">
              <p className="mr-4 font-bold">•</p>
              <p>
                proporre reclamo all’Autorità Garante per la Protezione dei dati
                personali e/o agire in sede giudiziale.
              </p>
            </li>
          </ul>

          <br></br>
          <p className="ml-10 mb-2">
            {" "}
            Per esercitare i propri diritti, gli Interessati possono inviare una
            richiesta ai seguenti indirizzi email:{" "}
            <span className="font-bold">
              {Array.isArray(data) && data[0].email}
            </span>{" "}
            (Ristoratore); <span className="font-bold">privacy@aigot.com</span>{" "}
            (AiGot).
          </p>
        </div>

        <br />

        <p className="flex self-start mb-8">
          {" "}
          Ultimo aggiornamento: 13/05/2022{" "}
        </p>
      </div>
    </>
  );
};

export default Privacypolicy;
