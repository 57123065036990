import React from 'react';
import dayjs from "dayjs";
import { BsClock } from "react-icons/bs"
import { IoIosPerson } from "react-icons/io"

function BookingCard({ booking }) {
    console.log()
    return (

        <div className='flex flex-col w-fit  gap-y-2 '>
            <p className='text-[#5A5246] font-medium text-xl sm:text-sm xsm:text-sm md:text-sm '>
                Prenotazione  {dayjs(booking?.booked_at).format('DD-MM-YYYY')}
            </p>
            <div className='flex flex-row items-center gap-x-2' >
                <p className='text-primaryColor'>
                    <BsClock size={25} />
                </p>
                <p className='text-[#7B7B7B] font-bold text-lg sm:text-sm xsm:text-sm md:text-sm'>
                    <span className='font-normal mr-2'>Ora: </span>
                    {dayjs(booking?.booked_at).format('HH:mm')}
                </p>
            </div>
            <div className='flex flex-row items-center gap-x-2' >
                <p className='text-primaryColor'>
                    <IoIosPerson size={25} />
                </p>
                <p className='text-[#7B7B7B] font-bold text-lg sm:text-sm xsm:text-sm md:text-sm '>
                    <span className='font-normal mr-2'>Quantità di persone: </span>
                    {booking?.persons}
                </p>
            </div>
        </div>
    )
}

export default BookingCard;
