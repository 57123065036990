import axios from "axios";
import { getSiteId } from "../Data/userData";
import { baseUrl } from "./Urls";

const endpoint = `https://${getSiteId()}.${
  process.env.REACT_APP_THEMES_PREFIX
}${baseUrl()}${process.env.REACT_APP_THEMES_API_PATH}/`;

export const getRestaurantInfo = async () => {
  const data = await axios.get(`${endpoint}restaurant-info`);
  return data;
};
//export const fetchTabless = () => axios.get(`${endpoint}booking/tables/${getSiteId()}`);
//export const fetchTables = () => axios.get(`${endpoint}booking/lookupTableAvailability`);
//export const fetchBooking = (id) => axios.get(`${endpoint}booking/${id}`);
//export const createBooking = (booking, header) => axios.post(`${endpoint}booking`, booking, header);
//export const updateBooking = (id, booking) => axios.patch(`${endpoint}booking/${id}`, booking);
//export const deleteBooking = (id) => axios.delete(`${endpoint}booking/${id}`);
