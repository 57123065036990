import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
  AmericanImages,
  ArgentinoImages,
  SweetsImages,
  Greek,
  Mexican,
  Italian,
  Pizza,
  Sushi,
  Fish,
  Arabian,
  Cocktail,
  ImageButton,
  style,
  RemoveFromLocalStorage,
} from "./ImagesImport";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ImageUploading from "react-images-uploading";
import UploadPlaceholderImage from "../../Assets/Icons/Upload-Image-Placeholder.svg";
import useWindowSize from "../Reusable/WindowSize";
import UploadImageToCDN from "../Reusable/UploadImage";
import { handleEditCatalog } from "../../Util/Api/CatalogApi";
import { MyContext } from "../../App";

function ChangeBGModal({
  handleClose,
  open,
  PatchNewInfo,
  SetRestaurantInfo,
  RestaurantInfo,
  WhichPart,
  Catalogs,
}) {
  const WindowWidth = useWindowSize();
  const { headersconfig } = React.useContext(MyContext);
  const [Images, SetImages] = React.useState(AmericanImages);
  const [isLoading2, SetLoading2] = React.useState(false);
  const [isLoading, SetLoading] = React.useState(false);
  const [imgsLoaded, setImgsLoaded] = React.useState(false);
  const CatalogID = localStorage.getItem("CatalogID");
  const CatalogIndex = Catalogs?.findIndex(
    (item) => item._id === JSON.parse(CatalogID)
  );
  const [selectedImage, SetSelectedImage] = React.useState(null);
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;
  const [isLoadingggg, SetLoadingggg] = React.useState(false);

  function handleChange(e) {
    const value = e.target.value;
    setImgsLoaded(false);
    if (value === "American") SetImages(AmericanImages);
    if (value === "Argentino") SetImages(ArgentinoImages);
    if (value === "Dolci") SetImages(SweetsImages);
    if (value === "Greek") SetImages(Greek);
    if (value === "Italian") SetImages(Italian);
    if (value === "Mexican") SetImages(Mexican);
    if (value === "Pizza") SetImages(Pizza);
    if (value === "Sushi") SetImages(Sushi);
    if (value === "Fish") SetImages(Fish);
    if (value === "Arabian") SetImages(Arabian);
    if (value === "Cocktail") SetImages(Cocktail);
  }

  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const loadImg = new Image();
      loadImg.src = image;
      loadImg.onload = () => resolve(image);
      loadImg.onerror = (err) => reject(err);
    });
  };

  React.useEffect(() => {
    SetLoading(true);
    Promise.all(Images.map((image) => loadImage(image)))
      .then(() => setImgsLoaded(true))
      .catch((err) => console.log("Failed to load images", err));
    SetLoading(false);
  }, [Images, imgsLoaded]);

  const DesktopScreen = WindowWidth > 1000;
  const MobileScreen = WindowWidth < 1000;
  const EditingHeroDeskTop = WhichPart === "hero" && DesktopScreen;
  const EditingHeroMobile = WhichPart === "hero" && MobileScreen;
  const EditingBioDeskTop = WhichPart === "bio" && DesktopScreen;
  const EditingBioMobile = WhichPart === "bio" && MobileScreen;
  const EditingBioDeskTop2 = WhichPart === "bio2" && DesktopScreen;
  const EditingBioMobile2 = WhichPart === "bio2" && MobileScreen;
  const EditingLogo = WhichPart === "logo";
  const EditingContactDeskTop = WhichPart === "contacts" && DesktopScreen;
  const EditingContactMobile = WhichPart === "contacts" && MobileScreen;
  const EditingLoginDeskTop = WhichPart === "login" && DesktopScreen;
  const EditingLoginMobile = WhichPart === "login" && MobileScreen;
  const EditingRegisterDeskTop = WhichPart === "register" && DesktopScreen;
  const EditingRegisternMobile = WhichPart === "register" && MobileScreen;
  const EditingMenuImages = WhichPart === "menu";
  const EdtingMenuBannerDeskTop = WhichPart === "menuBanner" && DesktopScreen;
  const EdtingMenuBannerMobile = WhichPart === "menuBanner" && MobileScreen;
  const EdtingDeliveryBannerDeskTop = WhichPart === "delivery" && DesktopScreen;
  const EdtingDeliveryBannerMobile = WhichPart === "delivery" && MobileScreen;
  const EdtingReservationBannerDeskTop =
    WhichPart === "reservation" && DesktopScreen;
  const EdtingReservationBannerMobile =
    WhichPart === "reservation" && MobileScreen;
  const EdtingBannerBlock01DeskTop = WhichPart === "Banner01" && DesktopScreen;
  const EdtingBannerBlock01BannerMobile =
    WhichPart === "Banner01" && MobileScreen;

  function handleIconChange(image) {
    if (EditingHeroDeskTop){
    window.localStorage.setItem('changedImage',image);
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          home: {
            ...RestaurantInfo.theme_photos.home,
            hero: {
              ...RestaurantInfo.theme_photos.home.hero,
              hero_img: window.location.origin + image,
              hero_mobile_img: window.location.origin + image,
            },
          },
        },
      });
    } 
    if (EditingHeroMobile){
    window.localStorage.setItem('changedImage',image);
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          home: {
            ...RestaurantInfo.theme_photos.home,
            hero: {
              ...RestaurantInfo.theme_photos.home.hero,
              hero_mobile_img: window.location.origin + image,
              hero_img: window.location.origin + image,
            },
          },
        },
      });
    }
    if (EditingBioDeskTop)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          home: {
            ...RestaurantInfo.theme_photos.home,
            bio: {
              ...RestaurantInfo.theme_photos.home.bio,
              bio_img: window.location.origin + image,
            },
          },
        },
      });

    if (EditingBioMobile)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          home: {
            ...RestaurantInfo.theme_photos.home,
            bio: {
              ...RestaurantInfo.theme_photos.home.bio,
              bio_mobile_img: window.location.origin + image,
            },
          },
        },
      });

    if (EditingBioDeskTop2)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          home: {
            ...RestaurantInfo.theme_photos.home,
            bio: {
              ...RestaurantInfo.theme_photos.home.bio,
              bio_img2: window.location.origin + image,
            },
          },
        },
      });

    if (EditingBioMobile2)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          home: {
            ...RestaurantInfo.theme_photos.home,
            bio: {
              ...RestaurantInfo.theme_photos.home.bio,
              bio_mobile_img2: window.location.origin + image,
            },
          },
        },
      });

    if (EdtingBannerBlock01DeskTop)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          home: {
            ...RestaurantInfo.theme_photos.home,
            menu_banner_1: {
              ...RestaurantInfo.theme_photos.home.menu_banner_1,
              banner_img: window.location.origin + image,
            },
          },
        },
      });

    if (EdtingBannerBlock01BannerMobile)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          home: {
            ...RestaurantInfo.theme_photos.home,
            menu_banner_1: {
              ...RestaurantInfo.theme_photos.home.menu_banner_1,
              banner_mobile_img: window.location.origin + image,
            },
          },
        },
      });

    if (EditingLogo)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          logo: window.location.origin + image,
        },
      });

    if (EditingContactDeskTop)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          contact: {
            ...RestaurantInfo.theme_photos.contact,
            contact_img: window.location.origin + image,
          },
        },
      });

    if (EditingContactMobile)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          contact: {
            ...RestaurantInfo.theme_photos.contact,
            contact_mobile_img: window.location.origin + image,
          },
        },
      });

    if (EditingLoginDeskTop)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          login: {
            ...RestaurantInfo.theme_photos.login,
            login_img: window.location.origin + image,
          },
        },
      });

    if (EditingLoginMobile)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          login: {
            ...RestaurantInfo.theme_photos.login,
            login_mobile_img: window.location.origin + image,
          },
        },
      });

    if (EditingRegisterDeskTop)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          register: {
            ...RestaurantInfo.theme_photos.register,
            register_img: window.location.origin + image,
          },
        },
      });

    if (EditingRegisternMobile)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          register: {
            ...RestaurantInfo.theme_photos.register,
            register_mobile_img: window.location.origin + image,
          },
        },
      });

    if (EdtingMenuBannerDeskTop)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          menu: {
            ...RestaurantInfo.theme_photos.menu,
            hero: window.location.origin + image,
          },
        },
      });

    if (EdtingMenuBannerMobile)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          menu: {
            ...RestaurantInfo.theme_photos.menu,
            heromobile: window.location.origin + image,
          },
        },
      });

    if (EdtingDeliveryBannerDeskTop)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          delivery: {
            ...RestaurantInfo.theme_photos.delivery,
            hero: window.location.origin + image,
          },
        },
      });

    if (EdtingDeliveryBannerMobile)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          delivery: {
            ...RestaurantInfo.theme_photos.delivery,
            heromobile: window.location.origin + image,
          },
        },
      });

    if (EdtingReservationBannerDeskTop)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          reservation: {
            ...RestaurantInfo.theme_photos.reservation,
            hero: window.location.origin + image,
          },
        },
      });

    if (EdtingReservationBannerMobile)
      SetRestaurantInfo({
        ...RestaurantInfo,
        theme_photos: {
          ...RestaurantInfo.theme_photos,
          reservation: {
            ...RestaurantInfo.theme_photos.reservation,
            heromobile: window.location.origin + image,
          },
        },
      });

    if (EditingMenuImages)
      Catalogs[CatalogIndex].image = window.location.origin + image;

    SetSelectedImage(image);
    setImages([]);
  }

  async function onChange(imageList, e) {
    setImages([]);
    SetSelectedImage(null);
    setImages(imageList);
    SetLoadingggg(true);
    try {
      const imageURL = await UploadImageToCDN(imageList[0]?.file);

      if (EditingHeroDeskTop)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            home: {
              ...RestaurantInfo.theme_photos.home,
              hero: {
                ...RestaurantInfo.theme_photos.home.hero,
                hero_img: imageURL,
                hero_mobile_img: imageURL,
              },
            },
          },
        });

      if (EditingHeroMobile)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            home: {
              ...RestaurantInfo.theme_photos.home,
              hero: {
                ...RestaurantInfo.theme_photos.home.hero,
                hero_mobile_img: imageURL,
                hero_img: imageURL,
              },
            },
          },
        });

      if (EditingBioDeskTop)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            home: {
              ...RestaurantInfo.theme_photos.home,
              bio: {
                ...RestaurantInfo.theme_photos.home.bio,
                bio_img: imageURL,
              },
            },
          },
        });

      if (EditingBioMobile)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            home: {
              ...RestaurantInfo.theme_photos.home,
              bio: {
                ...RestaurantInfo.theme_photos.home.bio,
                bio_mobile_img: imageURL,
              },
            },
          },
        });

      if (EditingBioDeskTop2)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            home: {
              ...RestaurantInfo.theme_photos.home,
              bio: {
                ...RestaurantInfo.theme_photos.home.bio,
                bio_img2: imageURL,
              },
            },
          },
        });

      if (EditingBioMobile2)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            home: {
              ...RestaurantInfo.theme_photos.home,
              bio: {
                ...RestaurantInfo.theme_photos.home.bio,
                bio_mobile_img2: imageURL,
              },
            },
          },
        });

      if (EdtingBannerBlock01DeskTop)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            home: {
              ...RestaurantInfo.theme_photos.home,
              menu_banner_1: {
                ...RestaurantInfo.theme_photos.home.menu_banner_1,
                banner_img: imageURL,
              },
            },
          },
        });

      if (EdtingBannerBlock01BannerMobile)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            home: {
              ...RestaurantInfo.theme_photos.home,
              menu_banner_1: {
                ...RestaurantInfo.theme_photos.home.menu_banner_1,
                banner_mobile_img: imageURL,
              },
            },
          },
        });

      if (EditingLogo)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: { ...RestaurantInfo.theme_photos, logo: imageURL },
        });

      if (EditingContactDeskTop)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            contact: {
              ...RestaurantInfo.theme_photos.contact,
              contact_img: imageURL,
            },
          },
        });

      if (EditingContactMobile)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            contact: {
              ...RestaurantInfo.theme_photos.contact,
              contact_mobile_img: imageURL,
            },
          },
        });

      if (EditingLoginDeskTop)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            login: {
              ...RestaurantInfo.theme_photos.login,
              login_img: imageURL,
            },
          },
        });

      if (EditingLoginMobile)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            login: {
              ...RestaurantInfo.theme_photos.login,
              login_mobile_img: imageURL,
            },
          },
        });

      if (EditingRegisterDeskTop)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            register: {
              ...RestaurantInfo.theme_photos.register,
              register_img: imageURL,
            },
          },
        });

      if (EditingRegisternMobile)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            register: {
              ...RestaurantInfo.theme_photos.register,
              register_mobile_img: imageURL,
            },
          },
        });

      if (EdtingMenuBannerDeskTop)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            menu: { ...RestaurantInfo.theme_photos.menu, hero: imageURL },
          },
        });

      if (EdtingMenuBannerMobile)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            menu: { ...RestaurantInfo.theme_photos.menu, heromobile: imageURL },
          },
        });

      if (EdtingDeliveryBannerDeskTop)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            delivery: {
              ...RestaurantInfo.theme_photos.delivery,
              hero: imageURL,
            },
          },
        });

      if (EdtingDeliveryBannerMobile)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            delivery: {
              ...RestaurantInfo.theme_photos.delivery,
              heromobile: imageURL,
            },
          },
        });

      if (EdtingReservationBannerDeskTop)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            reservation: {
              ...RestaurantInfo.theme_photos.reservation,
              hero: imageURL,
            },
          },
        });

      if (EdtingReservationBannerMobile)
        SetRestaurantInfo({
          ...RestaurantInfo,
          theme_photos: {
            ...RestaurantInfo.theme_photos,
            reservation: {
              ...RestaurantInfo.theme_photos.reservation,
              heromobile: imageURL,
            },
          },
        });

      if (EditingMenuImages) Catalogs[CatalogIndex].image = imageURL;
    } catch (error) {
      console.log(error);
    }
    SetLoadingggg(false);
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => {
          handleClose();
          setImages([]);
          SetSelectedImage(null);
          RemoveFromLocalStorage();
        }}
        disableScrollLock
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={{ ...style, width: MobileScreen ? "95%" : 800 }}>
            <div className="w-full flex flex-col items-center gap-y-7 z-10">
              {WhichPart === "hero" && (
                <p className=" text-primarytextcolour text-2xl">
                  Immagine Principale Home
                </p>
              )}
              {WhichPart === "bio" && (
                <p className=" text-primarytextcolour text-2xl">
                  Immagine Sezione Descrizione
                </p>
              )}
              {WhichPart === "logo" && (
                <p className=" text-primarytextcolour text-2xl">
                  Cambia Logo del Locale
                </p>
              )}

              <p className=" text-black text-sm -my-4">Carica Immagine</p>
              <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({ imageList, onImageUpload }) => (
                  <div
                    onClick={onImageUpload}
                    className="w-[350px] h-[150px] hover:cursor-pointer rounded-full text-[#00b27A] bg-white  flex items-center justify-center"
                  >
                    {(!imageList || imageList.length === 0) && (
                      <img
                        src={UploadPlaceholderImage}
                        alt=""
                        className="w-[350px] h-[150px] "
                      />
                    )}
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <img
                          src={image["data_url"]}
                          alt=""
                          className="w-[250px] h-[150px] "
                        />
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
              {WhichPart !== "logo" && (
                <p className=" text-black text-sm -mt-4 underline">Oppure</p>
              )}

              {WhichPart !== "logo" && (
                <p className=" text-black text-sm -my-4">
                  Scegli dal Nostro Elenco
                </p>
              )}

              {WhichPart !== "logo" && (
                <div className="mx-auto w-full items-center justify-center flex">
                  <FormControl sx={{ width: "25%" }} size="small">
                    <Select
                      native
                      id="uramaki-native-select"
                      onChange={(e) => handleChange(e)}
                    >
                      <option
                        aria-label="Americano"
                        value="American"
                        selected="selected"
                      >
                        {" "}
                        Americano{" "}
                      </option>
                      <option aria-label="Argentino" value="Argentino">
                        {" "}
                        Argentino{" "}
                      </option>
                      <option aria-label="Dolci" value="Dolci">
                        {" "}
                        Dolci{" "}
                      </option>
                      <option aria-label="Greco" value="Greek">
                        {" "}
                        Greco{" "}
                      </option>
                      <option aria-label="Italian" value="Italian">
                        {" "}
                        Italiano{" "}
                      </option>
                      <option aria-label="Messicano" value="Mexican">
                        {" "}
                        Messicano{" "}
                      </option>
                      <option aria-label="Pizza" value="Pizza">
                        {" "}
                        Pizza{" "}
                      </option>
                      <option aria-label="Sushi" value="Sushi">
                        {" "}
                        Sushi{" "}
                      </option>
                      <option aria-label="Pesce" value="Fish">
                        {" "}
                        Pesce{" "}
                      </option>
                      <option aria-label="Arabo" value="Arabian">
                        {" "}
                        Arabo{" "}
                      </option>
                      <option aria-label="Cocktail" value="Cocktail">
                        {" "}
                        Cocktail{" "}
                      </option>
                    </Select>
                  </FormControl>
                  {isLoading2 && (
                    <CircularProgress
                      color="success"
                      className=" w-fit mx-auto"
                    />
                  )}
                </div>
              )}

              {WhichPart !== "logo" && (
                <div className="w-full h-[250px] overflow-y-scroll  flex flex-col items-center gap-y-2  ">
                  {(isLoading || !imgsLoaded) && (
                    <CircularProgress
                      color="success"
                      className="my-10 w-full"
                    />
                  )}
                  {!isLoading &&
                    imgsLoaded &&
                    Images?.map((image, index) => {
                      return (
                        <ImageButton
                          focusRipple
                          style={{
                            width: "90%",
                          }}
                          onClick={(e) => handleIconChange(image)}
                          key={index}
                        >
                          <img
                            loading="eager"
                            src={image}
                            alt={"imagee"}
                            className={`w-full h-full object-cover border-8 ${
                              selectedImage === image && "border-green-300"
                            } border-transparent`}
                          />
                        </ImageButton>
                      );
                    })}
                </div>
              )}

              <button
                disabled={isLoading2 || isLoadingggg}
                onClick={() => {
                  EditingMenuImages &&
                    handleEditCatalog(
                      JSON.parse(CatalogID),
                      Catalogs[CatalogIndex],
                      headersconfig
                    );
                  !EditingMenuImages && PatchNewInfo();
                  handleClose();
                  RemoveFromLocalStorage();
                  setImages([]);
                  SetSelectedImage(null);
                  window.localStorage.setItem('changedCover', 'true');
                }}
                className="bg-primarybtn hover:bg-primarybtnhover elevation-1 active:shadow-innerbig text-white mx-auto px-8 py-2  h-fit rounded-md"
              >
                {isLoadingggg ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Conferma"
                )}
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default ChangeBGModal;
