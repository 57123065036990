import { SEND_MAIL } from '../../Constants'

export const ContactReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_MAIL:
      return {
        ...state,
        message: action.payload
      }
    default:
      return state
  }
}
