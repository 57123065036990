import React, { useContext, useEffect, useRef, useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { useNavigate } from "react-router-dom";
import { createOrder, stripeCheckout } from "../../Util/Api/OrderApi";
import { MyContext } from "../../App";
import { getCustomerById } from "../../Util/Redux/reducers/AuthorizationReducers";
import { IoIosArrowForward } from "react-icons/io";
import { RiMoneyEuroBoxLine } from "react-icons/ri";
import { GoLocation } from "react-icons/go";
import { BsClock, BsCheck2Circle, BsCheckCircleFill } from "react-icons/bs";
import CircularProgress from "@mui/material/CircularProgress";
import { editAuthUser } from "../../Util/Api/CustomerAPI";
import { io } from "socket.io-client";
import "./Timepicker.css";
import { toast } from "react-toastify";
import { getSiteId } from "../../Util/Data/userData";
import { baseUrl } from "../../Util/Api/Urls";
import axios from "axios";
function Checkout() {
  const current = new Date();
  const navigate = useNavigate();
  const date = `${current.getDate()}/${current.getMonth() + 1
    }/${current.getFullYear()}`;
  const [isLoading, SetLoading] = React.useState(false);
  const {
    cart,
    totalPrice,
    isForDelivery,
    deliveryTime,
    setDeliveryTime,
    shippingLabel,
    paymentMethod,
    setPaymentMethod,
    DeliverySettings,
    socketBooking,
    setSocketBooking,
    createSocketOrder,
  } = useContext(MyContext);
  const [activeStep, setActiveStep] = React.useState(isForDelivery ? 0 : 1);
  const user = JSON.parse(localStorage.getItem("currentCustomer"));
  const [userData, setUserData] = useState(null);
  const [Step1FormUserData, SetUserDataForm] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    city: "",
    CAP: "",
    firstLine: "",
    secondLine: "",
  });
  const [CreatingAddressIsLoadin, SetLoadingAddress] = React.useState(false);
  const [notes, SetNotes] = React.useState("");
  const socket = useRef();
  const [CheckAddressStatus, SetCheckAddressStatus] = React.useState(null);
  const endpoint = `https://${getSiteId()}.${process.env.REACT_APP_THEMES_PREFIX
    }${baseUrl()}${process.env.REACT_APP_THEMES_API_PATH}/`;

  async function getCustomer() {
    SetLoading(true);
    try {
      const res = await getCustomerById(user?.id);
      setUserData(res.data);
    } catch (error) {
      console.log(error);
    }
    SetLoading(false);
  }

  useEffect(() => {
    if (user !== null) getCustomer();
  }, [user?.id]);

  //Checks if the cart is empty
  useEffect(() => {
    if (!cart || cart.length === 0) navigate("/delivery");
  }, [cart, navigate]);

  useEffect(() => {
    setPaymentMethod(null);
    setDeliveryTime(null);
  }, []);

  useEffect(() => {
    // check if the address status has been updated and is true
    if (!user) {
      if (CheckAddressStatus) {
        setActiveStep((old) => old + 1);
      } else if (CheckAddressStatus === false) {
        toast.warning("L'indirizzo è fuori la zona di spedizione");
        SetLoadingAddress(false);
      }
    }
  }, [CheckAddressStatus, user]);

  const products = cart.map((prod) => {
    return {
      _id: prod._id._id,
      quantity: prod.quantity,
      price: prod._id.price,
      title:prod._id.title,
      additionalData: {
        variation: prod._id.DesiredVariations,
        options: prod._id.DesiredOptions,
      },
    };
  });
  const domain = window.location.host;
  const order = {
    customer: userData?._id || Step1FormUserData?.recipientFullName,
    name:
      "order of " +
      (userData?.firstName || Step1FormUserData?.recipientFullName) +
      " : " +
      date,
    products: products,
    totalPrice: totalPrice,
    billingAddress:
      Step1FormUserData?.firstLine +
      "," +
      Step1FormUserData?.secondLine +
      "," +
      Step1FormUserData?.city +
      "," +
      Step1FormUserData?.CAP,
    shippingLabel: shippingLabel || "non fornito",
    paymentMethod: paymentMethod,
    deliveryTime: deliveryTime,
    forDelivery: isForDelivery,
    notes: notes,
  };
  async function initializeOrder() {
    SetLoading(true);
    localStorage.removeItem("DeliveryCart");

    const deliveryCostCheck =
      isForDelivery && totalPrice < DeliverySettings?.FreeShippingMinimalCost
        ? DeliverySettings?.ShippingCost
        : 0;

    try {
      const res = await createOrder(order);
      createSocketOrder(order);
      const res2 =
        paymentMethod === "stripe" &&
        (await stripeCheckout(
          res?.data._id,
          products,
          domain,
          deliveryCostCheck
        ));
      paymentMethod === "stripe" && window.open(res2.data.link, "_self");
      paymentMethod === "cash" &&
        navigate("/payment/thank-you", { state: res.data._id });
    } catch (error) {
      console.log(error);
    }
    SetLoading(false);
  }

  // to controll the State of the Button
  const NoAddressEntered =
    activeStep === 0 &&
    (!Step1FormUserData.recipientFullName ||
      !Step1FormUserData.telephone ||
      !Step1FormUserData.city ||
      !Step1FormUserData.CAP ||
      !Step1FormUserData.firstLine ||
      !Step1FormUserData.secondLine);
  const NoDeliveryTimeChoosen = activeStep === 1 && !deliveryTime;
  const NoPaymentMethodIsChoosen = activeStep === 2 && !paymentMethod;
  const buttonIsDisabledForDelivery =
    NoAddressEntered || NoDeliveryTimeChoosen || NoPaymentMethodIsChoosen;
  // const NoPickUpTimeisChoosen = activeStep === 0 && !deliveryTime
  // const NoPaymentMethodIsChoosenForPickUp = activeStep === 1 && !paymentMethod
  // const buttonIsDisabledForPickup = NoPickUpTimeisChoosen || NoPaymentMethodIsChoosenForPickUp
  const controlButton = buttonIsDisabledForDelivery;
  // const controlButton = isForDelivery ? buttonIsDisabledForDelivery : buttonIsDisabledForPickup
  //----------------------------------------------

  let TheCurrentAddressesWithOutID = userData?.shippingAddress?.map((item) => ({
    recipientFullName: item.recipientFullName,
    telephone: item.telephone,
    city: item.city,
    CAP: item.CAP,
    firstLine: item.firstLine,
    secondLine: item.secondLine,
  }));

  const [NeedsNewAddress, SetNewAddress] = useState(false);
  const [badAddress, SetBadAddress] = useState(false);

  // create function with axios and async await to get the address status
  async function getAddressStatus(address) {
    SetLoading(true);
    try {
      const res = await axios.post(`${endpoint}map/distance`, {
        address: address,
      });
      console.log(address);
      SetCheckAddressStatus(res.data.status);
      SetBadAddress(false);
    } catch (error) {
      console.log(address);
      if (error.response.status === 500) {
        toast.warning("Non hai messo un indirizzo corretto");
        SetBadAddress(true);
      }
      return;
    } finally {
      SetLoading(false);
      return;
    }
  }

  async function handleAddTheAddressToUser() {
    SetLoadingAddress(true);
    const theNewUser = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      shippingAddress: TheCurrentAddressesWithOutID?.concat({
        recipientFullName: Step1FormUserData.recipientFullName,
        telephone: Step1FormUserData.telephone,
        city: Step1FormUserData.city,
        CAP: Step1FormUserData.CAP,
        firstLine: Step1FormUserData.firstLine,
        secondLine: Step1FormUserData.secondLine,
      }),
    };

    // Asked : what are you using for validation?
    // Answer : maybe someone else :)
    // Me : Solid Copy
    if (
      Step1FormUserData.CAP.length < 5 ||
      Step1FormUserData.telephone.length < 10
    ) {
      const warningMessage =
        Step1FormUserData.CAP.length < 5
          ? "Il CAP deve essere di 5 cifre"
          : "Il numero di telefono deve essere di 10 cifre";
      toast.warning(warningMessage);
      SetLoadingAddress(false);
      return;
    }

    try {
      SetLoading(true);
      const address = order?.billingAddress;
      if (address) {
        // can someone please refactor this and take it to sperate file ?
        const res = await axios.post(`${endpoint}map/distance`, {
          address: address,
        });
        const checkAddressStatus = res.data.status;
        SetCheckAddressStatus(checkAddressStatus);
        SetBadAddress(false);
        if (checkAddressStatus) {
          editAuthUser(user.id, { ...theNewUser });
          setActiveStep((old) => old + 1);
        } else {
          toast.warning("L'indirizzo è fuori la zona di spedizione");
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.warning("Non hai messo un indirizzo corretto");
        SetBadAddress(true);
      }
      console.log(error);
    } finally {
      SetLoading(false);
      SetLoadingAddress(false);
    }
  }

  const handleNext = async () => {
    if (NeedsNewAddress && activeStep === 0 && isForDelivery) {
      if (user === null) {
        const { firstLine, secondLine, city, CAP } = Step1FormUserData;
        if (firstLine && secondLine && city && CAP) {
          const address = `${firstLine},${secondLine},${city},${CAP}`;
          console.log("--- i will check the address");
          await getAddressStatus(address);
          console.log(CheckAddressStatus);
          if (CheckAddressStatus === true) {
            setActiveStep((old) => old + 1);
          } else {
            toast.warning("L'indirizzo è fuori la zona di spedizione");
            SetLoadingAddress(false);
          }
          return;
        }
      }
      // if the CAP or the
      await handleAddTheAddressToUser();
    } else if (activeStep === 3 || (activeStep === 2 && !isForDelivery)) {
      initializeOrder();
    } else {
      if (CheckAddressStatus === true || !NeedsNewAddress) {
        setActiveStep((old) => old + 1);
      } else {
        toast.warning("L'indirizzo è fuori la zona di spedizione");
      }
    }
  };

  function handleStep0() {
    setActiveStep(0);
    setPaymentMethod(null);
    setDeliveryTime(null);
    SetNewAddress(false);
    getCustomer();
  }
  function handleStep1() {
    setActiveStep(1);
    setPaymentMethod(null);
    setDeliveryTime(null);
  }
  function handleStep2() {
    setActiveStep(2);
    setPaymentMethod(null);
  }
  React.useEffect(() => {
    if (user) {
      if (
        order?.billingAddress !== "undefined,undefined,undefined,undefined" &&
        order?.billingAddress !== ",,," &&
        NeedsNewAddress === false
      )
        getAddressStatus(order?.billingAddress);
      // if (NeedsNewAddress === true) SetCheckAddressStatus(false);
    }
  }, [order?.billingAddress, NeedsNewAddress]);

  // /////////////////////socket for orders////////////////////
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_URL);
  }, []);

  // console.log(onlineUsers)

          // Send order to socket server
          useEffect(() => {
            if (order!==null) {
              socket.current.emit("send-order", order);
              socket.current.emit("new-order-add",order)
            
            }
            }, [order]);

    ///////////////////////socket for bookings/////////////////////////////

          // Send order to socket server
          useEffect(() => {
            if (socketBooking!==null) {
              socket.current.emit("send-booking", socketBooking);
              socket.current.emit("new-booking-add",socketBooking)

            }
              console.log(socketBooking,'socketbooking')
            }, [socketBooking]);
  //   /////////////////////////////////////////////////////////////////

  return (
    <>
      <div className="flex flex-row mb-12 mt-36 xsm:mt-10 sm:mt-10 md:mt-10 w-[60%] xmd:w-[80%] md:w-[90%] xsm:w-[95%] sm:w-[95%] bg-[#92847133] overflow-hidden mx-auto items-center h-12 xsm:h-10 sm:h-10">
        {isForDelivery && (
          <div
            onClick={() => {
              activeStep >= 0 && handleStep0();
            }}
            className={`flex flex-row items-center ${isForDelivery ? " w-1/4 " : " w-1/3 "
              }  h-12`}
          >
            <button className="w-full flex flex-row items-center gap-x-2 justify-center bg-primaryColor h-full relative">
              <p className="text-white font-semibold xsm:text-sm sm:text-sm xsm:hidden sm:hidden">
                indirizzo
              </p>
              {activeStep === 0 && <GoLocation size={25} color="white" />}
              {activeStep !== 0 && (
                <BsCheckCircleFill size={25} color="white" />
              )}
              {activeStep !== 0 && (
                <IoIosArrowForward
                  size={25}
                  color="white"
                  className="absolute right-0 xsm:hidden sm:hidden"
                />
              )}
            </button>
            {activeStep === 0 && (
              <div className="triangle-right h-[90%] border-l-[40px] xsm:border-l-[20px] sm:border-l-[20px] md:border-l-[20px] border-primaryColor "></div>
            )}
          </div>
        )}

        <div
          onClick={() => {
            activeStep >= 1 && handleStep1();
          }}
          className={`flex flex-row items-center ${isForDelivery ? " w-1/4 " : " w-1/3 "
            }   h-12`}
        >
          <div
            className={`w-full flex flex-row items-center ${activeStep >= 1 ? " cursor-pointer" : "cursor-no-drop"
              } gap-x-2 justify-center ${(activeStep === 1 || deliveryTime) && "bg-primaryColor"
              } h-full relative`}
          >
            {!deliveryTime && activeStep === 1 && (
              <p className="text-white font-semibold xsm:hidden sm:hidden">
                Ora
              </p>
            )}
            {deliveryTime && (
              <p className="text-white font-semibold  xsm:text-xs sm:text-xs">
                {deliveryTime}
              </p>
            )}
            {!deliveryTime && (
              <BsClock
                size={25}
                color={
                  activeStep !== 1 || (!isForDelivery && activeStep === 0)
                    ? "black"
                    : "white"
                }
              />
            )}
            {deliveryTime && (
              <IoIosArrowForward
                size={25}
                color="white"
                className="absolute right-0 xsm:hidden sm:hidden"
              />
            )}
          </div>
          {activeStep === 1 && (
            <div className="triangle-right h-[90%] border-l-[40px] xsm:border-l-[20px] sm:border-l-[20px] md:border-l-[20px] border-primaryColor "></div>
          )}
        </div>

        <div
          onClick={() => {
            activeStep >= 2 && handleStep2();
          }}
          className={`flex flex-row items-center ${isForDelivery ? "w-1/4" : "w-1/3"
            }  h-12`}
        >
          <div
            className={`w-full flex flex-row items-center ${activeStep >= 2 ? " cursor-pointer" : "cursor-no-drop"
              } gap-x-2 justify-center ${(activeStep === 2 || paymentMethod) && "bg-primaryColor"
              } h-full relative`}
          >
            {!paymentMethod && activeStep === 2 && (
              <p className="text-white font-semibold xsm:hidden sm:hidden md:hidden">
                {" "}
                Pagamento
              </p>
            )}
            {paymentMethod && (
              <p className="text-white font-semibold xsm:text-[10px] sm:text-xs">
                {paymentMethod === "stripe" ? "Carte" : "Contanti"}
              </p>
            )}
            {!paymentMethod && (
              <RiMoneyEuroBoxLine
                size={25}
                color={activeStep !== 2 ? "black" : "white"}
              />
            )}
            {paymentMethod && (
              <IoIosArrowForward
                size={25}
                color="white"
                className="absolute right-0 xsm:hidden sm:hidden"
              />
            )}
          </div>
          {activeStep === 2 && (
            <div className="triangle-right h-[90%] border-l-[40px] xsm:border-l-[20px] sm:border-l-[20px] md:border-l-[20px]  border-primaryColor "></div>
          )}
        </div>

        <div
          className={`flex flex-row items-center ${isForDelivery ? "w-1/4" : "w-1/3"
            }   h-12`}
        >
          <div
            className={`w-full ${activeStep !== 5 ? "cursor-no-drop" : "cursor-pointer"
              } flex flex-row items-center gap-x-2 justify-center ${activeStep === 3 && "bg-primaryColor"
              } h-full relative`}
          >
            <BsCheck2Circle
              size={25}
              color={activeStep !== 3 ? "black" : "white"}
              className="xsm:hidden sm:hidden"
            />
            {
              <p
                className={`${activeStep === 3 ? "text-white" : "text-black"
                  } font-semibold xsm:text-xs sm:text-xs`}
              >
                Checkout
              </p>
            }
          </div>
        </div>
      </div>
      {
        <div className="w-full">
          {activeStep === 0 ? (
            <Step1
              NeedsNewAddress={NeedsNewAddress}
              SetNewAddress={SetNewAddress}
              isLoading={isLoading}
              userData={userData}
              Step1FormUserData={Step1FormUserData}
              SetUserDataForm={SetUserDataForm}
              user={user}
              getCustomer={getCustomer}
            />
          ) : null}
          {activeStep === 1 ? (
            <Step2 SetNotes={SetNotes} notes={notes} />
          ) : null}
          {activeStep === 2 ? <Step3 /> : null}
          {activeStep === 3 ? (
            <Step4 Step1FormUserData={Step1FormUserData} userData={userData} />
          ) : null}
        </div>
      }

      {isLoading && (
        <div className="mb-12 md:mb-6 sm:mb-6 xsm:mb-6">
          <CircularProgress />
        </div>
      )}

      <div>
        <div className="flex flex-row justify-center items-center gap-4 mx-auto mb-12 mt-12 md:mt-6 sm:mt-6 xsm:mt-6 xsm:w-full sm:w-full md:w-full xsm:flex-col sm:flex-col md:flex-col px-4">
          <button
            style={{ fontFamily: 'var(--primary-font)' }}
            disabled={controlButton || isLoading}
            className={` bg-primaryColor border-2 border-primaryColor disabled:opacity-50 disabled:cursor-no-drop h-[50px] sm:h-[40px] xsm:h-[40px] mx-auto px-16 text-semibold py-3 sm:py-1 xsm:py-1 md:py-1 rounded-xl text-white  xsm:w-[45%] sm:w-[55%] flex items-center justify-center md:w-[40%]`}
            onClick={handleNext}
          >
            {CreatingAddressIsLoadin ? (
              <CircularProgress color="inherit" />
            ) : (
              "CONFERMA"
            )}
          </button>
        </div>
      </div>
    </>
  );
}

export default Checkout;
